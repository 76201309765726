import React from 'react'
import { Form, Input } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { COMMON_RULE } from '@/constants'

const ShadowTextInput = ({
  name,
  label,
  required=true,
  pricing,
  rating,
  ...params
}) => {

  return (
    <Form.Item label={label} name={name} rules={[{ required: required, message: "Required"}]}>
      <Input
        {...params}
        className=""
      />
    </Form.Item>
  )
}

export default ShadowTextInput