import React, { useEffect, useMemo, Fragment, useState } from 'react'
import { Layout, Avatar, Dropdown, Menu, Space, Button, Input, Drawer } from 'antd'
import { NavLink, Link, useNavigate, useLocation } from 'react-router-dom'
import { MenuOutlined, RightOutlined } from '@ant-design/icons'
import { useSelector, useDispatch } from 'react-redux'
import AuthActions from '@/redux/auth'
import { getInitialName } from '@/utils'
import Img from '@/component/Img'

import './style.less'

const Header = ({pageTitle, isMobile, router, openSidebar,preventSidebarClose }) => {
  const { userInfo, is_authed } = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {}, [userInfo])

  useEffect(() => {}, [pageTitle])

  const logout = async () => {
    await dispatch(AuthActions.logOut())
    navigate('/login')
  }

  const subTitle = pageTitle

  return (
    <Layout.Header className="header dashboard">
      <div className="header__logo">
        <Space  className='spacing'>
        {isMobile && (
        <Button type="text" icon={<MenuOutlined />} className='hamburger' onClick={(e) => {openSidebar(); preventSidebarClose(e);}} style={{ margin: '10px', marginBottom:"18px" }} />
      )}
          DASHBOARD{' '}
          {subTitle !== 'Dashboard' && (
            <>
              <RightOutlined />
              {pageTitle}
            </>
          )}
        </Space>
      </div>
      <div className="header__user">
        <Dropdown
          overlay={
            <Menu className="account-menu">
              <Menu.Item key="profile">
                <NavLink to="/dashboard/profile">Profile</NavLink>
              </Menu.Item>
              <Menu.Item key="logout" onClick={logout}>
                <span>Log Out</span>
              </Menu.Item>
            </Menu>
          }
          placement="bottomRight"
          trigger={['click']}
        >
          <Space size={16}>
            <Img
              src={userInfo?.profile_photo ? userInfo?.profile_photo.file : '/images/user.svg'}
              className="avatar-logo"
            />
          </Space>
        </Dropdown>
      </div>
    </Layout.Header>
  )
}

export default Header
