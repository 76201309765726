import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { Row, Col, Divider, Form, Button, Space, Input, Switch } from 'antd'
import SwitchInput from '@/component/SwitchInput'
import AuthActions from '@/redux/auth'
import { GENDER_OPTIONS } from '@/constants'
import './style.less'

const Settings = () => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const { userInfo, is_authed } = useSelector((state) => state.auth)

  const handleSubmit = async (values) => {
    try {
      setLoading(true)
      await dispatch(AuthActions.updateUser(userInfo.id, values))
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  const formItemLayout = {
    labelCol: { span: 22 },
    wrapperCol: { span: 2 }
  }

  return (
    <div className="dashboard-page settings-page">
      <Row gutter={[16]}>
        <Col span={24} md={24}>
          <Form
            {...formItemLayout}
            layout="horizontal"
            form={form}
            onFinish={handleSubmit}
            requiredMark={false}
            preserve={false}
            colon={false}
            initialValues={userInfo}
          >
            <h3>Emails</h3>
            <p>Connect email notifications to your account.</p>
            <Divider />
            <SwitchInput label="Recommendations and news" name="email_recommendations_and_news" />
            <SwitchInput label="Upcomming events" name="email_upcoming_events" />
            <SwitchInput label="Features and updates" name="email_features_and_updates" />
            <SwitchInput label="Message or mentions" name="email_message_or_mentions" />
            <br />
            <h3>Notifications</h3>
            <p>Connect notifications to your account.</p>
            <Divider />
            <SwitchInput label="Direct Message" name="notification_direct_message" />
            <SwitchInput label="Features and updates" name="notification_features_and_updates" />
            <Button type="primary" className="btn" htmlType="submit" loading={loading}>
              Save
            </Button>
          </Form>
        </Col>
      </Row>
    </div>
  )
}

export default Settings
