import React from 'react';
import { Form, Input } from 'antd';
import TooltipWithInfo from '@/component/TooltipWithInfo'; // Make sure the import path is correct

const TextInput = ({ name, label, required = true, tooltipInfo, ...params }) => {
  return (
    <Form.Item 
      label={
        <>
          {label} 
          {tooltipInfo && tooltipInfo.info && (
            <TooltipWithInfo info={tooltipInfo.info} />
          )}
        </>
      } 
      name={name} 
      rules={[{ required: required, message: 'Required' }]}
    >
      <Input {...params} />
    </Form.Item>
  );
};

export default TextInput;
