import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { Row, Col, Divider, Card, Statistic, Button, Table, Space } from 'antd'
import { CreditCardOutlined, SearchOutlined } from '@ant-design/icons'
import InlineRow from '@/component/InlineRow'
import MapArea from '@/component/MapArea'
import moment from 'moment'

const RentCast = (props) => {
  const { rentcastInfo } = props
  const [mapRef, setMapRef] = useState()
  const [isOpen, setIsOpen] = useState(false)
  const [infoWindowData, setInfoWindowData] = useState()

  const comparable_listing = rentcastInfo?.comparable_listing
  const prop_data = rentcastInfo?.prop_data
  const rent_data = rentcastInfo?.rent_data

  const tableProps = {
    size: 'large',
    columns: [
      { dataIndex: 'addressLine1', title: 'Address', key: 'addressLine1' },
      {
        dataIndex: 'listed_rent',
        title: 'Listed Rent',
        key: 'listed_rent',
        render: (text) => <Statistic value={text} precision={0} prefix="$" />
      },
      {
        dataIndex: 'listing_date',
        title: 'Listing Date',
        key: 'listing_date',
        render: (text) => moment(text).format('YYYY-MM-DD')
      },
      {
        dataIndex: 'similarity',
        title: 'Similarity',
        key: 'similarity',
        render: (text) => <Statistic value={text} precision={1} suffix="%" />
      },
      {
        dataIndex: 'distance',
        title: 'Distance',
        key: 'distance',
        render: (text) => <Statistic value={text} precision={2} suffix="mi" />
      },
      { dataIndex: 'beds', title: 'Beds', key: 'beds' },
      { dataIndex: 'baths', title: 'Baths', key: 'baths' },
      {
        dataIndex: 'square_ft',
        title: 'Square Feet',
        key: 'square_ft',
        render: (text) => <Statistic value={text} precision={0} />
      },
      { dataIndex: 'type', title: 'Type', key: 'type' }
    ],
    dataSource: rentcastInfo?.comparable_listing,
    pagination: false
  }

  const onMapLoad = (map) => {
    setMapRef(map)
    const bounds = new google.maps.LatLngBounds()
    if (prop_data) {
      bounds.extend({ lat: prop_data.latitude, lng: prop_data.longitude })
    }
    rentcastInfo?.comparable_listing.forEach(({ latitude, longitude }) =>
      bounds.extend({ lat: latitude, lng: longitude })
    )
    map.fitBounds(bounds)
  }

  const handleMarkerClick = (marker) => {
    if (marker?.latitude === null || marker?.longitude === null) return
    mapRef?.panTo({ lat: marker?.latitude, lng: marker?.longitude })
    setInfoWindowData(marker)
    setIsOpen(true)
  }

  let markers = rentcastInfo?.comparable_listing
  if (prop_data) {
    markers = [prop_data].concat(markers)
  }

  return (
    <div className="rent-cast-board">
      {rentcastInfo && (
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Card className="summary-card">
              <h4 className="blue-label">Estimated Monthly Rent</h4>
              <Statistic className="heading-1" value={rent_data.monthly} precision={0} prefix="$" />
              {rent_data.per_sq_ft && (
                <div className="d-flex mb-3">
                  <Space direction="vertical" size={0}>
                    <Statistic className="heading-2" value={rent_data.per_sq_ft} precision={2} prefix="$" />
                    <p>per sq.ft.</p>
                  </Space>
                  <Divider type="vertical" />
                  <Space direction="vertical" size={0}>
                    <Statistic className="heading-2" value={rent_data.per_bedroom} precision={0} prefix="$" />
                    <p>per bedroom</p>
                  </Space>
                </div>
              )}
              <div className="d-flex d-space">
                <p className="mb-0">Low Estimate</p>
                <p className="mb-0">High Estimate</p>
              </div>
              <div className="progress-bar"></div>
              <div className="d-flex d-space">
                <Statistic className="heading-2" value={rent_data.low_estimate} precision={0} prefix="$" />
                <Statistic className="heading-2" value={rent_data.high_estimate} precision={0} prefix="$" />
              </div>
              {rent_data.low_sq_estimate && (
                <div className="d-flex d-space">
                  <Statistic value={rent_data.low_sq_estimate} prefix="$" suffix="/sq.ft." />
                  <Statistic value={rent_data.high_sq_estimate} prefix="$" suffix="/sq.ft." />
                </div>
              )}
            </Card>
          </Col>
          <Col span={16}>
            {markers.length > 0 ? (
              <MapArea
                markers={markers}
                onMapLoad={onMapLoad}
                infoWindowData={infoWindowData}
                handleMarkerClick={handleMarkerClick}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
              />
            ) : (
              <p>No data</p>
            )}
          </Col>
          {/*<Col span={7}>
              <Card title="Property Information">
                <InlineRow className="mb-1">
                  <Statistic title="Address:" value={`${prop_data.addressLine1}`} />
                  <Statistic title="City:" value={`${prop_data.city}`} />
                  <Statistic title="State:" value={`${prop_data.state}`} />
                </InlineRow>
                <InlineRow className="mb-1">
                  <Statistic title="Property type:" value={`${prop_data.propertyType} `} />
                  <Statistic title="Zipcode:" value={`${prop_data.zipCode} `} />
                  <Statistic title="Bedrooms:" value={prop_data.bedrooms} />
                </InlineRow>
                <InlineRow>
                  <Statistic title="Bathrooms:" value={prop_data.bathrooms} />
                  <Statistic title="Square foot:" value={prop_data.squareFootage} />
                  <Statistic title="Year built:" value={prop_data.yearBuilt} />
                </InlineRow>
              </Card>
            </Col>*/}
          <Col span={24}>
            <Card title="Comparable listings">
              {rentcastInfo?.comparable_listing.length > 0 ? <Table {...tableProps} /> : <p>No data</p>}
            </Card>
          </Col>
        </Row>
      )}
    </div>
  )
}

export default RentCast
