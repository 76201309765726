import React, { useEffect } from 'react'
import { Spin, Divider, Space } from 'antd'
import { GoogleMap, InfoWindow, Marker, useLoadScript } from '@react-google-maps/api'
import Img from '@/component/Img'
import './style.less'

const MapArea = ({ onMapLoad, markers, infoWindowData, handleMarkerClick, isOpen, setIsOpen }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyDNIyoSDV1wnJVW8wdHkiNG-eZ2bMcq0iY'
  })

  return (
    <div className="mapview-container">
      {!isLoaded ? (
        <Spin spining={!isLoaded} className="map-loader"></Spin>
      ) : (
        <GoogleMap mapContainerClassName="map-container" onLoad={onMapLoad} options={{ mapTypeControl: false }}>
          {markers?.map((marker, ind) => (
            <Marker
              key={ind}
              position={{
                lat: marker.latitude || marker.incident_latitude,
                lng: marker.longitude || marker.incident_longitude
              }}
              icon="https://api.axiomsuites.com/media/image/58f9bc90-1d46-4a8c-8514-2cc2eea9a893_HnNkw36.png"
              onClick={() => handleMarkerClick(marker)}
            >
              {isOpen &&
                ((marker.incident_code && infoWindowData?.incident_code === marker.incident_code) ||
                  (marker.distance && infoWindowData?.distance === marker.distance)) && (
                  <InfoWindow
                    onCloseClick={() => {
                      setIsOpen(false)
                    }}
                  >
                    <div className="custom-marker">
                      {infoWindowData.addressLine1 ? (
                        <>
                          <h3>{infoWindowData.addressLine1}</h3>
                          <Divider />
                          <Space>
                            <p>{infoWindowData.distance}mi</p>
                            <Divider type="vertical" />
                            <p>{infoWindowData.beds} BD</p>
                            <Divider type="vertical" />
                            <p>{infoWindowData.bathrooms} BA</p>
                            <Divider type="vertical" />
                            <p>{infoWindowData.square_ft} sq.ft</p>
                          </Space>
                        </>
                      ) : (
                        <>
                          <h3>{infoWindowData.incident_address}</h3>
                          <Divider />
                          <Space className="mb-1">
                            <p>{infoWindowData.incident_offense_crime_against}</p>
                            <Divider type="vertical" />
                            <p>{infoWindowData.incident_offense_description}</p>
                          </Space>
                          <p>{infoWindowData.incident_source_original_type}</p>
                        </>
                      )}
                    </div>
                  </InfoWindow>
                )}
            </Marker>
          ))}
        </GoogleMap>
      )}
    </div>
  )
}

export default MapArea
