import React, { useEffect, useState } from 'react'
import { useParams, Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Divider, Form, Button, Space, Spin, Tooltip } from 'antd'
import Step1_File from './steps/step1_file'
import Step2_Property from './steps/step2_property'
import Step3_IncomeAndExpense from './steps/step3_income_and_expense'
import Step4_RentRoll from './steps/step4_rent_roll'
import Step5_ValueAdd from './steps/step5_value_add'
import Step6_Other from './steps/step6_other'
import useMediaQuery from 'use-media-antd-query'
import DealActions from '@/redux/deal'
import { TokenApi } from '@/utils/api'
import { DEAL } from '@/constants'
import './style.less'

const CreateDeal = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [fileType, setFileType] = useState('pdf')
  const [resource, setResource] = useState()
  const { userInfo, is_authed } = useSelector((state) => state.auth)
  const { dealInfo, stepIndex } = useSelector((state) => state.deal)
  const colSize = useMediaQuery()
  const isMobile = ['sm', 'xs'].indexOf(colSize) > -1
  const { dealId } = useParams()

  useEffect(() => {
    loadData()
  }, [dealId])

  useEffect(() => {}, [dealInfo])

  const loadData = async () => {
    if (dealId) {
      setLoading(true)
      await dispatch(DealActions.getDeal(userInfo.id, dealId, true))
      setLoading(false)
    } else {
      dispatch({ type: DEAL.DEAL_INIT, payload: 1 })
    }
  }

  const handleNativateStep = (index) => {
    if (dealInfo) dispatch({ type: DEAL.DEAL_STEP, payload: index })
  }

  const handleUpload = async (file) => {
    setLoading(true)
    const formData = new FormData()
    formData.append('file', file)
    formData.append('name', file.name)
    formData.append('category', 'pdf')
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data' 
      }
    }
    const res = await TokenApi.post(`api/v1/resources/`, formData,config)
    setResource(res)
    setLoading(false)
  }

  const handleCreate = async (values) => {
    if (dealInfo) handleNativateStep(2)
    else {
      try {
        setLoading(true)
        values.user = userInfo.id
        values.resource = resource.id
        await dispatch(DealActions.createDeal(userInfo.id, values))
        setLoading(false)
      } catch (err) {
        setLoading(false)
      }
    }
  }

  const handleUpdate = async (values, moveNext = true) => {
    setLoading(true)
    await dispatch(
      DealActions.updateDeal(userInfo.id, dealInfo.id, moveNext, {
        details: { ...dealInfo.details, ...values }
      })
    )
    setLoading(false)
  }

  const params = {
    dealInfo,
    handleUpdate
  }

  let stepRender = ''

  switch (stepIndex) {
    case 1:
      stepRender = (
        <Step1_File
          {...params}
          handleCreate={handleCreate}
          handleUpload={handleUpload}
          resource={resource}
          fileType={fileType}
          setFileType={setFileType}
        />
      )
      break
    case 2:
      stepRender = <Step2_Property {...params} />
      break
    case 3:
      stepRender = <Step4_RentRoll {...params} />
      break
    case 4:
      stepRender = <Step5_ValueAdd {...params} />
      break
    case 5:
      stepRender = <Step3_IncomeAndExpense {...params} />
      break
    case 6:
      stepRender = <Step6_Other {...params} />
      break
    default:
      stepRender = <Step1_File {...params} />
  }

  let edgeCnt = 1
  if (dealInfo) edgeCnt = 2

  const setTooltip = [
    'Upload File',
    'Property Details',
    'Rent Rolls',
    'Value Add / Cap Ex',
    'Income & Expenses',
    'Other'
  ]

  return (
    <div className="dashboard-page create-deal-page">
      <Spin spinning={loading}>
        <div className="form-container">{stepRender}</div>
        <div className="step-navigator">
          {[...Array(6)].map((idx, i) => (
            <Tooltip key={i} title={setTooltip[i]}>
              <span className="step-button-wrapper">
                <Button
                  key={i + 1}
                  onClick={() => handleNativateStep(i + 1)}
                  className={i + 1 === stepIndex && 'active'}
                  disabled={i == 0 && dealInfo}
                >
                  &nbsp;
                </Button>
              </span>
            </Tooltip>
          ))}
        </div>
        {stepIndex > edgeCnt && (
          <Button type="primary" className="back-btn btn" onClick={() => handleNativateStep(stepIndex - 1)}>
            Previous
          </Button>
        )}
      </Spin>
    </div>
  )
}

export default CreateDeal
