import React, { useState } from 'react'
import { Modal, Button } from 'antd'
import Stepper from './Stepper'

export default function Step5TransitionModal({ transitionModalVisible, handleStep5 }) {
  const modalContent = [
    {
      icon: '/images/people.png',
      title: 'People Title',
      text: 'People Text ................'
    },
    {
      icon: '/images/cash-stack.png',
      title: 'Cash Title',
      text: 'Cash Text ................'
    },
    {
      icon: '/images/money-bundle.png',
      title: 'Money Title',
      text: 'Money Text ................'
    }
  ]
  return (
    <Modal
      centered
      open={transitionModalVisible}
      closable={false}
      footer={null}
      width={600}
      bodyStyle={{ padding: ' 40px' }}
    >
      <div className="transition-content">
        <div className="transition-header">
          <div className="font-medium text-xl">You're off to a great start! 🥳 </div>
          <small>Continue your journey towards unlocking your Property Underwriting with the next five steps. </small>
        </div>
        <Stepper />
        <div className="pt-10">
          <Button type="primary" size="large" onClick={handleStep5}>
            Continue
          </Button>
        </div>
      </div>
    </Modal>
  )
}
