import React from 'react'
import { Table } from 'antd'

const AnswersTable = ({ answerData, questions }) => {
  if (!answerData) {
    return null
  }

  const dataSource = []

  // Iterate over each question and its corresponding answer data
  Object.keys(answerData).forEach((questionKey, index) => {
    const questionIndex = parseInt(questionKey)
    const question = questions[questionIndex] // Get the question text

    const answerDataForQuestion = answerData[questionKey] // Get the answer data for this question

    if (answerDataForQuestion && answerDataForQuestion.length > 0) {
      const rowData = {
        key: questionIndex,
        question
      }

      // Iterate over each unit's answer data for this question
      answerDataForQuestion.forEach((unitData, unitIndex) => {
        let unitValue = unitData.units

        // Add dollar symbol if unitIndex is 3 or 4
        if (questionIndex === 3 || questionIndex === 4) {
          unitValue = `$${unitValue}`
        }

        rowData[`unit${unitIndex + 1}`] = unitValue // Add unit data to the row
      })

      dataSource.push(rowData)
    }
  })

  const unitCount =
    dataSource.length > 0 ? Object.keys(dataSource[0]).filter((key) => key.startsWith('unit')).length : 0

  // Creating columns dynamically
  const columns = [
    {
      title: 'Questions',
      dataIndex: 'question',
      key: 'question',
      width: 250, // Set a static width for the Questions column
      fixed: 'left' // Make the Questions column sticky
    },
    ...Array.from({ length: unitCount }, (_, index) => ({
      title: `Unit ${index + 1}`,
      dataIndex: `unit${index + 1}`,
      key: `unit${index + 1}`,
      width: 150, // Set a static width for each Unit column
      render: (text) => <div style={{ maxWidth: '150px', overflow: 'auto' }}>{text}</div> // Allow overflow scroll for Unit columns
    }))
  ]

  return <Table columns={columns} dataSource={dataSource} pagination={false} scroll={{ x: 'max-content', y: 400 }} />
}

export default AnswersTable
