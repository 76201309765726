import React from 'react'
import { Form, Switch } from 'antd'
import { UploadOutlined } from '@ant-design/icons'

const SwitchInput = ({ name, label, required = false, ...params }) => {
  return (
    <Form.Item label={label} name={name} rules={[{ required: required, message: 'Required' }]} valuePropName="checked">
      <Switch {...params} />
    </Form.Item>
  )
}

export default SwitchInput
