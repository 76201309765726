import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Row, Col, Divider, Form, Button, Space, Input } from 'antd'
import InlineRow from '@/component/InlineRow'
import NumberInput from '@/component/NumberInput'
import TextInput from '@/component/TextInput'

const Step6_other = (props) => {
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const { dealInfo, handleUpdate } = props

  const handleSubmit = async (values) => {
    await handleUpdate({ other: values, status: 'completed' })
    navigate(`/dashboard/underwriting/${dealInfo.id}`)
  }

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={handleSubmit}
      requiredMark={false}
      preserve={false}
      colon={false}
      initialValues={{
        ...dealInfo?.details?.other,
        first_years_insurance: dealInfo?.financials?.summary.uses_of_funds.first_years_insurance
      }}
    >
      <h1 className="mb-4">Other</h1>
      <InlineRow>
        <NumberInput label="Total Capital Improvements" name="total_capital_improvements" pricing={true} />
        <NumberInput label="Per Unit" name="total_capital_improvements_per_unit" pricing={true} />
      </InlineRow>
      <InlineRow>
        <NumberInput label="Capital Reserves Per Unit" name="capital_reserves_per_unit" pricing={true} />
        <NumberInput label="Cap Rate @ Resale" name="cap_rate_resale" rating={true} />
      </InlineRow>
      <InlineRow>
        <NumberInput label="Manager Split" name="manager_split" rating={true} />
        <NumberInput label="Preferred Rate" name="preferred_rate" rating={true} />
        <NumberInput label="Investor Split" name="investor_split" rating={true} />
      </InlineRow>
      <InlineRow>
        <NumberInput label="1st Years Insurance" name="first_years_insurance" precision={2} pricing={true} />
        <NumberInput label="Property Tax per Month" name="property_tax_per_month" precision={2} pricing={true} />
        <NumberInput label="Contingency" name="contingency" precision={2} pricing={true} />
      </InlineRow>
      <Button type="primary" className="next-btn btn" htmlType="submit">
        Submit
      </Button>
    </Form>
  )
}

export default Step6_other
