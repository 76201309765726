import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { Row, Col, Divider, Card, Statistic, Button, Progress, Space } from 'antd'
import { CreditCardOutlined, SearchOutlined } from '@ant-design/icons'
import Img from '@/component/Img'
import moment from 'moment'
// import ReactHighcharts from 'react-highcharts'
import MapArea from '@/component/MapArea'

const Walkscore = (props) => {
  const { walkscoreInfo } = props
  const [mapRef, setMapRef] = useState()
  const [isOpen, setIsOpen] = useState(false)
  const [infoWindowData, setInfoWindowData] = useState()

  const config = {
    chart: {
      type: 'column'
    },
    title: {
      align: 'left',
      text: ''
    },
    subtitle: {
      align: 'left',
      text: ''
    },
    accessibility: {
      announceNewData: {
        enabled: true
      }
    },
    xAxis: {
      type: 'category'
    },
    yAxis: {
      title: {
        text: ''
      }
    },
    legend: {
      enabled: false
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          format: '{point.y:.1f}%'
        }
      }
    },
    tooltip: {
      headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
      pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>'
    },

    series: [
      {
        name: 'Scores',
        colorByPoint: true,
        data: walkscoreInfo?.reports
      }
    ]
  }

  // const onMapLoad = (map) => {
  //   setMapRef(map)
  //   const bounds = new google.maps.LatLngBounds()
  //   walkscoreInfo?.crimes.forEach(({incident_latitude, incident_longitude}) => bounds.extend({ lat: incident_latitude, lng: incident_longitude }))
  //   map.fitBounds(bounds)
  // }

  // const handleMarkerClick = (marker) => {
  //   if (marker?.incident_latitude === null || marker?.incident_longitude === null)
  //     return
  //   mapRef?.panTo({ lat: marker?.incident_latitude, lng: marker?.incident_longitude })
  //   setInfoWindowData(marker)
  //   setIsOpen(true)
  // }

  return (
    <div className="rent-cast-board">
      {walkscoreInfo && (
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Card title="Scores" className="mb-2">
              <Row gutter={[16]}>
                {walkscoreInfo.scores.map((score, idx) => (
                  <Col key={idx} span={12} md={8} className="text-center">
                    {score.name == 'Bike' ? (
                      <Img src={`/images/${score.name}.svg`} className="score-icon" />
                    ) : (
                      <Img src={`/images/${score.name}.png`} className="score-icon" />
                    )}
                    <h4 className="mb-2">{score.name} Score</h4>
                    <Progress
                      type="circle"
                      percent={score.value || 0}
                      strokeColor={score.value > 80 ? 'green' : 'orange'}
                      strokeWidth={12}
                      format={(percent) => `${percent}`}
                    />
                  </Col>
                ))}
              </Row>
            </Card>
            {/*<Card title="Details Report">
              <ReactHighcharts config={config} />
            </Card>*/}
          </Col>
          {/*<Col span={24}>
              <Card title="Crime Incidents + Riskmap" className="crime-card">
                {walkscoreInfo?.crimes.length > 0?
                  <MapArea
                    markers={walkscoreInfo?.crimes}
                    onMapLoad={onMapLoad}
                    infoWindowData={infoWindowData}
                    handleMarkerClick={handleMarkerClick}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                  />
                  :
                  <p>No data</p>
                }
              </Card>
            </Col>*/}
        </Row>
      )}
    </div>
  )
}

export default Walkscore
