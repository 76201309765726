import { COMMON } from '@/constants'
import { getErrorMessage } from '@/utils'
import { Api, TokenApi } from '@/utils/api'

export const clearAlert = () => {
  return (dispatch) => {
    dispatch({
      type: COMMON.CLEAR_ALERT
    })
  }
}

export const getTreasuryData = () => {
  return async (dispatch) => {
    try {
      const res = await Api.get(`api/v1/treasury/`)
      dispatch({
        type: COMMON.TREASURY_DETAIL,
        payload: res
      })
    } catch (err) {
      dispatch({
        type: COMMON.TOP_ALERT,
        payload: { type: 'error', message: getErrorMessage(err) }
      })
    }
  }
}

export const getRentcastData = (values) => {
  return async (dispatch) => {
    try {
      const res = await TokenApi.post(`api/v1/rentcast/`, values)
      dispatch({
        type: COMMON.RENT_CAST_DETAIL,
        payload: res
      })
    } catch (err) {
      dispatch({
        type: COMMON.TOP_ALERT,
        payload: { type: 'error', message: getErrorMessage(err) }
      })
    }
  }
}

export const getMarketTrendsData = (values) => {
  return async (dispatch) => {
    try {
      const res = await TokenApi.post(`api/v1/market-trends/`, values)
      dispatch({
        type: COMMON.MARKET_TRENDS_DETAIL,
        payload: res
      })
    } catch (err) {
      dispatch({
        type: COMMON.TOP_ALERT,
        payload: { type: 'error', message: getErrorMessage(err) }
      })
    }
  }
}

export const getWalkscoreData = (values) => {
  return async (dispatch) => {
    try {
      const res = await TokenApi.post(`api/v1/walkscore/`, values)
      dispatch({
        type: COMMON.WALK_SCORE_DETAIL,
        payload: res
      })
    } catch (err) {
      dispatch({
        type: COMMON.TOP_ALERT,
        payload: { type: 'error', message: getErrorMessage(err) }
      })
    }
  }
}
