import React, { useEffect, useState } from 'react'
import { Row, Form, Button, Select, Statistic, InputNumber } from 'antd'
import InlineRow from '@/component/InlineRow'
import NumberInput from '@/component/NumberInput'
import { YES_NO_OPTIONS, EXPENSES_INFO } from '@/constants'
import FilledValuesTable from '@/component/FilledValuesTable'
import TooltipWithInfo from '@/component/TooltipWithInfo'
import Step8TransitionModal from './Step8TransitonModal'

export default function Step8Expenses(props) {
  const [form] = Form.useForm()
  const { dealInfo, dealInput, handleUpdate } = props
  const [showInputValues, setShowInputValues] = useState(false)
  const [showFilledValues, setShowFilledValues] = useState(false)
  const [showInputTaxRate, setShowInputTaxRate] = useState(false)
  const [showPropertyValue, setShowPropertyValue] = useState(false)
  const [filledTableInputValues, setFilledTableInputValues] = useState({})
  const [propertyValueTaxRate, setPropertyValueTaxRate] = useState(0)
  const [taxRate, setTaxRate] = useState(0)
  const [millageRate, setMillageRate] = useState(0)
  const [isModalVisible, setModalVisible] = useState(false)
  const [finalFormattedData, setFormattedData] = useState()
  const [physicalVacancy, setPhysicalVacancy] = useState(0)
  const [concessions, setConcessions] = useState(0)
  const [badDebt, setBadDebt] = useState(0)
  const [NOI, setNOI] = useState(0)
  const [valueAddOtherIncome, setValueAddOtherIncome] = useState(0)
  const [flatValue, setFlattenedValues] = useState([])
  const [total_gross_income,setTotalGrossIncome]=useState(0)
  const [gpr,setGPR]=useState(0);
  const [adjustedUtilityReimbursement,setAdjustedUtilityReimbursement] = useState(0);

  const purchase_price = dealInfo?.details?.property?.purchase_price ?? 0
  const { incomes = [], rent_roll = {}, value_add = {}, expenses = {} } = dealInfo?.details ?? {}
  const { utility_reimbursement = 0, other_income = 0 } = incomes.length > 0 ? incomes[0] : {}
  const {
    below_market_rent_per_unit = 0,
    units_below_market_rent = 0,
    gross_scheduled_income = 0
  } = rent_roll

  
  useEffect(() => {
    const value_add_details = JSON.parse(localStorage.getItem('value_add'));
    let value_add_other_income = 0, marketRentAmount = 0, renovatedAmount = 0;

    const billBackYear1Amount = value_add_details.rubs_value_add.bill_back_year_data[0].bill_back_year_amount;
    setAdjustedUtilityReimbursement(utility_reimbursement + billBackYear1Amount)

    if (value_add_details && value_add_details.extra_value_add) {
        const extraValueAdd = value_add_details.extra_value_add[0]?.rows ?? [];        
        value_add_other_income = extraValueAdd.reduce((acc, row) => {
          return acc + (row.monthly_cost_to_resident * row.quantity);
      }, 0) * 12;
        setValueAddOtherIncome(value_add_other_income ?? 0);
    }

    if (value_add_details && value_add_details.renovation_value_add?.length > 0) {
        marketRentAmount = (value_add_details.renovation_value_add[0].market_rent_amount ?? 0);
        renovatedAmount = (value_add_details.renovation_value_add[0].renovated_amount ?? 0);
    }


  const year1MarketRent =
    renovatedAmount + gross_scheduled_income + below_market_rent_per_unit * units_below_market_rent * 12
  const year1LossToLease = below_market_rent_per_unit * units_below_market_rent * 12 - marketRentAmount
  const gpr = year1MarketRent - year1LossToLease
  setGPR(gpr)
  const total_other_income= other_income+ value_add_other_income
  const total_rent_income = year1MarketRent - (year1LossToLease + concessions + physicalVacancy + badDebt)
  const total_gross_income = total_rent_income + adjustedUtilityReimbursement + total_other_income
  setTotalGrossIncome(total_gross_income)
  
  

  
  }, [physicalVacancy,concessions,badDebt])

  const handleTaxRateChange = (value) => {
    setTaxRate(value)
    const ans = value * 10
    setMillageRate(ans)
    form.setFieldsValue({ milage_rate: ans })
  }

  const handleMillageRateChange = (value) => {
    setMillageRate(value)
    const ans = (value / 1000) * 100
    setTaxRate(ans)
    form.setFieldsValue({ tax_rate: ans })
  }

  const handlePhysicalVacancyChange = (value) => {
    setPhysicalVacancy((value / 100) * gpr)
  }

  const handleConcessionsChange = (value) => {
    setConcessions((value / 100) * gpr)
  }

  const handleBadDebtChange = (value) => {
    setBadDebt((value / 100) * gpr)
  }

  const transformToCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(value)
  }

  const handleSubmit = async (values) => {
    setModalVisible(true)
    let {
      physical_vacancy_rate,
      concessions_rate,
      loss_rate,
      utility_reimbursement:adjustedUtilityReimbursement,
      other_income,
      tax_rate,
      milage_rate,
      tax_or_milage,
      property_value_taxed,
      is_property_value_taxed
    } = values
    const total_expense = filledTableInputValues?.total_expense ?? 0
    console.log({total_gross_income,total_expense})
    setNOI((total_gross_income - total_expense).toFixed(0))
    const formattedData = {
      physical_vacancy_rate,
      concessions_rate,
      loss_rate,
      utility_reimbursement:adjustedUtilityReimbursement,
      other_income,
      tax_rate,
      milage_rate,
      rot_units: filledTableInputValues,
      tax_or_milage,
      property_value_taxed,
      is_property_value_taxed
    }
    setFormattedData(formattedData)
  }

  const handleConfirm = async () => {
    setModalVisible(false)
    await handleUpdate(finalFormattedData)
  }

  const handleSelect = (value) => {
    setShowInputValues(!!value)
    setShowFilledValues(true)
  }

  const handleTaxRateSelect = (value) => {
    setShowInputTaxRate(!!value)
    setShowPropertyValue(!value)
    setPropertyValueTaxRate(purchase_price * 0.8 * taxRate)
  }

  useEffect(() => {
    if (localStorage.getItem('expenses')) {
      const localValues = JSON.parse(localStorage.getItem('expenses'))
      const flattenedValues = {
        ...localValues,
        ...localValues.rot_units
      }
      delete flattenedValues.rot_units
      setFlattenedValues(flattenedValues)
      if (flattenedValues?.tax_rate > 0 && flattenedValues?.milage_rate > 0) {
        handleSelect(true)
      }
      if (flattenedValues?.is_property_value_taxed) {
        handleTaxRateSelect(true)
      }
      form.setFieldsValue(flattenedValues)
    }
  }, [form])

  return (
    <div>
      <Form layout="vertical" form={form} onFinish={handleSubmit} requiredMark={false} preserve={false} colon={false}>
        <h1>Expenses</h1>
        <Row>
          <h3>How do you expect these values to be at the end of year 1 based on your business plan?</h3>
        </Row>

        <InlineRow>
          <Form.Item
            name="physical_vacancy_rate"
            label={
              <>
                {'Physical Vacancy %'}
                {<TooltipWithInfo info={EXPENSES_INFO[0]['Physical Vacancy %']} />}
              </>
            }
          >
            <InputNumber addonAfter="%" onChange={(value) => handlePhysicalVacancyChange(value)} />
          </Form.Item>

          <Form.Item
            name="concessions_rate"
            label={
              <>
                {'Concessions %'}
                {<TooltipWithInfo info={EXPENSES_INFO[0]['Concessions %']} />}
              </>
            }
          >
            <InputNumber addonAfter="%" onChange={(value) => handleConcessionsChange(value)} />
          </Form.Item>

          <Form.Item
            name="loss_rate"
            label={
              <>
                {'Loss / Bad Debt %'}
                {<TooltipWithInfo info={EXPENSES_INFO[0]['Loss / Bad Debt %']} />}
              </>
            }
          >
            <InputNumber addonAfter="%" onChange={(value) => handleBadDebtChange(value)} />
          </Form.Item>
        </InlineRow>
        <InlineRow>
          <Statistic
            title={
              <>
                {'In Place Utility Reimbursement'}
                {<TooltipWithInfo info={EXPENSES_INFO[0]['In Place Utility Reimbursement']} />}
              </>
            }
            value={transformToCurrency(adjustedUtilityReimbursement)}
            precision={0}
          />
          <Statistic
            title={
              <>
                {'Other Income'}
                {<TooltipWithInfo info={EXPENSES_INFO[0]['Other Income']} />}
              </>
            }
            value={transformToCurrency(other_income + valueAddOtherIncome)}
            precision={0}
          />
        </InlineRow>

        <InlineRow>
          <Form.Item
            label="Do you know the tax or millage rate for this property?"
            name="tax_or_milage"
            value={flatValue?.tax_rate > 0 ?? true}
            rules={[{ required: true, message: 'Required' }]}
          >
            <Select options={YES_NO_OPTIONS} onChange={(e) => handleSelect(e)} />
          </Form.Item>
        </InlineRow>
        {showInputValues && (
          <div>
            <InlineRow>
              <Form.Item
                name="tax_rate"
                label={
                  <>
                    {'Tax Rate'}
                    {<TooltipWithInfo info={EXPENSES_INFO[0]['Tax Rate']} />}
                  </>
                }
              >
                <InputNumber
                  addonAfter="%"
                  value={taxRate}
                  onChange={(value) => {
                    handleTaxRateChange(value)
                  }}
                  step={0.001}
                />
              </Form.Item>
              <Form.Item
                name="milage_rate"
                label={
                  <>
                    {'Millage Rate'}
                    {<TooltipWithInfo info={EXPENSES_INFO[0]['Millage Rate']} />}
                  </>
                }
              >
                <InputNumber
                  addonAfter="%"
                  value={millageRate}
                  onChange={(value) => {
                    handleMillageRateChange(value)
                  }}
                />
              </Form.Item>
            </InlineRow>
            <Form.Item
              label="Do you know how much of the property value is assessed or being taxed?"
              name="is_property_value_taxed"
              rules={[{ required: true, message: 'Required' }]}
            >
              <Select options={YES_NO_OPTIONS} onChange={(e) => handleTaxRateSelect(e)} />
            </Form.Item>
            {showInputTaxRate && (
              <Form.Item
                name="property_value_taxed"
                label={
                  <>
                    {'Input Rate (Tax rate)'}
                    {<TooltipWithInfo info={EXPENSES_INFO[0]['Input Rate (Tax rate)']} />}
                  </>
                }
              >
                <InputNumber addonAfter="%" onChange={(value) => setPropertyValueTaxRate(value)} />
              </Form.Item>
            )}
          </div>
        )}

        <FilledValuesTable
          initialData={flatValue || {}}
          fillableData={dealInfo?.details?.expenses?.[0] || []}
          totalNumberOfUnit={dealInfo?.details?.property?.number_of_units}
          onInputValuesChange={(value) => {
            setFilledTableInputValues(value)
          }}
          gross_income={total_gross_income}
        />

        <Button type="primary" className="next-btn btn" htmlType="submit">
          Next
        </Button>
      </Form>

      {isModalVisible && (
        <Step8TransitionModal
          onClose={() => setModalVisible(false)}
          onConfirm={handleConfirm}
          sellersNOI={transformToCurrency(dealInfo?.details?.expenses?.[0]?.noi || 0)}
          yourNOI={transformToCurrency(NOI)}
        />
      )}
    </div>
  )
}
