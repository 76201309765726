import React, { useState } from 'react'
import { Modal, Button } from 'antd'
import { parse } from 'postcss'

export default function Step8TransitionModal({ onClose, onConfirm, sellersNOI=0, yourNOI=0 }) {
  return (
    <Modal centered open={true} closable={false}  footer={null} width={600} bodyStyle={{ padding: ' 40px' }}>
      <div className="grid gap-10 justify-center">
        <div className="flex flex-col  gap-4">
          <div className="flex w-full justify-center">
            <span className="text-xl font-bold">NOI Overview</span>
          </div>
          <div className="grid grid-cols-2 gap-10">
            <div className="grid">
              <span>Your NOI</span>
              <span className="text-center">{yourNOI}</span>
            </div>
            <div className="grid">
              <span>Sellers NOI</span>
              <span className="text-center">{sellersNOI}</span>
            </div>
          </div>
        </div>

        <div className="grid place-items-center ">
          <Button type="primary" onClick={onConfirm}>
            Continue
          </Button>
        </div>
      </div>
    </Modal>
  )
}
