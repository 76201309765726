import React, { useEffect, useState } from 'react'
import { Row, Col, Divider, Form, Button, Space, Input, Select } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import InlineRow from '@/component/InlineRow'
import NumberInput from '@/component/NumberInput'
import TextInput from '@/component/TextInput'
import { PROPERTY_CLASS_OPTIONS, HOLD_TIME_OPTIONS, YEAR_OPTIONS } from '@/constants'

const Step2_Property = (props) => {
  const [form] = Form.useForm()
  const { dealInfo, handleUpdate } = props

  const handleSubmit = async (values) => {
    await handleUpdate({ property: values })
  }

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={handleSubmit}
      requiredMark={false}
      preserve={false}
      colon={false}
      initialValues={dealInfo?.details?.property}
    >
      <h1 className="mb-4">Propery details</h1>
      <InlineRow>
        <TextInput label="Property Name" name="name" />
        <TextInput label="Address" name="address" />
      </InlineRow>
      <InlineRow>
        <NumberInput label="Number Of units" name="number_of_units" />
        <NumberInput label="Purchase Price" name="purchase_price" pricing={true} />
      </InlineRow>
      <InlineRow>
        <NumberInput label="Price Per Unit" name="asking_price_per_unit" pricing={true} />
        <Form.Item label="Year Built" name="year_built">
          <Select options={YEAR_OPTIONS} showSearch />
        </Form.Item>
      </InlineRow>
      <InlineRow>
        <NumberInput label="Rentable Square Foot" name="rentable_square_foot" />
        <Form.Item label="Hold Time" name="hold_time">
          <Select options={HOLD_TIME_OPTIONS} />
        </Form.Item>
      </InlineRow>
      <InlineRow>
        <Form.Item label="Property Class" name="property_class">
          <Select options={PROPERTY_CLASS_OPTIONS} />
        </Form.Item>
        <NumberInput label="Market Cap Rate" name="market_cap_rate" rating={true} />
      </InlineRow>
      <Button type="primary" className="next-btn btn" htmlType="submit">
        Next
      </Button>
    </Form>
  )
}

export default Step2_Property
