import React from 'react'
import { Form, InputNumber } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { COMMON_RULE } from '@/constants'

const ShadowNumberInput = ({
  name,
  label,
  required=true,
  pricing,
  rating,
  ...params
}) => {

  return (
    <Form.Item label={label} name={name} rules={[{ required: required, message: "Required"}]}>
      <InputNumber
        {...params}
        className="number-input"
        prefix={pricing? "$": ""}
        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      />
    </Form.Item>
  )
}

export default ShadowNumberInput