import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { Row, Col, Divider, Card, Statistic, Input, Spin, Button, Space } from 'antd'
import { CreditCardOutlined, SearchOutlined, PlusOutlined } from '@ant-design/icons'
import DealActions from '@/redux/deal'
import CommonActions from '@/redux/common'
import Charts from './charts'
import './style.less'
import { useSearchParams } from 'react-router-dom';

const Dashboard = () => {
  const dispatch = useDispatch()
  const navigate=useNavigate()
  const [searchParams] = useSearchParams();
  const { userInfo, is_authed } = useSelector((state) => state.auth)
  const { dealList } = useSelector((state) => state.deal)
  const [loading, setLoading] = useState(false)

  const searchKey = useMemo(() => {
    let query = searchParams.get('query') || '';
    return query
  }, [searchParams])

  useEffect(() => {
    const fetchData = async () => {
      if (userInfo) {
        setLoading(true);
        await dispatch(DealActions.getDealList(userInfo?.id, searchKey));
        await dispatch(CommonActions.getTreasuryData());
        setLoading(false);
      }
    };
  
    fetchData();
  
  }, [userInfo]);
  
  const handleAddProperty = () => {
    dispatch(DealActions.resetDealState())
    navigate('/dashboard/create-deal')
  }
  

  const handleSearch = (value) => {
    navigate(`/dashboard?query=${value}`)
  }

  return (
    <div className="dashboard-page overview-page">
      <Spin spinning={loading}>
        <div className="property-actions">
          <Input.Search
            className="search-input"
            placeholder="Search property"
            defaultValue={searchKey}
            onSearch={handleSearch}
          />
          <Button onClick={handleAddProperty} className="btn" icon={<PlusOutlined />}>
            Add Property
          </Button>
        </div>
        <Charts loading={loading} />
        <Row gutter={[16, 16]} className="statistic-items">
          <Col span={12} md={6}>
            <Card>
              <CreditCardOutlined />
              <Statistic title="No of properties" value={userInfo?.summary?.number_of_properties} precision={0} />
            </Card>
          </Col>
          <Col span={12} md={6}>
            <Card>
              <CreditCardOutlined />
              <Statistic title="No of units" value={userInfo?.summary?.total_number_of_units} precision={0} />
            </Card>
          </Col>
          <Col span={12} md={6}>
            <Card>
              <CreditCardOutlined />
              <Statistic title="Average current rent" value={0} precision={0} />
            </Card>
          </Col>
          <Col span={12} md={6}>
            <Card>
              <CreditCardOutlined />
              <Statistic title="Total target amount" value={0} precision={0} />
            </Card>
          </Col>
        </Row>
        <h2>All Properties</h2>
        {dealList?.results
          ?.filter((deal) => deal?.property?.name?.toLowerCase().indexOf(searchKey.toLowerCase()) > -1)
          .map((deal) => (
            <Link to={`/dashboard/underwriting/${deal.id}`} key={deal.id}>
              <Card className="property-item">
                <Row gutter={[48, 16]}>
                  <Col span={24} md={6}>
                    <img src="/images/property.png" />
                  </Col>
                  <Col span={24} md={18}>
                    <h2>{deal.property.name || 'Unknown name'}</h2>
                    <Space className="mb-2">
                      <Statistic title="Address" value={`${deal.property.address || '-'} `} />
                    </Space>
                    <br />
                    <Space size={32}>
                      <Statistic title="Number of Units" value={deal.property.number_of_units} precision={0} />
                      <Statistic title="Purchase Price" value={deal.property.purchase_price} precision={0} prefix="$" />
                      <Statistic
                        title="Asking Price"
                        value={deal.property.asking_price_per_unit}
                        precision={0}
                        prefix="$"
                      />
                    </Space>
                  </Col>
                </Row>
              </Card>
            </Link>
          ))}
      </Spin>
    </div>
  )
}

export default Dashboard
