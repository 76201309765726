import { DEAL } from '@/constants';

// Load step index from localStorage or default to 0
const initialStepIndex = 0;

const initState = {
  dealInfo: null,
  dealList: [],
  stepIndex: parseInt(initialStepIndex), // Initialize step index
  dealInput: {
    property: {},
    rent_roll: {},
    rent_unit_mix: {},
    income_expenses: {},
    investment_strategy: {},
    value_add: {},
    expenses: {},
    loan_financing: {},
    sources_other: {},
    growth_rate: {},

  }
}

const setNestedProperty = (obj, path, value) => {
  const keys = path.split('.');
  let current = obj;
  for (let i = 0; i < keys.length - 1; i++) {
    if (!current[keys[i]]) {
      current[keys[i]] = {};
    }
    current = current[keys[i]];
  }
  current[keys[keys.length - 1]] = value;
  return obj;
};

const setDealInput = (state, payload) => {  
  const { key, value } = payload;
  const updatedDealInput = { ...state.dealInput };
  setNestedProperty(updatedDealInput, key, value);
  return { ...state, dealInput: updatedDealInput };
};

const DealReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case DEAL.RESET_DEAL:
      return initState;
    case DEAL.DEAL_INTRO:
      return {
        ...state,
        dealInfo: null,
        stepIndex: 0 // Reset stepIndex to 0 in DEAL_INTRO action
      };
    case DEAL.DEAL_LOAD:
      return {
        ...state,
        dealInfo: payload,
        stepIndex: payload.moveNext ? state.stepIndex + 1 : state.stepIndex,
      };
    case DEAL.DEAL_DETAIL:
      // Check if the last step is completed
      if (state.stepIndex === 10) {
        // Store the returned status and values in dealInfo
        return {
          ...state,
          dealInfo: {
            ...state.dealInfo,
            status: payload.status, // Assuming payload has a 'status' property
            values: payload.values // Assuming payload has a 'values' property containing the returned values
          },
          dealInput: {
            ...state.dealInput,
            ...payload.values // Assuming payload contains values from the last step
          }
        };
      } else {
        // Update stepIndex and dealInfo as usual for other steps
        return {
          ...state,
          dealInfo: payload,
          stepIndex: payload.moveNext ? state.stepIndex + 1 : state.stepIndex,
          dealInput: {
            ...state.dealInput,
            ...payload.details // Assuming payload contains details from the current step
          }
        };
      }
    case DEAL.DEAL_LIST:
      return {
        ...state,
        dealList: payload
      };
    case DEAL.DEAL_NOT_EXIST:
      return {
        ...state,
        dealInfo: payload,
        stepIndex: 0 // Reset stepIndex to 0 in DEAL_NOT_EXIST action
      };
    case DEAL.DEAL_STEP:
      return {
        ...state,
        stepIndex: payload
      };

    case DEAL.DEAL_UPDATE_VALUE:
      return setDealInput(state, payload);
    default:
      return state;
  }
};

export default DealReducer;


// const DealReducer = (state = initState, action) => {
//   const { type, payload } = action;

//   switch (type) {
//     case DEAL.DEAL_INTRO:
//       return {
//         ...state,
//         dealInfo: null,
//         stepIndex: 0 // Reset stepIndex to 0 in DEAL_INTRO action
//       };
//     case DEAL.DEAL_LOAD:
//       return {
//         ...state,
//         dealInfo: payload,
//         stepIndex: 2
//       };
//     case DEAL.DEAL_INIT:
//       return {
//         ...state,
//         dealInfo: null,
//         stepIndex: 1
//       };
//     case DEAL.DEAL_DETAIL:
//       return {
//         ...state,
//         dealInfo: payload,
//         stepIndex: payload.moveNext ? state.stepIndex + 1 : state.stepIndex,
//         dealInput: { ...state.dealInput, incomes: payload.details.incomes[3], expenses: payload.details.expenses[3], market_rent_growth: payload.details.market_rent_growth, financing: { ...payload.details.financing, down_payment: payload.details?.financing.down_payment === 0 ? payload.financials?.financing.down_payment : payload.details?.financing.down_payment }, property: { property_class: payload.details.property.property_class, market_cap_rate: payload.details.property.market_cap_rate, hold_time: payload.details.property.hold_time }, value_add: { rental_increase_per_renovated_unit: payload.details.value_add.rental_increase_per_renovated_unit, units_that_need_upgrades: payload.details.value_add.units_that_need_upgrades, length_of_renovations: payload.details.value_add.length_of_renovations, other_income: payload.details.value_add.other_income, additionals: payload.details.value_add.additionals }, rent_roll: { below_market_rent_per_unit: payload.details.rent_roll.below_market_rent_per_unit, units_below_market_rent: payload.details.rent_roll.units_below_market_rent }, other: { manager_split: payload.details.other.manager_split, preferred_rate: payload.details.other.preferred_rate, capital_reserves_per_unit: payload.details.other.capital_reserves_per_unit } }
//       };
//     case DEAL.DEAL_LIST:
//       return {
//         ...state,
//         dealList: payload
//       };
//     case DEAL.DEAL_NOT_EXIST:
//       return {
//         ...state,
//         dealInfo: payload,
//         stepIndex: 0 // Reset stepIndex to 0 in DEAL_NOT_EXIST action
//       };
//     case DEAL.DEAL_STEP:
//       return {
//         ...state,
//         stepIndex: payload
//       };
//     case DEAL.DEAL_UPDATE_VALUE:
//       return {
//         ...state,
//         dealInput: setDealInput(state.dealInput, payload)
//       };
//     default:
//       return state;
//   }
// }





