import React, { useEffect, useState } from 'react'
import { Row, Col, Divider, Form, Button, Space, Input } from 'antd'
import InlineRow from '@/component/InlineRow'
import NumberInput from '@/component/NumberInput'
import TextInput from '@/component/TextInput'
import TextLabel from '@/component/TextLabel'
import DealActions from '@/redux/deal'
import { INCOMES_PROPERTIES, EXPENSES_PROPERTIES } from '@/constants'

const Step3_IncomeAndExpense = (props) => {
  const [form] = Form.useForm()
  const { dealInfo, handleUpdate } = props

  // Setting default state for NOI Editable Field
  const [noiEditable, setNoiEditable] = useState(false)

  const handleSubmit = async (values) => {
    await handleUpdate(values)
  }

  let initialValues = dealInfo?.details
  initialValues['expenses'][0]['noi'] = dealInfo?.financials?.summary.financials.net_operating_income
  // initialValues['expenses'][2]['noi'] = dealInfo?.financials?.summary.financials.take_over_noi

  const toggleNoiEditable = (e) => {
    setNoiEditable(!noiEditable)
  }

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={handleSubmit}
      requiredMark={false}
      preserve={false}
      colon={false}
      initialValues={initialValues}
    >
      <h1 className="mb-4">Incomes & Expenses</h1>
      <Divider>Income</Divider>
      <InlineRow>
        <TextLabel></TextLabel>
        <TextLabel>Sellers:</TextLabel>
        <TextLabel>Year1:</TextLabel>
      </InlineRow>
      {INCOMES_PROPERTIES.map((prop) => (
        <InlineRow key={prop.value}>
          <TextLabel>{prop.label}:</TextLabel>
          <NumberInput name={['incomes', 0, prop.value]} pricing={true} />
          <NumberInput
            name={['incomes', 2, prop.value]}
            pricing={!prop.rating}
            rating={prop.rating}
            readOnly={prop.readOnly}
          />
        </InlineRow>
      ))}
      <Divider>Expenses</Divider>
      <InlineRow>
        <TextLabel></TextLabel>
        <TextLabel>Sellers:</TextLabel>
        <TextLabel>Year1 (Per Unit):</TextLabel>
      </InlineRow>
      {EXPENSES_PROPERTIES.map((prop) => (
        <InlineRow key={prop.value}>
          <TextLabel>{prop.label}:</TextLabel>
          <NumberInput
            name={['expenses', 0, prop.value]}
            pricing={true}
            readOnly={prop.value === 'noi' ? !noiEditable : prop.readOnly}
          />
          <NumberInput
            name={['expenses', 2, prop.value]}
            pricing={!prop.rating}
            rating={prop.rating}
            readOnly={prop.value === 'noi' ? !noiEditable : prop.readOnly}
          />
        </InlineRow>
      ))}
      <div>
        <label>
          <input type="checkbox" onChange={toggleNoiEditable} value={noiEditable} />
          &nbsp; Edit NOI
        </label>
      </div>
      <Button type="primary" className="next-btn btn" htmlType="submit">
        Next
      </Button>
    </Form>
  )
}

export default Step3_IncomeAndExpense
