import React from 'react'
import { Row, Col } from 'antd'

const InlineRow = ({ children, ...params }) => {
  const colSpan = 24 / children.length

  return (
    <Row gutter={24} {...params}>
      {Array.isArray(children) ? (
        children.map((child, idx) => (
          <Col span={colSpan} key={idx}>
            {child}
          </Col>
        ))
      ) : (
        <Col span={12}>{children}</Col>
      )}
    </Row>
  )
}

export default InlineRow
