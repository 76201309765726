import moment from 'moment'

export function isPhoneNumber(val) {
  new RegExp(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/).test(val)
}

export function getPhoneNumber(val) {
  return '+' + val.code + val.phone
}

export function dateFormat(date) {
  return moment(new Date(date)).format('lll')
}

export function formSubmitDateFormat(date) {
  if (!date) return null

  return moment(new Date(date)).format('YYYY-MM-DD')
}

export function formatNumber(value) {
  value += ''
  const list = value.split('.')
  const prefix = list[0].charAt(0) === '-' ? '-' : ''
  let num = prefix ? list[0].slice(1) : list[0]
  let result = ''

  while (num.length > 3) {
    result = `,${num.slice(-3)}${result}`
    num = num.slice(0, num.length - 3)
  }

  if (num) {
    result = num + result
  }
  return `${prefix}${result}${list[1] ? `.${list[1]}` : ''}`
}

export function getErrorMessage(err) {
  let messages = []
  Object.keys(err).map((key) => messages.push(err[key]))
  return messages.join('\n')
}
