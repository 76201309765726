// Redux Type Constants
export const COMMON = {
  TOP_ALERT: 'TOP_ALERT',
  CHOOSE_BG: 'CHOOSE_BG',
  CLEAR_ALERT: 'CLEAR_ALERT',
  DETAIL: 'DETAIL',
  RENT_CAST_DETAIL: 'RENT_CAST_DETAIL',
  MARKET_TRENDS_DETAIL: 'MARKET_TRENDS_DETAIL',
  WALK_SCORE_DETAIL: 'WALK_SCORE_DETAIL',
  TREASURY_DETAIL: 'TREASURY_DETAIL'
}

export const AUTH = {
  SIGNED_IN: 'SIGNED_IN',
  SIGNED_OUT: 'SIGNED_OUT',
  USER_PROFILE: 'USER_PROFILE',
  USER_INFO: 'USER_INFO',
  UPDATE_USER: 'UPDATE_USER',
  OTP_STATUS: 'OTP_STATUS'
}

export const SUBSCRIPTIONS = {
  LIST:'LIST'
}

export const PROFILE = {
  USER_DETAIL: 'USER_DETAIL',
  USER_NOT_EXIST: 'USER_NOT_EXIST'
}

export const DEAL = {
  DEAL_INTRO: 'DEAL_INTRO',
  DEAL_INIT: 'DEAL_INIT',
  DEAL_LOAD: 'DEAL_LOAD',
  DEAL_DETAIL: 'DEAL_DETAIL',
  DEAL_LIST: 'DEAL_LIST',
  DEAL_NOT_EXIST: 'DEAL_NOT_EXIST',
  DEAL_UPDATE_VALUE: 'DEAL_UPDATE_VALUE',
  DEAL_STEP: 'DEAL_STEP',
  RESET_DEAL: 'RESET_DEAL',
}

export const DASHBOARD = {
  ITEM_LIST: 'ITEM_LIST'
}

export const COMMON_RULE = [{ required: true, message: 'Required' }]

export const PAGE_NUMBER_OPTIONS = [...Array(100)].map((val, idx) => ({
  value: idx + 1,
  label: `${idx + 1} Page`
}))

export const FINANCIAL_TABLE_OPTIONS = [
  { value: 'incomes', label: 'Income Table' },
  { value: 'expenses', label: 'Expenses Table' },
  { value: 'property_returns', label: 'Property Return Table' },
  { value: 'investor_returns', label: 'Investors Return(s) Table' },
  { value: 'others', label: 'Other Table' }
]

export const FINANCIAL_TABLE_TITLES = {
  incomes: 'Income',
  expenses: 'Expenses',
  property_returns: 'Property returns',
  investor_returns: 'Investors returns',
  others: 'Others'
}

export const PROPERTY_CLASS_OPTIONS = [
  { value: 'A', label: 'A' },
  { value: 'B', label: 'B' },
  { value: 'C', label: 'C' },
  { value: 'D', label: 'D' }
]


export const RENT_ROLL_OPTIONS = [
  { value: 'current_vacant_income', label: 'Current Vacant Monthly Income' },
  { value: 'number_of_vacant_units', label: '# of Vacant Units' },
  { value: 'occupancy_rate', label: 'Occupancy %' },
]

export const UNIT_OPTIONS = [...Array(5)].map((val, idx) => ({
  value: idx + 1,
  label: `${idx + 1}`
}))


export const HOLD_TIME_OPTIONS = [...Array(10)].map((val, idx) => ({
  value: idx + 1,
  label: `${idx + 1}`
}))

export const YEAR_OPTIONS = [...Array(71)].map((val, idx) => ({
  value: 1960 + idx,
  label: `${1960 + idx}`
}))

export const GENDER_OPTIONS = [
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' }
]

export const UPGRADE_PLAN_OPTIONS = [
  // {
  //   name: 'Free',
  //   membership_plan: 'free',
  //   description: 'Explore Axiom Suites for free.',
  //   price: {
  //     month: 0,
  //     year: 0
  //   },
  //   items: [
  //     'Unlimited Rent Roll & T-12 uploads via Excel',
  //     '24/7 AI enabled chatbot coach',
  //     'Daily treasury & interest rate integrations'
  //   ]
  // },
  // {
  //   name: 'Standard',
  //   membership_plan: 'standard',
  //   description: "Explore Axiom's Standard Package.",
  //   price: {
  //     month: '249',
  //     year: '2690'
  //   },
  //   items: [
  //     'Unlimited Rent Roll & T-12 uploads via Excel',
  //     'Unlimited Rent Roll & T-12 uploads via PDF or Offering Memorandum',
  //     '24/7 AI enabled chatbot coach',
  //     'Daily treasury & interest rate integrations',
  //     '10-Year financial forecasting',
  //     'Saving capabilities'
  //   ]
  // },
  {
    name: 'Beta',
    membership_plan: 'beta',
    description: "Explore Axiom's Beta Package.",
    price: {
      month: '19',
      year: ((19 * 12) * 0.90).toFixed(2) 
    },
    items: [
      'Unlimited Rent Roll & T-12 underwriting',
      '24/7 AI enabled chatbot coach',
      '10-Year financial forecasting',
      'Priority customer support',
    ]
  }
]

export const INCOMES_PROPERTIES = [
  { value: 'gross_potential_rent', label: 'Gross Potential Rent (GPR)' },
  { value: 'loss_to_lease', label: 'Loss To Lease' },
  { value: 'physical_vacancy', label: 'Physical Vacancy' },
  { value: 'concessions', label: 'Concessions' },
  { value: 'bad_debt', label: 'Loss / Bad Debt' },
  { value: 'non_revenue_units', label: 'Non-Revenue Units (models, office, etc)' },
  { value: 'utility_reimbursement', label: 'Utility Reimbursement' },
  { value: 'other_income', label: 'Other Income' }
]

export const INCOMES = [
  { value: 'market_rent', label: 'Market Rent' },
  { value: 'loss_to_lease', label: 'Loss To Lease' },
  { value: 'gross_potential_rent', label: 'Gross Potential Rent (GPR)' },
  { value: 'physical_vacancy', label: 'Physical Vacancy' },
  { value: 'concessions', label: 'Concessions' },
  { value: 'bad_debt', label: 'Loss / Bad Debt' },
  { value: 'non_revenue_units', label: 'Non-Revenue Units (models, office, etc)' },
  { value: 'utility_reimbursement', label: 'Utility Reimbursement' },
  { value: 'other_income', label: 'Other Income' },
  { value: 'total_gross_income', label: 'Gross Income' },
]

export const EXPENSES_PROPERTIES = [
  { value: 'taxes', label: 'Taxes' },
  { value: 'utility', label: 'Utility' },
  { value: 'payroll', label: 'Payroll' },
  { value: 'repairs_and_maintenance', label: 'Repairs And Maintenance' },
  { value: 'insurance', label: 'Insurance' },
  { value: 'management', label: 'Management Fee' },
  { value: 'contract_services', label: 'Contract Services' },
  { value: 'general_admin', label: 'General & Admin / Legal' },
  { value: 'marketing', label: 'Marketing' },
  { value: 'misc', label: 'Misc' },
  // { value: 'noi', label: 'NOI', readOnly: true }
]

export const FILE_TYPE_OPTIONS = [
  { value: 'pdf', label: 'PDF' },
  { value: 'Excel', label: 'EXCEL' }
]

export const PROPERTY_TYPE_OPTIONS = [
  { value: 'Single Family', label: 'Single Family' },
  { value: 'Condo', label: 'Condo' },
  { value: 'Townhouse', label: 'Townhouse' },
  { value: 'Multi-Family', label: 'Multi-Family' },
  { value: 'Apartment', label: 'Apartment' }
]

export const BEDROOM_OPTIONS = [
  { value: '0', label: 'Studio' },
  { value: '1', label: '1 Bed' },
  { value: '2', label: '2 Beds' },
  { value: '3', label: '3 Beds' },
  { value: '4', label: '4 Beds' },
  { value: '5', label: '5 Beds' },
  { value: '6', label: '6+ Beds' }
]

export const BATHROOM_OPTIONS = [
  { value: '1', label: '1 Bath' },
  { value: '1.5', label: '1.5 Baths' },
  { value: '2', label: '2 Baths' },
  { value: '2.5', label: '2.5 Baths' },
  { value: '3', label: '3 Baths' },
  { value: '3.5', label: '3.5 Baths' },
  { value: '4', label: '4+ Baths' }
]

export const DISTANCE_OPTIONS = [...Array(20)].map((val, idx) => ({
  value: `${idx + 1}mi`,
  label: `${idx + 1}mi`
}))


export const INCREASE_INCOME_OPTIONS = [
  { value: 'value_add', label: 'Value add' },
  { value: 'expenses', label: 'Expenses' },
  { value: 'both', label: 'Both' },
]

export const YES_NO_OPTIONS = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' }
]

export const FAQ = [
  {
    "Basic": {
      "1. What is underwriting, and why is it important in multifamily real estate investing?": "Underwriting involves assessing the financial viability and risks of a real estate investment. It's crucial in multifamily investing because it helps investors make informed decisions and avoid potentially risky deals.",
      "2. What are the primary goals of underwriting a multifamily property?": "The main goals of underwriting are to evaluate the property's income potential, assess its ability to generate cash flow, determine its overall profitability, and mitigate investment risks.",
      "3. What are some common underwriting terms and metrics I should know as a beginner investor?": "Familiarize yourself with terms like net operating income (NOI), capitalization (cap) rate, debt service coverage ratio (DSCR), cash-on-cash return, and internal rate of return (IRR) to better understand the financial aspects of underwriting.",
      "4. How do I estimate rental income and expenses when underwriting a multifamily property?": "Start by researching local rental market trends to estimate potential rental income. Then, identify and estimate expenses such as property taxes, insurance, maintenance, utilities, and property management fees based on industry benchmarks and historical data.",
      "5. What role does market research play in multifamily real estate underwriting?": "Market research helps investors understand local market dynamics, rental demand, vacancy rates, economic trends, and competitor properties. This information informs underwriting decisions and helps investors assess the property's competitive position.",
      "6. How can I assess the potential risks associated with a multifamily investment during underwriting?": "Identify and analyze risks such as market volatility, tenant turnover, economic downturns, regulatory changes, and property-specific factors like deferred maintenance or environmental hazards to evaluate their potential impact on investment returns.",
      "7. What factors should I consider when evaluating the location of a multifamily property?": "Look for properties in desirable locations with strong rental demand, access to amenities, good school districts, low crime rates, and proximity to employment centers, transportation, and retail areas.",
      "8. How do I determine the appropriate financing options for a multifamily investment?": "Research different financing options such as conventional loans, FHA loans, and commercial loans to find the best fit for your investment goals, financial situation, and risk tolerance. Consider factors like interest rates, loan terms, and down payment requirements.",
      "9. What are some common mistakes to avoid when underwriting multifamily properties as a beginner?": "Avoid common pitfalls such as overestimating rental income, underestimating expenses, neglecting due diligence, ignoring market trends, and failing to account for potential risks. Seek guidance from experienced professionals and learn from their expertise.",
      "10. How can I improve my underwriting skills and gain confidence as a beginner investor?": "Continuously educate yourself by reading books, attending seminars, taking online courses, and networking with experienced investors and industry professionals. Practice underwriting different properties, seek feedback, and learn from your experiences to improve your skills over time."
    }

  },
  {
    "Technical": {
      "1. What data sources are commonly used in multifamily real estate underwriting?": "Underwriters typically rely on sources such as rent rolls, historical financial statements, property appraisals, market research reports, and demographic data to inform their analysis.",
      "2. How do underwriters calculate the debt service coverage ratio (DSCR) for multifamily properties?": "DSCR is calculated by dividing the property's net operating income (NOI) by its annual debt service (mortgage payments). A DSCR of 1.25 or higher is often required by lenders to ensure sufficient cash flow to cover debt obligations.",
      "3. What factors influence the property's net operating income (NOI), and how do underwriters project future NOI?": "NOI is influenced by factors such as rental income, vacancy rates, operating expenses, and property management fees. Underwriters project future NOI based on assumptions about rent growth, expense escalation, and market conditions.",
      "4. How do underwriters evaluate the accuracy of rent roll and lease information provided by sellers?": "Underwriters verify rent roll and lease information by comparing it to historical records, conducting tenant interviews, and reviewing lease agreements. They may also analyze market rents and vacancy rates to validate the information.",
      "5. What role does sensitivity analysis play in multifamily real estate underwriting?": "Sensitivity analysis involves testing the impact of various scenarios (e.g., rent decreases, higher vacancy rates) on the property's financial performance. Underwriters use sensitivity analysis to assess the property's resilience to potential risks and uncertainties.",
      "6. How do underwriters assess the potential for value-add opportunities in multifamily properties?": "Underwriters evaluate the feasibility and impact of value-add strategies such as renovations, repositioning, or operational improvements. They analyze the costs, benefits, and risks associated with implementing these strategies to determine their effect on property value.",
      "7. What methods do underwriters use to estimate property operating expenses?": "Underwriters estimate operating expenses by categorizing them into fixed expenses (e.g., property taxes, insurance) and variable expenses (e.g., maintenance, utilities). They may use industry benchmarks, historical data, and market research to estimate expenses accurately.",
      "8. How do underwriters assess the property's capitalization (cap) rate and market value?": "Underwriters calculate the property's cap rate by dividing its NOI by its market value. They compare the cap rate to market benchmarks and consider factors such as location, property condition, and comparable sales to determine the property's market value.",
      "9. What are the key underwriting considerations for financing a multifamily property through syndication or joint venture partnerships?": "Underwriters evaluate the financial structure, ownership interests, and risk-sharing arrangements of syndicated or joint venture deals. They assess the experience and track record of the sponsors and analyze the potential returns and risks for investors.",
      "10. How do underwriters assess the impact of interest rate changes on multifamily financing terms and investment returns?": "Underwriters analyze the sensitivity of financing terms (e.g., loan-to-value ratio, interest rates) to changes in market interest rates. They evaluate the potential impact on cash flow, debt service coverage, and overall investment returns to assess interest rate risk."
    }
  },
  {
    "Hybrid": {
      "1. What role do market trends play in multifamily real estate underwriting?": "Market trends, such as rental demand, supply levels, and economic indicators, are crucial considerations in underwriting. Analyzing these trends helps investors assess the potential risks and opportunities of a multifamily investment.",
      "2. How does underwriting differ for different types of multifamily properties, such as Class A, B, and C?": "Underwriting criteria may vary depending on the class of multifamily property. Class A properties may have stricter underwriting standards due to their higher-quality amenities and tenant base, while Class B and C properties may be evaluated based on different risk factors such as location and property condition.",
      "3. What impact does financing structure have on underwriting multifamily properties?": "The type of financing (e.g., conventional, FHA, commercial) can influence underwriting requirements and criteria. Understanding how financing structures affect underwriting helps investors tailor their approach to different lenders and financing options.",
      "4. How does underwriting account for potential regulatory changes or zoning issues?": "Underwriters assess the regulatory landscape and zoning ordinances to identify potential risks that could affect the property's value or operational feasibility. Understanding these factors helps investors anticipate and mitigate regulatory risks.",
      "5. What due diligence should investors conduct during the underwriting process?": "Conducting thorough due diligence, including property inspections, title searches, environmental assessments, and legal reviews, is essential for identifying potential issues that could impact the investment's success. Investors should work with experienced professionals to ensure comprehensive due diligence.",
      "6. How do underwriters evaluate the strength of the property management team?": "Underwriters assess the experience, qualifications, and track record of the property management team to gauge their ability to effectively manage the property and mitigate operational risks. A strong management team can positively influence underwriting decisions.",
      "7. What strategies can investors use to negotiate favorable terms during the underwriting process?": "Investors can leverage market knowledge, property performance data, and competitive financing offers to negotiate favorable terms with lenders. Understanding the lender's underwriting criteria and demonstrating the property's potential can strengthen negotiation positions.",
      "8. What role does environmental sustainability play in multifamily real estate underwriting?": "Increasingly, underwriters consider environmental sustainability factors, such as energy efficiency, green building certifications, and sustainable design features, when evaluating multifamily properties. Properties with strong sustainability credentials may receive favorable underwriting treatment.",
      "9. How do underwriters assess the potential impact of economic downturns or market volatility on multifamily investments?": "Underwriters analyze economic indicators, market stability, and historical performance data to assess the resilience of multifamily investments to economic downturns or market fluctuations. Stress testing scenarios and sensitivity analyses help quantify potential risks.",
      "10. What strategies can investors use to mitigate underwriting risks and enhance investment returns?": "Investors can mitigate underwriting risks by diversifying their portfolios, conducting thorough due diligence, maintaining adequate reserves, and implementing proactive asset management strategies. Additionally, staying informed about market trends and regulatory changes helps investors adapt to evolving conditions and maximize returns."
    }
  }




]



export const PROPERTY_INFO = [
  {
    'Asking Price Per Unit': 'The price per individual unit (e.g., apartment, office space) that the seller is requesting.',
    'Purchase Price': 'The total price paid by the buyer to acquire the property.',
    'Price Per Square Foot': 'The cost of a property divided by its total square footage, often used to compare the relative value of different properties.',
    'Rentable Square Foot': 'The total square footage of space that can be rented out to tenants within a property.',
    'Hold Time (Years)': 'The length of time an investor plans to hold onto a property before selling it.',
    'Property Class': 'A classification system used to categorize real estate properties based on various factors such as age, location, amenities, and overall quality.',
    'Market Cap Rate': 'The rate of return on a real estate investment based on the income the property is expected to generate relative to its market value.',
  }
]
export const RENT_ROLL_INFO = [
  {
    'Current Occupied Income': 'The total income generated from units that are currently occupied by tenants.',
    'Current Vacant Income': 'The income generated from units that are currently vacant and available for rent.',
    'Number of Vacant Units': 'The total count of units within a property that are currently unoccupied.',
    'Occupancy %': 'The percentage of units within a property that are currently occupied by tenants.',
  }
]
export const RENT_UNIT_MIX_INFO = [
  {
    'Unit Types': 'Different categories or types of units within a property, such as studio apartments, one-bedroom apartments, two-bedroom apartments, etc.',
    'Units': 'The total number of individual living or working spaces within a property.',
    'Unit SF': 'The square footage of each individual unit within a property.',
    "Seller's Monthly Rent": 'The amount of rent currently being paid by tenants to the seller of the property.',
    'Market Monthly Rent': 'The average or typical rent amount for similar properties in the market.',
  }
]

export const INCOME_EXPENSE_INFO = [
  {
    'Net Operating Income (NOI)': 'The total income generated by a property after subtracting operating expenses such as maintenance, utilities, and property management fees.',
    'Gross Potential Rent (GPR)': 'The total potential income a property could generate if all units were rented out at market rates, without considering vacancies or other factors.',
    'Loss To Lease': 'The difference between the current rent being collected and the market rent for the property.',
    'Physical Vacancy': 'The portion of a property\'s units that are currently unoccupied due to factors such as maintenance or renovation.',
    'Concessions': 'Discounts or incentives offered to tenants to encourage leasing, such as free rent or reduced security deposits.',
    'Loss / Bad Debt': 'The amount of rental income lost due to unpaid rent or tenants defaulting on their lease agreements.',
    'Non-Revenue Units (models, office, etc)': 'Units within a property that are not generating rental income, such as model units used for showings or office space for property management.',
    'Utility Reimbursement': 'Any reimbursement received from tenants for utilities used within the property.',
    'Other Income': 'Additional sources of income generated by the property, such as parking fees or laundry facilities.',
    'Payroll': 'The total amount paid to employees or contractors for property management and maintenance services.',
    'Repairs And Maintenance': 'The costs associated with repairing and maintaining the property, including routine maintenance and unexpected repairs.',
    'Insurance': 'The cost of property insurance to protect against potential risks such as fire, theft, or liability claims.',
    'Utility': 'The cost of utilities such as electricity, water, and gas used within the property.',
    'Contract Services': 'Payments made to third-party contractors for services such as landscaping, janitorial services, or security.',
    'General & Admin / Legal': 'Administrative and legal expenses related to the operation and management of the property.',
    'Marketing': 'Expenses associated with marketing the property to attract tenants, such as advertising costs or broker commissions.',
    'Misc': 'Other miscellaneous expenses not covered by the above categories, such as property taxes or licensing fees.',
    'Taxes': 'The property taxes assessed by local government authorities based on the value of the property.',
    'Management Fee': 'The fee paid to a property management company for overseeing the day-to-day operations of the property on behalf of the owner.'
  }

];

export const VALUE_ADD_INFO = [
  {
    'Other Income': 'Reserved parking, Pet rent,community fees etc..',
    'Capital Improvements': `Capital improvements enhance property value
      and are treated separately from regular expenses. They are assets on the balance sheet,
      not expenses on the income statement, reflecting their long-term value.  New roof, paint, HVAC, plumbing, common areas, security systems, accessibility improvements`,
    'RUBS': `Ratio Utility Billing System is a method to divide up the cost of things like water, electricity, or gas among the people who live in an apartment building based on the size of their apartment or how many people live there currently`,

  }
]
export const EXPENSES_INFO = [{
  'Physical Vacancy %': 'how many units are currently empty and not being used',
  'Concessions %': 'refers to the percentage of discounts offered to tenants, like rent discounts or waived fees',
  'Loss / Bad Debt %': 'the percentage of unpaid rent compared to the total rent revenue',
  'In Place Utility Reimbursement': `The system already in use for tenants to pay for their utilities, like RUBS`,
  'Other Income': 'refers to revenue generated from sources other than rent',
  'Tax Rate': 'the percentage of property tax charged on the assessed value of a property',
  'Millage Rate': 'the amount of tax payable per dollar of the assessed value of a property',
  'Input Rate (Tax rate)': `the percentage of property tax charged on the assessed value of a property`
}]

export const LOAN_FINANCING_INFO = [
  {
    'Are you assuming the loan?': 'refers to taking over an existing loan from the current borrower',
    'How much money are you putting down?': 'a percentage of the total purchase price, with the remainder financed through a mortgage loan',
    'What is the interest rate?': 'the percentage of the loan amount charged by a lender as interest, which is the cost of borrowing money',
    'What is the Interest only period (months)?': 'a specific time frame during a loan term where the borrower is only required to pay the interest on the loan, not principle and interest',
    'What is the amortization term for interest only period?': "this is usually this number you can edit it as per your convenience",
    'What is the amortization term after interest only period (years)?': "this is usually this number you can edit it as per your convenience",
    'What is the term?': "this is usually this number you can edit it as per your convenience",

  }
]
export const SOURCES_AND_OTHER_INFO = [
  {
    "Closing Cost %": 'the fees associated with purchasing a property,, including costs such as appraisal fees, title insurance, and attorney fees',
    "Loan Points %": 'upfront fees paid to a lender at closing in exchange for flexible terms on the loan',
    "Acquisition Fee %": `a one-time fee paid by investors to compensate a sponsor for arranging an investment`,
    "Guarantor Fee %": "a fee charged by a third-party guarantor, such as a business partner who agrees to guarantee the loan repayment",
    "Capital Reserves Per Unit": "the amount of money set aside by the property owner or management company for future capital expenditures",
    "Cap Rate @ Resale": "a measure used to evaluate the potential return on an investment property",
    "Managers Split": "A managers percentage of the net profit"
  }
]






