import React, { useState, useEffect, useMemo } from 'react'
import { Table, InputNumber } from 'antd'
import './style.less'
import NumberInput from '../NumberInput'

const RUBSTable = ({ implementedRUBSAmount = 0, onDataUpdate, RUBSData }) => {
  const [utilitiesYear1, setUtilitiesYear1] = useState(0)
  const [inputPercent, setInputPercent] = useState(0)
  const [rates, setRates] = useState(new Array(6).fill(0))

  useEffect(() => {
    if (RUBSData) {
      setUtilitiesYear1(RUBSData.utilities_year_1 || 0)
      setInputPercent(RUBSData.implemented_rubs_rate || 0)
      const initialRates = [
        RUBSData.implemented_rubs_rate || 0,
        ...(RUBSData.bill_back_year_data?.map((data) => data.bill_back_year_rate) || new Array(5).fill(0))
      ]
      setRates(initialRates)
    } else {
      setUtilitiesYear1(0)
      setInputPercent(0)
      setRates(new Array(6).fill(0))
    }
  }, [])

  const implementedRubsRate = useMemo(() => {
    if (!isNaN(implementedRUBSAmount) && !isNaN(utilitiesYear1) && utilitiesYear1 !== 0) {
      return ((implementedRUBSAmount / utilitiesYear1) * 100).toFixed(0)
    } else {
      return 0
    }
  }, [implementedRUBSAmount, utilitiesYear1])

  useEffect(() => {
    setRates((prevRates) => {
      const newRates = [...prevRates]
      newRates[0] = inputPercent
      return newRates
    })
  }, [inputPercent])

  const data = useMemo(() => {
    return [
      {
        key: 'implemented_rubs_rate',
        label: 'Implemented RUBS Rate',
        bill_back_year_rate: parseFloat(implementedRubsRate),
        bill_back_year_amount: implementedRUBSAmount ? implementedRUBSAmount : 0 
      },
      ...rates.slice(1).map((rate, index) => {
        const calculatedRate = parseInt(rate)
        return {
          key: `year_${index + 1}`,
          label: `Bill Back Year ${index + 1}`,
          bill_back_year_rate: parseInt(calculatedRate),
          bill_back_year_amount: parseFloat(utilitiesYear1) * calculatedRate / 100 * 1.02 // Multiply by 1.02
        }
      })
    ]
  }, [rates, utilitiesYear1, implementedRubsRate, implementedRUBSAmount])

  const totalPercent = useMemo(() => {
    return data.reduce((total, item) => {
      const rate = parseFloat(item.bill_back_year_rate)
      return total + (isNaN(rate) ? 0 : rate)
    }, 0)
  }, [data])

  const totalAmount = useMemo(() => {
    return totalPercent > 70
      ? 0
      : data.reduce((total, item) => total + item.bill_back_year_amount, 0) // Do not multiply total by 1.02
  }, [data, totalPercent])

  const totalRow = {
    key: 'total',
    label: 'Total:',
    bill_back_year_rate: totalPercent > 70 ? 'It’s over 70' : totalPercent.toFixed(0),
    bill_back_year_amount: totalAmount
  }

  useEffect(() => {
    const modifiedData = data.slice(1).map(item => ({
      ...item,
      bill_back_year_amount: totalPercent > 70 ? 0 : item.bill_back_year_amount 
    }))

    onDataUpdate({
      utilities_year_1: utilitiesYear1,
      implemented_rubs_rate: parseInt(implementedRubsRate),
      bill_back_year_data: modifiedData
    })
  }, [data, utilitiesYear1, implementedRubsRate, totalPercent])

  const handleRateChange = (index, value) => {
    setRates((prevRates) => {
      const newRates = [...prevRates]
      newRates[index] = parseFloat(value) || 0
      return newRates
    })
  }

  const columns = useMemo(
    () => [
      {
        title: 'Description',
        dataIndex: 'label',
        key: 'label'
      },
      {
        title: 'Percentage',
        dataIndex: 'bill_back_year_rate',
        key: 'bill_back_year_rate',
        render: (_, record, index) =>
          record.key === 'implemented_rubs_rate' || record.key === 'total' ? (
            <span>{`${record.bill_back_year_rate}%`}</span>
          ) : (
            <InputNumber
              value={parseInt(record.bill_back_year_rate)}
              addonAfter="%"
              onChange={(value) => handleRateChange(index, parseInt(value))}
              step={1}
            />
          )
      },
      {
        title: 'Amount',
        dataIndex: 'bill_back_year_amount',
        key: 'bill_back_year_amount',
        render: (_, record) => (
          <span>{record.bill_back_year_amount === 'N/A' || totalPercent>70 ? `$${0}` : `$${record.bill_back_year_amount.toFixed(2)}`}</span>
        )
      }
    ],
    [rates, totalPercent]
  )

  return (
    <div>
      <div style={{ marginBottom: '16px' }}>
        <NumberInput
          label={'Utilities Year 1:'}
          id="utilitiesYear1"
          value={utilitiesYear1}
          onChange={(e) => setUtilitiesYear1(parseFloat(e) || 0)}
          style={{ width: '200px', marginLeft: '10px' }}
          pricing={true}
        />
      </div>
      <Table
        columns={columns}
        dataSource={[...data, totalRow]}
        pagination={false}
        bordered={true}
        rowClassName={(record) => (record.key === 'total' && totalPercent > 70 ? 'over70-row' : '')}
      />
    </div>
  )
}

export default RUBSTable
