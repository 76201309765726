import React, { useEffect, useState } from 'react'
import { Row, Col, Divider, Form, Button, Space, Input, InputNumber } from 'antd'
import { MinusOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons'
import InlineRow from '@/component/InlineRow'
import NumberInput from '@/component/NumberInput'
import TextInput from '@/component/TextInput'
import ShadowTextInput from '@/component/ShadowTextInput'
import ShadowNumberInput from '@/component/ShadowNumberInput'
import ValueAdds from '@/component/ValueAdds'
import { COMMON_RULE } from '@/constants'

const Step5_ValueAdd = (props) => {
  const [form] = Form.useForm()
  const { dealInfo, handleUpdate } = props

  const handleSubmit = async (values) => {
    await handleUpdate({ value_add: values })
  }

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={handleSubmit}
      requiredMark={false}
      preserve={false}
      colon={false}
      initialValues={dealInfo?.details?.value_add}
      className="value-add-additional-form"
    >
      <h1 className="mb-4">Value Add / Cap Ex</h1>
      <InlineRow>
        <NumberInput label="Rental Increase per Unit" name="rental_increase_per_unit" pricing={true} />
        <NumberInput
          label="Rental Increase per Renovated Unit"
          name="rental_increase_per_renovated_unit"
          pricing={true}
        />
        <NumberInput label="Units that Need Upgrades" name="units_that_need_upgrades" />
      </InlineRow>
      <InlineRow>
        <NumberInput label="Length Of Renovations" name="length_of_renovations" />
        <NumberInput label="Additional Market Rent per Year" name="additional_market_rent_per_year" pricing={true} />
      </InlineRow>
      <InlineRow>
        <NumberInput label="Additional Renovation per Year" name="additional_renovation_per_year" pricing={true} />
        <NumberInput label="Total Increase Per Year" name="total_increase_per_year" pricing={true} />
      </InlineRow>
      <InlineRow>
        <NumberInput label="Other Income" name="other_income" pricing={true} />
        <NumberInput label="Capital Improvements" name="capital_improvements" pricing={true} />
      </InlineRow>
      <h4 className="mt-1">Additionals</h4>
      <Row gutter={[8, 8]} className="row-header">
        <Col span={24} md={4}>
          <p>Description</p>
        </Col>
        <Col span={24} md={3}>
          <p>Quantity</p>
        </Col>
        <Col span={24} md={4}>
          <p>Unit Cost</p>
        </Col>
        <Col span={24} md={1}></Col>
      </Row>
      <Form.List name="additionals">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Row gutter={[8, 8]} key={key}>
                <Col span={24} md={4}>
                  <Form.Item {...restField} name={[name, 'description']} rules={COMMON_RULE}>
                    <ShadowTextInput placeholder="Washer & Dryer" />
                  </Form.Item>
                </Col>
                <Col span={24} md={3}>
                  <Form.Item {...restField} name={[name, 'quantity']} rules={COMMON_RULE}>
                    <ShadowNumberInput placeholder="50" />
                    {/* <InputNumber formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}/> */}
                  </Form.Item>
                </Col>
                <Col span={24} md={4}>
                  <Form.Item {...restField} name={[name, 'unit_cost']} rules={COMMON_RULE}>
                    <ShadowNumberInput pricing={true} placeholder="1,350" />
                    {/* <InputNumber prefix="$" formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} /> */}
                  </Form.Item>
                </Col>
                <Col span={24} md={1}>
                  <Form.Item>
                    <MinusOutlined onClick={() => remove(name)} />
                  </Form.Item>
                </Col>
              </Row>
            ))}
            <Form.Item>
              <Button className="mt-1" type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                Add new
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
      <Button type="primary" className="next-btn btn" htmlType="submit">
        Next
      </Button>
    </Form>
  )
}

export default Step5_ValueAdd
