import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { message, notification } from 'antd'
import CommonActions from '@/redux/common'

const TopAlert = () => {
  const dispatch = useDispatch()
  const { alert } = useSelector((state) => state.common)

  useEffect(() => {
    if (alert) {
      switch (alert.type) {
        case 'error':
          notification.error({
            message: 'Error',
            description: alert.message
          })
          break
        case 'success':
          notification.success({
            message: 'Success',
            description: alert.message
          })
          break
        default:
          notification.info({
            message: 'Info',
            description: alert.message
          })
      }
      setTimeout(dispatch(CommonActions.clearAlert()), 3000)
    }
  }, [alert])

  return null
}

export default TopAlert
