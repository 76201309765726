import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { StepConnector, Stepper, StepLabel, Step } from '@mui/material'
import { styled } from '@mui/material/styles'

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#784af4'
  }),
  '& .QontoStepIcon-completedIcon': {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor'
  }
}))

function QontoStepIcon(props) {
  const { active, completed, ...other } = props

  return (
    <QontoStepIconRoot ownerState={{ active, completed }} {...other}>
      {completed ? <Check className="QontoStepIcon-completedIcon" /> : <div className="QontoStepIcon-circle" />}
    </QontoStepIconRoot>
  )
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool
}

const ColorLibConnector = styled(StepConnector)(({ theme,step }) => {

  
    return {
      top: 27,
      '& .MuiStepConnector-line': {
        height: 4,
        width: '100%',
        border: 0,
        backgroundColor: step > 1 ? '#ccc' : '#1890FF',
        borderRadius: 1
      }
    };
  });

const ColorLibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 60,
  height: 60,
  display: 'flex',
  fontWeight: 'bold',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundColor:'#1890FF',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)'

  }),
  ...(ownerState.completed && {
    backgroundColor:'#1890FF'
  })
}))

function ColorLibStepIcon(props) {
  const { active, completed, icon } = props

  const icons = {
    1: 'Step 1',
    2: 'Step 2',
    3: 'Step 3',
    4: 'Step 4',
    5: 'Step 5',
    6: 'Step 6',
    7: 'Step 7',
    8: 'Step 8',
    9: 'Step 9'
  }

  return <ColorLibStepIconRoot ownerState={{ active, completed }}>{icons[icon]}</ColorLibStepIconRoot>
}

const styles = {
  root: {
    width: '100%'
  },
  button: (theme) => ({
    marginRight: theme.spacing(2)
  }),
  instructions: (theme) => ({
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  })
}

function getSteps() {
  return [
    'Property Details',
    'Rent Roll',
    'Rent Unit Mix',
    'Income & Expenses T-12',
    'Investment Strategy',
    'Value Add',
    'Expenses',
    'Loan Financing',
    'Sources & Other'
  ]
}

export default function CustomizedSteppers() {
  const [activeStep, setActiveStep] = React.useState(3)
  const steps = getSteps()

  return (
    <div sx={styles.root}>
      <Stepper alternativeLabel activeStep={activeStep} connector={<ColorLibConnector step={1}/>}>
        {steps.slice(0, 4).map((label, index) => (
          <Step key={index}>
            <StepLabel StepIconComponent={ColorLibStepIcon} StepIconProps={{ icon: index + 1 }}>
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <br /> {/* New line after step 5 */}
      <Stepper alternativeLabel activeStep={activeStep >= 5 ? activeStep - 5 : -1} connector={<ColorLibConnector step={10} />}>
        {steps.slice(4).map((label, index) => (
          <Step key={index + 5}>
            <StepLabel StepIconComponent={ColorLibStepIcon} StepIconProps={{ icon: index + 4 + 1 }}>
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  )
}
