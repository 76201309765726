import '@/theme.less'
import '@/base.less'
import * as React from 'react'
import ReactDOM from 'react-dom/client'
import { configureStore } from '@/redux'
import { Provider } from 'react-redux'
import App from './App'

const store = configureStore()
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Provider store={store}>
    <App />
  </Provider>
)
 if (module.hot) {
  module.hot.accept();
 }