import React, { useEffect, useState } from 'react'
import { Table, Input, Button, Space } from 'antd'
import NumberInput from '@/component/NumberInput'
import TextInput from '../TextInput'
import TextLabel from '../TextLabel'

const ValueAddTable = ({ valueAddData, setValueAddData }) => {
  const [data, setData] = useState([
    {
      key: 1,
      year: 1,
      rows: [{ rowKey: 1, description: '', quantity: 0, cost_to_buyer: 0, monthly_cost_to_resident: 0 }]
    },
    {
      key: 2,
      year: 2,
      rows: [{ rowKey: 1, description: '', quantity: 0, cost_to_buyer: 0, monthly_cost_to_resident: 0 }]
    },
    {
      key: 3,
      year: 3,
      rows: [{ rowKey: 1, description: '', quantity: 0, cost_to_buyer: 0, monthly_cost_to_resident: 0 }]
    },
    {
      key: 4,
      year: 4,
      rows: [{ rowKey: 1, description: '', quantity: 0, cost_to_buyer: 0, monthly_cost_to_resident: 0 }]
    },
    {
      key: 5,
      year: 5,
      rows: [{ rowKey: 1, description: '', quantity: 0, cost_to_buyer: 0, monthly_cost_to_resident: 0 }]
    }
  ])

  const transformToCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(value)
  }

  const handleAddRow = (year) => {
    const yearData = data.find((item) => item.year === year)
    const newRowKey = yearData ? yearData.rows.length + 1 : 1

    const newData = data.map((item) =>
      item.year === year ? { ...item, rows: [...item.rows, { rowKey: newRowKey, description: '', quantity: 0, cost_to_buyer: 0, monthly_cost_to_resident: 0 }] } : item
    )

    setData(newData)
    setValueAddData(newData) // Update parent component's state
  }

  const handleRemoveRow = (year, rowKey) => {
    const newData = data.map((item) =>
      item.year === year ? { ...item, rows: item.rows.filter((row) => row.rowKey !== rowKey) } : item
    )
    setData(newData)
    setValueAddData(newData) // Update parent component's state
  }

  const calculateYearlyTotal = (year) => {
    const yearData = data.find((item) => item.year === year)
    if (!yearData) return 0

    let total = 0
    yearData.rows.forEach((row) => {
      const quantity = parseFloat(row.quantity) || 0
      const monthly_cost_to_resident = parseFloat(row.monthly_cost_to_resident) || 0
      total += quantity * monthly_cost_to_resident * 12
    })

    return total
  }

  const columns = [
    {
      title: '',
      dataIndex: 'year',
      width: '10%',
      render: (_, record) => <TextLabel>{`Year ${record.year}`}</TextLabel>
    },
    {
      title: 'Description',
      dataIndex: 'description',
      width: '25%',
      render: (_, record) => (
        <>
          {record.rows.map((row, index) => (
            <div
              key={`description_${record.year}_${row.rowKey}`}
              style={{ display: 'flex', alignItems: 'center', gap: '5px' }}
            >
              <TextInput
                name={`description_${record.year}_${row.rowKey}`}
                required={record.key == 1}
                onChange={(e) => handleInputChange(record.year, row.rowKey, 'description', e.target.value)}
              />
              <div className="row flex flex-col">
                <div className="ant-col ant-form-item-label">
                  <label className="ant-form-item-required ant-form-item-no-colon"></label>
                </div>
                {index === 0 && (
                  <Button style={{ padding: ' 0px 30px' }} onClick={() => handleAddRow(record.year)}>
                    Add
                  </Button>
                )}
                {index !== 0 && (
                  <Button
                    type="danger"
                    ghost
                    onClick={() => handleRemoveRow(record.year, row.rowKey)}
                    style={{ marginLeft: '2px' }}
                  >
                    Remove
                  </Button>
                )}
              </div>
            </div>
          ))}
        </>
      )
    },
    {
      title: '# Quantity',
      dataIndex: 'quantity',
      width: '15%',
      render: (_, record) => (
        <>
          {record.rows.map((row) => (
            <NumberInput
              key={`quantity_${record.year}_${row.rowKey}`}
              name={`quantity_${record.year}_${row.rowKey}`}
              required={record.key == 1}
              onChange={(value) => handleInputChange(record.year, row.rowKey, 'quantity', value)}
              style={{ width: 120 }}
            />
          ))}
        </>
      )
    },
    {
      title: 'Cost to buyer',
      dataIndex: 'cost_to_buyer',
      width: '20%',
      render: (_, record) => (
        <>
          {record.rows.map((row) => (
            <NumberInput
              required={false}
              key={`cost_to_buyer_${record.year}_${row.rowKey}`}
              name={`cost_to_buyer_${record.year}_${row.rowKey}`}
              pricing={true}
              onChange={(value) => handleInputChange(record.year, row.rowKey, 'cost_to_buyer', value)}
              style={{ width: 120 }}
            />
          ))}
        </>
      )
    },
    {
      title: 'Cost to resident (monthly)',
      dataIndex: 'monthly_cost_to_resident',
      width: '20%',
      render: (_, record) => (
        <>
          {record.rows.map((row) => (
            <NumberInput
              required={record.key == 1}
              key={`monthly_cost_to_resident${record.year}_${row.rowKey}`}
              name={`monthly_cost_to_resident${record.year}_${row.rowKey}`}
              pricing={true}
              onChange={(value) => {
                handleInputChange(record.year, row.rowKey, 'monthly_cost_to_resident', value)
              }}
              style={{ width: 150 }}
            />
          ))}
        </>
      )
    },
    {
      title: 'Yearly Total',
      dataIndex: 'yearly_total',
      width: '10%',
      render: (_, record) => {
        const total = calculateYearlyTotal(record.year)
        return <TextLabel>{transformToCurrency(total)}</TextLabel>
      }
    }
  ]

  const handleInputChange = (year, rowKey, field, value) => {
    const newData = data.map((item) => {
      if (item.year === year) {
        const newRows = item.rows.map((row) => (row.rowKey === rowKey ? { ...row, [field]: value } : row))
        return { ...item, rows: newRows }
      }
      return item
    })
    setData(newData)
    setValueAddData(newData) // Update parent component's state
  }

  return (
    <div style={{ overflowX: 'auto' }}>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        scroll={{ x: 'max-content' }} // Enable horizontal scrolling
      />
    </div>
  )
}

export default ValueAddTable
