import React from 'react'

const Img = ({ src, ...params }) => {
  let prefix = ''

  if (!src) return null
  if (!src.startsWith('http') && !src.startsWith('/images')) {
    prefix = process.env.MEDIA_URL
  }

  return <img src={`${prefix}${src}`} {...params} />
}

export default Img
