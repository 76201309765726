import React, { useEffect, useState } from 'react'
import { Row, Col, Divider, Button, Space, Input, Statistic, Card, InputNumber, Select, Checkbox } from 'antd'
import InlineRow from '@/component/InlineRow'
import { PROPERTY_CLASS_OPTIONS } from '@/constants'

const NumberInput = ({
  className,
  label,
  value,
  field,
  handleChange,
  handleSave,
  prefix = null,
  suffix = null,
  disabled = false,
  isDSCR = false
}) => {
  const formatter = (value) => {
    let formattedValue = 0
    if (value) {
      formattedValue = value
    }
    if (suffix || isDSCR) {
      formattedValue = parseFloat(formattedValue).toFixed(2)
    } else {
      formattedValue = parseFloat(formattedValue).toFixed(0)
    }

    return `${formattedValue}${suffix ? '%' : ''}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  return (
    <div className={`info-field ${className}`}>
      {label && <p>{label}</p>}
      <InputNumber
        value={value}
        onChange={(val) => handleChange(field, val)}
        prefix={prefix}
        formatter={formatter}
        onBlur={() => handleSave()}
        disabled={disabled}
      />
    </div>
  )
}

const FinancingTab = (props) => {
  const [financingEditMode, setFinancingEditMode] = useState(false)
  const [loanAmountEditMode, setLoanAmountEditMode] = useState(false)
  const [closingAcquisitionCostEditMode, setClosingAcquisitionCostEditMode] = useState(false)
  const [profitFromSalesEditMode, setProfitFromSalesEditMode] = useState(false)

  const { userInfo, dealInfo, dealInput, updateDealInput, getDealInput, handleSave } = props
  if (!dealInfo.details.financials) return null
  const { financing } = dealInfo.details


  const params = {
    handleChange: updateDealInput,
    handleSave: handleSave
  }

  return (
    <div className="tab-content financing-tab">
      <Row gutter={[16, 16]}>
        <Col span={24} md={12}>
          <Card title="Financing">
            <Checkbox checked={financingEditMode} onChange={() => setFinancingEditMode(!financingEditMode)}>
              Edit Mode
            </Checkbox>
            <InlineRow className="mb-1">
              <Statistic
                title="Purchase Price:"
                value={dealInfo?.financials?.financing?.purchase_price}
                prefix="$"
                precision={0}
              />
              <Statistic
                title="Down Payment %:"
                value={
                  financing?.down_payment_percentage === 0
                    ? (dealInput.financing.down_payment / dealInfo?.details.property?.purchase_price) * 100
                    : financing?.down_payment_percentage
                }
                suffix="%"
                precision={2}
              />
            </InlineRow>
            <InlineRow className="mb-1">
              <NumberInput
                {...params}
                label="Down Payment:"
                value={dealInput?.financing?.down_payment}
                field="financing.down_payment"
                prefix="$"
                disabled={!financingEditMode}
              />
              <Statistic title="Mortgate amount:" value={financing?.mortgage_amount} prefix="$" precision={0} />
            </InlineRow>
            <InlineRow className="mb-1">
              <NumberInput
                {...params}
                label="Interest Rate:"
                value={dealInput?.financing?.interest_rate}
                field="financing.interest_rate"
                suffix="%"
                disabled={!financingEditMode}
              />
              <NumberInput
                {...params}
                label="Interest Only Period(month):"
                value={dealInput?.financing?.interest_only_period}
                field="financing.interest_only_period"
                disabled={!financingEditMode}
              />
            </InlineRow>
            <InlineRow className="mb-1">
              <NumberInput
                {...params}
                label="Amort. Term for Interest Only Period:"
                value={dealInput?.financing?.amort_term_for_interest_only_period}
                field="financing.amort_term_for_interest_only_period"
                disabled={!financingEditMode}
              />
              <NumberInput
                {...params}
                label="Amort. after Interest Only Period(years):"
                value={dealInput?.financing?.amort_after_interest_only_period}
                field="financing.amort_after_interest_only_period"
                disabled={!financingEditMode}
              />
            </InlineRow>
            <InlineRow className="mb-1">
              <NumberInput
                {...params}
                label="Term:"
                value={dealInput?.financing?.term}
                field="financing.term"
                disabled={!financingEditMode}
              />
            </InlineRow>
            <InlineRow className="mb-1">
              <Statistic
                title="Annual Debt Service I.O. Period:"
                value={dealInfo?.financials?.financing?.annual_debt_service_interests}
                prefix="$"
                precision={0}
              />
              <Statistic
                title="Annual Debt Service post I.O.:"
                value={dealInfo?.financials?.financing?.annual_debt_service_post_interests}
                prefix="$"
                precision={0}
              />
            </InlineRow>
          </Card>
        </Col>
        <Col span={24} md={12}>
          <Card title="Loan Amount">
            <Checkbox checked={loanAmountEditMode} onChange={() => setLoanAmountEditMode(!loanAmountEditMode)}>
              Edit Mode
            </Checkbox>
            <InlineRow className="mb-1">
              <Statistic
                title="Purchase Price:"
                value={dealInfo?.financials?.financing?.purchase_price}
                prefix="$"
                precision={0}
              />
              <Statistic title="NOI:" value={dealInfo?.financials?.financing?.noi} prefix="$" precision={0} />
            </InlineRow>
            <InlineRow className="mb-1">
              <Statistic title="Interest Rate:" value={financing?.interest_rate} suffix="%" precision={2} />
              {/* <NumberInput
                {...params}
                label="DSCR:"
                isDSCR
                value={dealInput?.financing?.dscr}
                field="financing.dscr"
                disabled={!loanAmountEditMode}
              /> */}
              <Statistic title="DSCR" value={dealInfo?.financials?.financing?.dscr} precision={2} />
            </InlineRow>
            <InlineRow className="mb-1">
              <Statistic
                title="Annual Payment:"
                value={dealInfo?.financials?.financing?.annual_payment}
                prefix="$"
                precision={0}
              />
              <Statistic
                title="Loan Amount:"
                value={dealInfo?.financials?.financing?.loan_amount}
                prefix="$"
                precision={0}
              />
            </InlineRow>
            <Divider />
            <h3>Loan DSCR</h3>
            <InlineRow className="mb-1">
              <Statistic
                title="Loan Amount at DSCR:"
                value={dealInfo?.financials?.financing?.loan_amount_at_dscr}
                prefix="$"
                precision={0}
              />
              <Statistic
                title="Loan Amount %:"
                value={dealInfo?.financials?.financing?.loan_amount_at_dscr_rate}
                suffix="%"
                precision={2}
              />
            </InlineRow>
            <Divider />
            <h3>Loan LTV</h3>
            <InlineRow>
              <Statistic
                title="Loan Amount at LTV:"
                value={dealInfo?.financials?.financing?.loan_amount_at_ltv}
                prefix="$"
                precision={0}
              />
              <NumberInput
                {...params}
                label="LTV %:"
                value={dealInfo?.financials?.financing?.loan_amount_at_ltv_rate}
                field="financing.loan_amount_at_ltv_rate"
                disabled={!loanAmountEditMode}
              />
            </InlineRow>
          </Card>
        </Col>
        <Col span={24} md={12}>
          <Card title="Closing & Acquision Cost">
            <Checkbox
              checked={closingAcquisitionCostEditMode}
              onChange={() => setClosingAcquisitionCostEditMode(!closingAcquisitionCostEditMode)}
            >
              Edit Mode
            </Checkbox>
            <InlineRow className="mb-1">
              <Space size={4} direction="vertical" className="info-field">
                <p className="mb-0">Closing Cost:</p>
                <Space align="center">
                  <NumberInput
                    {...params}
                    value={dealInput?.other?.closing_cost_rate}
                    field="other.closing_cost_rate"
                    suffix="%"
                    disabled={!closingAcquisitionCostEditMode}
                  />
                  <Statistic value={financing?.closing_cost} prefix="$" precision={0} />
                </Space>
              </Space>
              <Space size={4} direction="vertical" className="info-field">
                <p className="mb-0">Loan Cost:</p>
                <Space align="center">
                  <NumberInput
                    {...params}
                    value={dealInput?.other?.loan_points_rate}
                    field="other.loan_points_rate"
                    suffix="%"
                    disabled={!closingAcquisitionCostEditMode}
                  />
                  <Statistic value={financing?.loan_cost} prefix="$" precision={0} />
                </Space>
              </Space>
            </InlineRow>
            <InlineRow className="mb-1">
              <Space size={4} direction="vertical" className="info-field">
                <p className="mb-0">Acquisition Amount:</p>
                <Space align="center">
                  <NumberInput
                    {...params}
                    value={dealInput?.other?.acquisition_fee_rate}
                    field="other.acquisition_fee_rate"
                    suffix="%"
                    disabled={!closingAcquisitionCostEditMode}
                  />
                  <Statistic value={financing?.acquisition_amount} prefix="$" precision={0} />
                </Space>
              </Space>
              <Space size={4} direction="vertical" className="info-field">
                <p className="mb-0">Guarantor Amount:</p>
                <Space align="center">
                  <NumberInput
                    {...params}
                    value={dealInput?.other?.guarantor_fee_rate}
                    field="other.guarantor_fee_rate"
                    suffix="%"
                    disabled={!closingAcquisitionCostEditMode}
                  />
                  <Statistic value={financing?.guarantor_amount} prefix="$" precision={0} />
                </Space>
              </Space>
            </InlineRow>
            <Statistic
              title="Total Closing Costs:"
              value={dealInfo?.financials?.financing?.total_closing_costs}
              prefix="$"
              precision={0}
            />
            <Statistic
              title="Total Acquisition Costs:"
              value={dealInfo?.financials?.summary?.uses_of_funds?.total_closing_acquisition_costs}
              prefix="$"
              precision={0}
            />
          </Card>
        </Col>
        <Col span={24} md={12}>
          <Card title="Profit from Sales">
            <Checkbox
              checked={profitFromSalesEditMode}
              onChange={() => setProfitFromSalesEditMode(!profitFromSalesEditMode)}
            >
              Edit Mode
            </Checkbox>
            <h3>Expenses</h3>
            <InlineRow className="mb-1">
              <Statistic
                title="Exit Price:"
                value={dealInfo?.financials?.financing?.exit_price}
                prefix="$"
                precision={0}
              />
              <Space size={4} direction="vertical" className="info-field">
                <p className="mb-0">Loan Balance Repayment:</p>
                <Space align="center">
                  {/*<NumberInput
                    {...params}
                    value={dealInput?.financing?.loan_balance_repayment}
                    field="financing.loan_balance_repayment"
                  />*/}
                  <Statistic value={dealInfo?.financials?.financing?.loan_balance_repayment} prefix="$" precision={0} />
                </Space>
              </Space>
            </InlineRow>
            <InlineRow className="mb-1">
              <Space size={4} direction="vertical" className="info-field">
                <p className="mb-0">Other:</p>
                <Space align="center">
                  <NumberInput
                    {...params}
                    value={dealInput?.financing?.other}
                    field="financing.other"
                    suffix="%"
                    disabled={!profitFromSalesEditMode}
                  />
                  <Statistic value={dealInfo?.financials?.financing?.other} prefix="$" precision={0} />
                </Space>
              </Space>
              <Space size={4} direction="vertical" className="info-field">
                <p className="mb-0">Commission:</p>
                <Space align="center">
                  <NumberInput
                    {...params}
                    value={dealInput?.financing?.commission}
                    field="financing.commission"
                    suffix="%"
                    disabled={!profitFromSalesEditMode}
                  />
                  <Statistic value={dealInfo?.financials?.financing?.commission} prefix="$" precision={0} />
                </Space>
              </Space>
            </InlineRow>
            <InlineRow className="mb-1">
              <Space size={4} direction="vertical" className="info-field">
                <p className="mb-0">Loan Prepayment Penalty:</p>
                <Space align="center">
                  <NumberInput
                    {...params}
                    value={dealInput.financing?.loan_prepayment_penalty}
                    field="financing.loan_prepayment_penalty"
                    suffix="%"
                    disabled={!profitFromSalesEditMode}
                  />
                  <Statistic
                    value={dealInfo?.financials?.financing?.loan_prepayment_penalty}
                    prefix="$"
                    precision={0}
                  />
                </Space>
              </Space>
            </InlineRow>
            <Divider />
            <h3>Profits</h3>
            <InlineRow className="mb-1">
              <Statistic
                title="Gross Profits:"
                value={dealInfo?.financials?.financing?.gross_profits}
                prefix="$"
                precision={0}
              />
              <Statistic
                title="Equity Created:"
                value={dealInfo?.financials?.financing?.equity_created}
                prefix="$"
                precision={2}
              />
            </InlineRow>
            <Statistic
              title="Net Profit:"
              value={dealInfo?.financials?.financing?.net_profit}
              prefix="$"
              precision={0}
            />
            <InlineRow className="mb-1">
              <Space size={4} direction="vertical" className="info-field">
                <p className="mb-0">Managers Share of Equity:</p>
                <Space align="center" size={32}>
                  <Statistic value={dealInfo?.financials?.financing?.managers_share} prefix="$" precision={0} />
                  <Statistic value={dealInfo?.financials?.financing?.manager_split_rate} suffix="%" precision={2} />
                </Space>
              </Space>
              <Space size={4} direction="vertical" className="info-field">
                <p className="mb-0">Investor Share of Equity:</p>
                <Space align="center" size={32}>
                  <Statistic value={dealInfo?.financials?.financing?.investors_share} prefix="$" precision={0} />
                  <Statistic value={dealInfo?.financials?.financing?.investor_split_rate} suffix="%" precision={2} />
                </Space>
              </Space>
            </InlineRow>

            <Statistic
              title="Investor's Net Equity % (Cumulative)"
              value={dealInfo?.financials?.financing?.investors_net_equity_rate }
              suffix="%"
              precision={2}
            />
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default FinancingTab
