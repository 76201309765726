import { combineReducers } from 'redux'
import CommonReducer from './common/reducer'
import AuthReducer from './auth/reducer'
import ProfileReducer from './profile/reducer'
import DealReducer from './deal/reducer'
import DashboardReducer from './dashboard/reducer'

const reducer = combineReducers({
  common: CommonReducer,
  auth: AuthReducer,
  profile: ProfileReducer,
  deal: DealReducer,
  dashboard: DashboardReducer
})

export default reducer
