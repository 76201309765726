import React, { useEffect, useState } from 'react'
import { Table, Form, Button, Card, Modal, Spin, InputNumber } from 'antd'
import NumberInput from '@/component/NumberInput'
import { useNavigate } from 'react-router-dom'
import { SOURCES_AND_OTHER_INFO } from '@/constants'
import TooltipWithInfo from '@/component/TooltipWithInfo'

export default function Step10SourcesAndOther({ dealInfo, handleUpdate }) {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const [modalVisible, setModalVisible] = useState(false)
  const [firstTableValues, setFirstTableValues] = useState([
    { name: 'closing_cost_rate', label: 'Closing Cost %', value: 3 },
    { name: 'loan_points_rate', label: 'Loan Points %', value: 1 },
    { name: 'acquisition_fee_rate', label: 'Acquisition Fee %', value: 5 },
    { name: 'guarantor_fee_rate', label: 'Guarantor Fee %', value: 1 },
    { name: 'preferred_rate', label: 'Preferred  %', value: 7}
  ])
  const { property = {}, expenses = {} } = dealInfo?.details || {}
  
  const [secondTableValues, setSecondTableValues] = useState([
    { name: 'capital_reserves_per_unit', label: 'Capital Reserves Per Unit', value: 250 },
    {
      name: 'cap_rate_resale',
      label: 'Cap Rate @ Resale',
      value: property?.market_cap_rate ?? 0 + (property?.hold_time ?? 1 * 0.001),
      rating: true
    },
    { name: 'manager_split', label: 'Managers Split', value: 30, rating: true },
    { name: 'property_tax_per_month', label: 'Property Tax per Month', value: expenses[0]?.taxes != null ? expenses[0].taxes / 3 : 0 }
  ])

  const handleSubmit = async (values) => {
    setModalVisible(true)
    // Prepare data to send to the API
    const dataToSend = {
      ...firstTableValues.reduce((acc, item) => {
        acc[item.name] = item.value
        return acc
      }, {}),
      ...secondTableValues.reduce((acc, item) => {
        acc[item.name] = item.value
        return acc
      }, {}),
      status: 'completed'
    }

    await handleUpdate(dataToSend)
    setModalVisible(false)
  }

  const handleFirstTableChange = (index, newValue) => {
    const updatedValues = [...firstTableValues]
    updatedValues[index].value = newValue
    setFirstTableValues(updatedValues)
  }

  const handleSecondTableChange = (index, newValue) => {
    const updatedValues = [...secondTableValues]
    updatedValues[index].value = newValue
    setSecondTableValues(updatedValues)
  }

  const firstColumns = [
    {
      title: 'Label',
      dataIndex: 'label',
      render: (text) => (
        <div>
          {text} <TooltipWithInfo info={SOURCES_AND_OTHER_INFO[0][text]} />
        </div>
      )
    },
    {
      title: 'Value',
      dataIndex: 'value',
      render: (value, record, index) => (
        <Form.Item name={`first_${record.name}`} initialValue={value}>
          <InputNumber addonAfter="%" onChange={(newValue) => handleFirstTableChange(index, newValue)} />
        </Form.Item>
      )
    }
  ]

  const secondColumns = [
    {
      title: 'Label',
      dataIndex: 'label',
      render: (text) => (
        <div>
          {text} {text != 'Property Tax per Month' && <TooltipWithInfo info={SOURCES_AND_OTHER_INFO[0][text]} />}
        </div>
      )
    },
    {
      title: 'Value',
      dataIndex: 'value',
      render: (value, record, index) => (
        <Form.Item name={`second_${record.name}`} initialValue={value}>
          <InputNumber
            addonAfter={record.rating ? '%' : ''}
            onChange={(newValue) => handleSecondTableChange(index, newValue)}
          />
        </Form.Item>
      )
    }
  ]

  return (
    <Form layout="vertical" form={form} onFinish={handleSubmit} requiredMark={false} initialValues={''}>
      <h1>Sources & Other</h1>

      <div className="card-container">
        <Card className="card">
          <div className="card-caption">
            This section has been prefilled with industry standard percentages. Adjust accordingly
          </div>
          <Table columns={firstColumns} dataSource={firstTableValues} pagination={false} />
        </Card>

        <Card className="card">
          <Table columns={secondColumns} dataSource={secondTableValues} pagination={false} />
        </Card>
      </div>

      <Button type="primary" className="next-btn btn" htmlType="submit">
        Submit
      </Button>

      <Modal
        width={600}
        centered
        bodyStyle={{ padding: '50px', textAlign: 'center' }}
        open={modalVisible}
        closable={false}
        footer={null}
      >
        <div className="modal-container">
          <h2 style={{ marginBottom: '30px' }}>Processing Underwriting</h2>
          <Spin size="large" style={{ marginBottom: '30px' }} />
          <p style={{ fontSize: '16px' }}>Please wait while we process your underwriting...</p>
        </div>
      </Modal>
    </Form>
  )
}
