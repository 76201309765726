import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { Row, Col, Divider, Card, Statistic, Button, Table } from 'antd'
import { CreditCardOutlined, SearchOutlined } from '@ant-design/icons'
import InlineRow from '@/component/InlineRow'
import moment from 'moment'
// import ReactHighcharts from 'react-highcharts'

const MarketTrend = (props) => {
  const { marketTrendsInfo } = props

  const tableProps = {
    size: 'large',
    columns: [
      { dataIndex: 'bedrooms', title: 'Bedrooms', key: 'bedrooms' },
      {
        dataIndex: 'averageRent',
        title: 'Average Rent',
        key: 'averageRent',
        render: (text) => <Statistic value={text} precision={0} prefix="$" />
      },
      {
        dataIndex: 'maxRent',
        title: 'Max Rent',
        key: 'maxRent',
        render: (text) => <Statistic value={text} precision={0} prefix="$" />
      },
      {
        dataIndex: 'minRent',
        title: 'Min Rent',
        key: 'minRent',
        render: (text) => <Statistic value={text} precision={0} prefix="$" />
      },
      {
        dataIndex: 'totalListings',
        title: 'Total Listings',
        key: 'totalListings',
        render: (text) => <Statistic value={text} precision={0} />
      }
    ],
    dataSource: marketTrendsInfo?.rentalData?.dataByBedrooms,
    pagination: false
  }

  const config = {
    chart: {
      type: 'spline',
      zoomType: 'x',
      panning: true,
      panKey: 'shift',
      scrollablePlotArea: {
        minWidth: 600,
        scrollPositionX: 1
      }
    },
    title: {
      text: '',
      align: 'left'
    },
    subtitle: {
      text: '',
      align: 'left'
    },
    xAxis: {
      categories: marketTrendsInfo?.history?.datetime,
      labels: {
        format: '{value}'
      }
    },
    yAxis: {
      title: {
        text: ''
      },
      labels: {
        format: '${value}'
      },
      minorGridLineWidth: 1,
      gridLineWidth: 1
    },
    plotOptions: {
      spline: {
        lineWidth: 1,
        states: {
          hover: {
            lineWidth: 1.5
          }
        },
        marker: {
          enabled: false
        }
      }
    },
    tooltip: {
      headerFormat: '<b>{series.name}</b>: ${point.y:.2f}',
      pointFormat: ''
    },
    series: marketTrendsInfo?.history?.data
  }

  return (
    <div className="rent-cast-board">
      {marketTrendsInfo && (
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Card className="summary-card">
              <h4 className="blue-label">Average Monthly Rent</h4>
              <Statistic
                className="heading-1"
                value={marketTrendsInfo?.rentalData?.averageRent}
                precision={0}
                prefix="$"
              />
              <p className="text-center mb-3">last 12 months</p>
              <div className="d-flex d-space">
                <p className="mb-0">Low Rent</p>
                <p className="mb-0">High Rent</p>
              </div>
              <div className="progress-bar"></div>
              <div className="d-flex d-space">
                <Statistic
                  className="heading-2"
                  value={marketTrendsInfo?.rentalData?.minRent}
                  precision={0}
                  prefix="$"
                />
                <Statistic
                  className="heading-2"
                  value={marketTrendsInfo?.rentalData?.maxRent}
                  precision={0}
                  prefix="$"
                />
              </div>
            </Card>
          </Col>
          <Col span={16}>
            <Card title="Bedrooms">
              {marketTrendsInfo?.rentalData?.dataByBedrooms.length > 0 ? <Table {...tableProps} /> : <p>No data</p>}
            </Card>
          </Col>
          <Col span={24}>
            <Card title="Historical Performance">
              {/* {marketTrendsInfo?.history?.data.length > 0?
                <ReactHighcharts config={config} />
                :
                <p>No data</p>
              } */}
            </Card>
          </Col>
        </Row>
      )}
    </div>
  )
}

export default MarketTrend
