import React, { useEffect, useState } from 'react'
import { Row, Col, Divider, Button, Space, Input, Statistic, Card, Table } from 'antd'

const AmortizationTab = (props) => {
  const { userInfo, dealInfo } = props

  const tableProps = {
    size: 'large',
    columns: [
      { dataIndex: 'payment', title: 'Payment', key: 'payment' },
      {
        dataIndex: 'interest',
        title: 'Interest',
        key: 'interest',
        render: (text) => <Statistic value={text} prefix="$" precision={0} />
      },
      {
        dataIndex: 'principal',
        title: 'Principal',
        key: 'principal',
        render: (text) => <Statistic value={text} prefix="$" precision={2} />
      },
      {
        dataIndex: 'total',
        title: 'Total',
        key: 'total',
        render: (text) => <Statistic value={text} prefix="$" precision={0} />
      },
      {
        dataIndex: 'balance',
        title: 'Balance',
        key: 'balance',
        render: (text) => <Statistic value={text} prefix="$" precision={0} />
      }
    ],
    pagination: {
      defaultPageSize: 50
    }
  }

  return (
    <div className="tab-content">
      <Row gutter={16}>
        <Col span={24} md={12}>
          <Card title="Debt Service Interest Only period">
            <Table {...tableProps} rowKey={(record) => `${record.payment}-${record.interest}-${record.principal}`}  dataSource={dealInfo?.financials?.amortization?.debt_service_interests} />
          </Card>
        </Col>
        <Col span={24} md={12}>
          <Card title="Debt Service Post Interest Only">
            <Table {...tableProps} rowKey={(record) => `${record.payment}-${record.interest}-${record.principal}`}  dataSource={dealInfo?.financials?.amortization?.debt_service_post_interests} />
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default AmortizationTab
