import { AUTH, COMMON,SUBSCRIPTIONS } from '@/constants'
import { Api, TokenApi } from '@/utils/api'
import { getErrorMessage } from '@/utils'

// Get User with Token
export const getCurrentUser = () => {
  return async (dispatch) => {
    try {
      const res = await TokenApi.get('api/v1/users/me/')

      // if(!res.two_factor_enable) throw true

      dispatch({
        type: AUTH.SIGNED_IN
      })
      dispatch({
        type: AUTH.USER_INFO,
        payload: res
      })
    } catch (err) {
      dispatch(logOut())
    }
  }
}

export const updateUser = (id, values) => {
  return async (dispatch) => {
    try {
      const res = await TokenApi.patch(`api/v1/users/${id}/`, values)
      dispatch({
        type: AUTH.USER_INFO,
        payload: res
      })
    } catch (err) {
      dispatch({
        type: COMMON.TOP_ALERT,
        payload: { type: 'error', message: getErrorMessage(err) }
      })
    }
  }
}

// Login
export const login = (obj) => async (dispatch) => {
  try {
    const res = await Api.post('rest-auth/login/', obj)
    window.localStorage.setItem('accessToken', res.key)
    return res
  } catch (err) {
    dispatch({
      type: COMMON.TOP_ALERT,
      payload: { type: 'error', message: getErrorMessage(err) }
    })
    throw err
  }
}

// Signup
export const signup = (obj) => {
  return async (dispatch) => {
    try {
      const res = await Api.post('rest-auth/signup/', obj)
      if (res.key) {
        window.localStorage.setItem('accessToken', res.key)
        dispatch({
          type: COMMON.TOP_ALERT,
          payload: { type: 'success', message: 'You have created your account successfully.' }
        })
      }
      return res
    } catch (err) {
      dispatch({
        type: COMMON.TOP_ALERT,
        payload: { type: 'error', message: getErrorMessage(err) }
      })
      throw err
    }
  }
}

export const subscribe = (obj) => {
  return async (dispatch) => {
    try {
      const res = await TokenApi.post('api/v1/users/subscribe/', obj);
      dispatch({
        type: COMMON.TOP_ALERT,
        payload: { type: 'success', message: 'You are subscribed successfully.' },
      });
      return res;
    } catch (err) {
      dispatch({
        type: COMMON.TOP_ALERT,
        payload: { type: 'error', message: getErrorMessage(err) },
      });
      throw err;
    }
  };
};

export const unSubscribe = () => {
  return async (dispatch) => {
    try {
      const res = await TokenApi.post('api/v1/users/unsubscribe/');
      dispatch({
        type: COMMON.TOP_ALERT,
        payload: { type: 'success', message: 'You are unsubscribed successfully.' },
      });
      return res;
    } catch (err) {
      dispatch({
        type: COMMON.TOP_ALERT,
        payload: { type: 'error', message: getErrorMessage(err) },
      });
      throw err;
    }
  };
};

export const get_subscriptions = () => {
  return async (dispatch) => {
    try {
      const res = await TokenApi.get('api/v1/users/get_subscriptions/');
      dispatch({
        type: SUBSCRIPTIONS.LIST,
        payload: { type: 'success', message: res },
      });
      return res;
    } catch (err) {
      dispatch({
        type: COMMON.TOP_ALERT,
        payload: { type: 'error', message: getErrorMessage(err) },
      });
      throw err;
    }
  };
};

// Reset password & change password request
export const resetPwdRequest = (obj) => async (dispatch) => {
  try {
    const res = await Api.post('rest-auth/password/reset-request/', obj)
    dispatch({
      type: COMMON.TOP_ALERT,
      payload: { type: 'success', message: 'Reset password request has been sent successfully.' }
    })
    return res
  } catch (err) {
    dispatch({
      type: COMMON.TOP_ALERT,
      payload: { type: 'error', message: err ? getErrorMessage(err) : "Email doesn't match our records" }
    })
    throw err
  }
}

// Reset password & change password
export const resetPwdComplete = (obj) => async (dispatch) => {
  try {
    const res = await Api.post('rest-auth/password/new/', obj)
    dispatch({
      type: COMMON.TOP_ALERT,
      payload: { type: 'success', message: 'The password has been set successfully.' }
    })
    return res
  } catch (err) {
    dispatch({
      type: COMMON.TOP_ALERT,
      payload: { type: 'error', message: getErrorMessage(err) }
    })
    throw err
  }
}

export const changePassword = (obj) => async (dispatch) => {
  try {
    const res = await TokenApi.post('rest-auth/password/change/', obj)
    return res
  } catch (err) {
    dispatch({
      type: COMMON.TOP_ALERT,
      payload: { type: 'error', message: getErrorMessage(err) }
    })
    throw err
  }
}

export const logOut = () => {
  return async (dispatch) => {
    try {
      await TokenApi.post('rest-auth/logout/')
      window.localStorage.removeItem('accessToken')
      dispatch({
        type: AUTH.SIGNED_OUT
      })
    } catch (err) {
      window.localStorage.removeItem('accessToken')
      dispatch({
        type: AUTH.SIGNED_OUT
      })
    }
  }
}
