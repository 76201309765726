import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Row, Col, Divider, Card, Statistic, Button, Switch, Space, Modal, Table, Empty, Skeleton } from 'antd'
import { CheckOutlined, EyeOutlined } from '@ant-design/icons'
import { UPGRADE_PLAN_OPTIONS } from '@/constants'
import SquareElement from '@/component/SquareElement'
import AuthActions from '@/redux/auth'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { Alert } from 'antd'
import './style.less'
import InvoiceTable from '@/component/InvoiceTable'
const UpgradePlan = () => {
  const { userInfo, invoiceLists } = useSelector((state) => state.auth)
  const [isYearly, setIsYearly] = useState(false)
  const [activeMembershipPlan, setActiveMembershipPlan] = useState(null)
  const location = useLocation()
  const pathname = location ? location.pathname : ''
  const { membership_plan, membership_avail } = userInfo || {}
  const [searchParams] = useSearchParams()
  const feature = searchParams.get('feature')
  const isMarketResearchPage = pathname?.indexOf('market-research') > -1
  // const isRestricted = !membership_avail || !membership_plan || (membership_plan === "free" && feature && feature !== "summary") || (["free", "standard"].indexOf(membership_plan) > -1 && isMarketResearchPage)
  // const isRestricted = false
  const isRestricted = !membership_avail
  const [isLoading, setIsLoading] = useState(true)

  const dispatch = useDispatch()

  useEffect(() => {

    const fetchData = async () => {
      dispatch(AuthActions.get_subscriptions())
      dispatch(AuthActions.getCurrentUser())
      setIsLoading(false)
    }

    fetchData()
  }, [dispatch])

  const handleChange = (checked) => {
    setIsYearly(checked)
  }

  const handleUnsubscribe = () => {
    Modal.confirm({
      title: 'Do you want to unsubscribe?',
      content:
        'Are you sure that you want to unsubscribe? You can restart your subscription later. You will lose all subscription benefits until you resubscribe.',
      icon: null,
      width: 546,
      closable: true,
      okText: 'Unsubscribe',
      cancelText: 'Cancel',
      onOk: async () => {
        try {
          await dispatch(AuthActions.unSubscribe())
          await dispatch(AuthActions.getCurrentUser())
        } catch (err) {}
      }
    })
  }

  const handleSubscribe = (plan) => {
    setActiveMembershipPlan(plan)
  }

  const groupByPlanName = (subscriptions) => {
    return subscriptions?.reduce((acc, subscription) => {
      const planName = subscription?.plan_name
      if (!acc[planName]) {
        acc[planName] = []
      }
      acc[planName].push(subscription)
      return acc
    }, {})
  }

  const sortedSubscriptions = (userInfo?.subscriptions_details || []).sort((a, b) =>
    a?.cadence === 'monthly' && a?.plan_name == b?.plan_name ? -1 : 1
  )
  const groupedSubscriptions = groupByPlanName(sortedSubscriptions)
  const is_free_subscription = userInfo?.subscription_ended && userInfo?.square_subscription_id ? false : true
  // Sample data structure
  const columns = [
    {
      title: 'Invoice No',
      dataIndex: 'invoice_no',
      key: 'invoice_no'
    },
    {
      title: 'Plan',
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status'
    },
    {
      title: 'Due Date',
      dataIndex: 'due_date',
      key: 'due_date'
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount'
    },
    {
      title: 'View',
      dataIndex: 'public_url',
      key: 'public_url',
      render: (text) => (
        <a href={text} target="_blank" rel="noopener noreferrer" >
          <EyeOutlined scriptUrl={text} />
        </a>
      )
    }
  ]

  return (
    <>
      <div className="dashboard-page upgrade-plan-page">
        <Row gutter={[16]}>
          <Col span={24}>
            <h6 className="pricing-label">Pricing</h6>
            <h1>
              {!userInfo?.canceled_date && !userInfo?.square_subscription_id
                ? 'Choose a plan for 14-day free trial'
                : 'Choose the plan that fits you'}
            </h1>
          </Col>
        </Row>
        <Row gutter={[16, 16]} className="pricing-cards" justify="center">
          <Col span={24} className="billig-options">
            <Space>
              <p>Monthly Billing</p>
              <Switch onChange={handleChange} />
              <p className="d-flex">
                Yearly Billing (Save 10%) <img className="mx-2" src="/images/popular.svg" alt="popular" />
              </p>
            </Space>
          </Col>

          {Object.keys(groupedSubscriptions).map((planName) => {
            const subscription = isYearly
              ? groupedSubscriptions[planName].length > 1
                ? groupedSubscriptions[planName][1]
                : groupedSubscriptions[planName][0]
              : groupedSubscriptions[planName][0]
            const isPopular = false // Adjust as per your logic
            const isSubscribed = membership_plan === subscription?.plan_name && userInfo?.square_subscription_id

            return (
              <Col span={24} md={8} key={subscription?.id}>
                <Card>
                  {isPopular ? (
                    <div className="d-flex d-space mb-3 responsive-card">
                      <h2 className="pricing-label">{subscription?.plan_name}</h2>
                      <h4 className="popular-label">Most Popular</h4>
                    </div>
                  ) : (
                    <h2 className="mb-3">{subscription?.plan_name}</h2>
                  )}
                  <p>{subscription?.plan_desc}</p>
                  <Statistic
                    value={subscription?.amount / 100} // Assuming amount is in cent
                    prefix="$"
                    suffix={isYearly ? '/year' : '/month'}
                  />
                  <Button
                    className="btn"
                    type="primary"
                    ghost={!isPopular}
                    onClick={() =>
                      isSubscribed && !userInfo?.canceled_date
                        ? handleUnsubscribe()
                        : handleSubscribe(subscription?.plan_name)
                    }
                  >
                    {isSubscribed && !userInfo?.canceled_date
                      ? 'Unsubscribe'
                      : is_free_subscription && !userInfo?.canceled_date
                        ? 'Subscribe for free trial'
                        : 'Subscribe'}
                  </Button>
                  <Divider />
                  <ul>
                    {subscription?.items?.map((item, idx) => (
                      <li key={idx}>
                        <CheckOutlined />
                        <p>{item}</p>
                      </li>
                    ))}
                  </ul>
                </Card>
              </Col>
            )
          })}
          

          <SquareElement
            userInfo={userInfo}
            membershipPlan={activeMembershipPlan}
            setActiveMembershipPlan={setActiveMembershipPlan}
            membershipPeriod={isYearly ? 'yearly' : 'monthly'}
            free_subscription={userInfo?.square_subscription_id || userInfo?.subscription_ended ? false : true}
          />
        </Row>
      </div>
      { invoiceLists?.message?.message?.invoices  && <div className="mt-8 mb-4">
        <h2 className="text-xl font-bold mb-4">Invoices</h2>
        <Divider className="text-xl"/>
        {isLoading ? (
          <div className="grid gap-2">
            {Array(4)
              .fill(null)
              .map((item, index) => (
                <Skeleton.Input key={index} active size={30} className="w-full rounded-md " />
              ))}
            {/* <Skeleton.Input active size={20} className='w-full' /> */}
          </div>
        ) : invoiceLists?.message?.message?.invoices ? (
          <InvoiceTable invoices={invoiceLists?.message?.message?.invoices} columns={columns} />
        ) : (
          <Table locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> }} columns={columns} />
        )}
      </div>}
    </>
  )
}

export default UpgradePlan
