import React, { useState, useEffect } from 'react'
import { Form, Button, Select, Typography, InputNumber, Card, Divider } from 'antd'
import InlineRow from '@/component/InlineRow'
import NumberInput from '@/component/NumberInput'
import { YES_NO_OPTIONS, LOAN_FINANCING_INFO } from '@/constants'
import TooltipWithInfo from '@/component/TooltipWithInfo'

const { Text } = Typography

const PMT = (rate, nper, pv, fv = 0, type = 0) => {

  if (rate === 0) return -(pv + fv) / nper
  const pvif = Math.pow(1 + rate, nper)
  const pmt = (rate / (pvif - 1)) * -(pv * pvif + fv)
  if (type === 1) {
    return pmt / (1 + rate)
  }
  return pmt
}

export default function Step9LoanFinancing({ dealInfo, handleUpdate }) {
  const [form] = Form.useForm()
  const [showAssumedLoanInput, setShowAssumedLoanInput] = useState(false)
  const [showCalculatedLoanInput, setShowCalculatedLoanInput] = useState(false)
  const [downPayment, setDownPayment] = useState(0)
  const [interestRate, setInterestRate] = useState(0)
  const [dscr, setDscr] = useState(0)
  const purchasePrice = dealInfo?.details?.property?.purchase_price ?? 0
  const NOI = dealInfo?.details?.expenses.length > 0 ? dealInfo?.details?.expenses[0].noi : 0

  useEffect(() => {
    if (dealInfo?.details?.financing) {
      const financing = dealInfo.details.financing
      if (
        financing.down_payment_percentage != '' &&
        financing.down_payment_percentage > 0
      ) {
        handleSelect(false)
        form.setFieldsValue({
          assuming_loan: false,
        })
        setDownPayment(financing.down_payment)
      } else {
        handleSelect(true)
        form.setFieldsValue({
          assuming_loan: true,
        })
        setDownPayment(financing.down_payment)
      }
      form.setFieldsValue({
        assuming_amount: financing.assuming_amount,
        down_payment_percentage: financing.down_payment_percentage,
        interest_rate: financing.interest_rate,
        interest_only_period_months: financing.interest_only_period,
        amortization_term: financing.amort_term_for_interest_only_period,
        amortization_term_after_interest: financing.amort_after_interest_only_period,
        loan_term: financing.term,
        down_payment: financing.down_payment,
      })
    }
  }, [])

  const handleSubmit = async (values) => {
    let {
      assuming_amount,
      down_payment_percentage,
      interest_rate,
      interest_only_period_months,
      amortization_term,
      amortization_term_after_interest,
      loan_term
    } = values

    const formattedData = {
      assuming_amount: assuming_amount || 0,
      down_payment_percentage: down_payment_percentage || 0,
      interest_rate: interestRate,
      interest_only_period: interest_only_period_months || 0,
      amort_term_for_interest_only_period: amortization_term || 250,
      amort_after_interest_only_period: amortization_term_after_interest || 30,
      term: loan_term || 10,
      dscr: dscr,
      down_payment: downPayment
    }
    await handleUpdate(formattedData)
  }

  const handleSelect = (value) => {
    if (value) {
      setShowAssumedLoanInput(true)
      setShowCalculatedLoanInput(false)
    } else {
      setShowAssumedLoanInput(false)
      setShowCalculatedLoanInput(true)
    }
  }

  const handlePercentageInput = (value) => {
    if (value) {
      const ans = (purchasePrice * value) / 100
      setDownPayment(ans)
      form.setFieldsValue({ down_payment: ans })
    }
  }

  const calculateDSCR = (noi, debtService) => {
    if (debtService === 0) return 0
    return (noi / debtService).toFixed(2)
  }

  useEffect(() => {
    const loanAmount = purchasePrice - downPayment
    const monthlyRate = interestRate / 100 / 12
    const interestOnlyMonths = form.getFieldValue('interest_only_period_months') || 0
    const totalMonths = (form.getFieldValue('amortization_term_after_interest') || 30) * 12
    const amortizedMonths = totalMonths - interestOnlyMonths

    const interestOnlyPayment = loanAmount * monthlyRate
    const amortizedPayment = PMT(monthlyRate, amortizedMonths, loanAmount)

    const totalDebtService = interestOnlyPayment * interestOnlyMonths + amortizedPayment * amortizedMonths

    setDscr(calculateDSCR(NOI, totalDebtService))
  }, [interestRate, downPayment, showAssumedLoanInput, showCalculatedLoanInput, purchasePrice])

  return (
    <div className="">
      <h2>Loan Financing</h2>
      <div className="flex">
        <Card style={{ width: '70%' }}>
          <Form
            layout="vertical"
            form={form}
            requiredMark={false}
            onFinish={handleSubmit}
            initialValues={{
              assuming_amount: dealInfo?.details?.financing?.assuming_amount,
              down_payment_percentage: dealInfo?.details?.financing?.down_payment_percentage,
              interest_rate: dealInfo?.details?.financing?.interest_rate,
              interest_only_period_months: dealInfo?.details?.financing?.interest_only_period,
              amortization_term: dealInfo?.details?.financing?.amort_term_for_interest_only_period,
              amortization_term_after_interest: dealInfo?.details?.financing?.amort_after_interest_only_period,
              loan_term: dealInfo?.details?.financing?.term,
              down_payment: dealInfo?.details?.financing?.down_payment
            }}
          >
            <Typography.Title level={4}>Now let's discuss the loan for this property:</Typography.Title>
            <Divider />
            <Form.Item
              name="interest_rate"
              label={
                <>
                  {'What is the interest rate?'}
                  <TooltipWithInfo info={LOAN_FINANCING_INFO[0]['What is the interest rate?']} />
                </>
              }
            >
              <InputNumber
                addonAfter="%"
                onChange={(e) => {
                  setInterestRate(e)
                }}
              />
            </Form.Item>

            <NumberInput
              tooltipInfo={{ info: LOAN_FINANCING_INFO[0]['What is the Interest only period (months)?'] }}
              label="What is the Interest only period (months)?"
              name="interest_only_period_months"
            />

            <NumberInput
              tooltipInfo={{
                info: LOAN_FINANCING_INFO[0]['What is the amortization term for interest only period?']
              }}
              label="What is the amortization term for interest only period?"
              name="amortization_term"
              defaultValue={250}
              required={false}
            />

            <NumberInput
              tooltipInfo={{
                info: LOAN_FINANCING_INFO[0]['What is the amortization term after interest only period (years)?']
              }}
              label="What is the amortization term after interest only period (years)?"
              name="amortization_term_after_interest"
              defaultValue={30}
              required={false}
            />

            <NumberInput
              tooltipInfo={{ info: LOAN_FINANCING_INFO[0]['What is the term?'] }}
              label="What is the term?"
              name="loan_term"
              defaultValue={10}
              required={false}
            />

            <Form.Item
              label={
                <>
                  {'Are you assuming the loan?'}
                  <TooltipWithInfo info={LOAN_FINANCING_INFO[0]['Are you assuming the loan?']} />
                </>
              }
              name="assuming_loan"
              rules={[{ required: true, message: 'Required' }]}
            >
              <Select options={YES_NO_OPTIONS} onChange={(value) => handleSelect(value)} />
            </Form.Item>

            {showAssumedLoanInput && (
              //  <NumberInput
              //   name="down_payment"
              //   label="How much are you assuming it for?"
              //   onChange={(value) => setDownPayment(value)}
              //   pricing
              // />
              <Form.Item name="down_payment" label={<>{'Down Payment'}</>}>
              <InputNumber
                type="text"
                className="number-input text-bold"
                addonBefore="$"
                onChange={(value) => setDownPayment(value)}
              />
            </Form.Item>
            )}
            {showCalculatedLoanInput && (
              <>
                <Form.Item
                  name="down_payment_percentage"
                  label={
                    <>
                      {'How much money are you putting down?'}
                      <TooltipWithInfo info={LOAN_FINANCING_INFO[0]['How much money are you putting down?']} />
                    </>
                  }
                >
                  <InputNumber addonAfter="%" onChange={(value) => handlePercentageInput(value)} />
                </Form.Item>
                <Form.Item name="down_payment" label={<>{'Down Payment'}</>}>
                  <InputNumber
                    type="text"
                    className="number-input text-bold"
                    disabled={true}
                    addonBefore="$"
                    value={downPayment}
                  />
                </Form.Item>
              </>
            )}
          </Form>
        </Card>
        {/* <Card
          className={`ml-5 flex-grow border ${dscr !== null && dscr < 1.25 ? 'border-red-500' : 'border-gray-300'}`}
        >
          <Typography.Title level={4} className={`${dscr !== null && dscr < 1.25 ? 'text-red-500' : ''}`}>
            Real-Time DSCR
          </Typography.Title>
          <div className={` text-xl text-center ${dscr !== null && dscr < 1.25 ? 'text-red-500' : ''}`}>
            {dscr !== null ? dscr : 'Calculating...'}
          </div>
        </Card> */}
      </div>
      <Button onClick={form.submit} type="primary" className="next-btn btn" htmlType="submit">
        Next
      </Button>
    </div>
  )
}
