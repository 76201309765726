import React, { useEffect, useState } from 'react'
import { Form, Button, Select, Divider, Tooltip, InputNumber } from 'antd'
import InlineRow from '@/component/InlineRow'
import NumberInput from '@/component/NumberInput'
import TextInput from '@/component/TextInput'
import { PROPERTY_CLASS_OPTIONS, YEAR_OPTIONS, PROPERTY_INFO } from '@/constants'
import TooltipWithInfo from '@/component/TooltipWithInfo'


const Step1Property = (props) => {
  const [form] = Form.useForm()
  const {handleCreate,dealInfo,edit } = props
  const [initialValues, setInitialValues] = useState({})
  const [loading, setLoading] = useState(true) // Added loading state

  const handleSubmit = async (values) => {
    if(edit){
      await handleCreate(values, false)
    }
    else{
      await handleCreate(values,true)
    }
    
  }


 

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={handleSubmit}
      requiredMark={false}
      preserve={false}
      colon={false}
      initialValues={dealInfo?.details?.property}
    >
      <h1>Property details</h1>
      <Divider />

      <InlineRow>
        <TextInput label="Property Name" name="name" />
        <TextInput label="Address" name="address" />
      </InlineRow>
      <Divider />

      <InlineRow>
        <NumberInput label="# Of units" name="number_of_units" />
        <NumberInput
          label="Purchase Price"
          name="purchase_price"
          pricing={true}
          tooltipInfo={{ info: PROPERTY_INFO[0]['Purchase Price'] }}
        />
      </InlineRow>
      <Divider />

      <InlineRow>
        <NumberInput
          label="Asking Price Per Unit"
          name="asking_price_per_unit"
          pricing={true}
          tooltipInfo={{ info: PROPERTY_INFO[0]['Asking Price Per Unit'] }}
        />
        <NumberInput
          label="Price Per Square Foot"
          name="price_per_square_foot"
          pricing={true}
          tooltipInfo={{ info: PROPERTY_INFO[0]['Price Per Square Foot'] }}
        />
      </InlineRow>
      <Divider />

      <InlineRow>
        <NumberInput
          label="Rentable Square Foot"
          name="rentable_square_foot"
          tooltipInfo={{ info: PROPERTY_INFO[0]['Rentable Square Foot'] }}
        />
        <NumberInput
          label="Hold Time (Years)"
          name="hold_time"
          tooltipInfo={{ info: PROPERTY_INFO[0]['Hold Time (Years)'] }}
        />
      </InlineRow>
      <Divider />

      <InlineRow>
        <Form.Item rules={[{ required: true, message: 'Required' }]} label="Property Class" name="property_class">
          <Select options={PROPERTY_CLASS_OPTIONS} />
        </Form.Item>
        <Form.Item
               name="market_cap_rate"
                label={
                  <>
                    {'Market Cap Rate'}
                    {<TooltipWithInfo info={PROPERTY_INFO[0]['Market Cap Rate']} />}
                  </>
                }
              >
              <InputNumber
                addonAfter="%"
              />
        </Form.Item>
      </InlineRow>
      <Divider />

      <InlineRow>
        <Form.Item rules={[{ required: true, message: 'Required' }]} label="Year Built" name="year_built">
          <Select options={YEAR_OPTIONS} showSearch />
        </Form.Item>
      </InlineRow>

      <Divider />
      {edit ? (
        <Button type="primary" className="next-btn btn" htmlType="submit">
          Submit
        </Button>
      ) : (
        <Button type="primary" className="next-btn btn" htmlType="submit" >
           Next
        </Button>
      )}
     
    </Form>
  )
}

export default Step1Property
