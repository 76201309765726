import React, { useEffect, useState } from 'react'
import { Row, Col, Divider, Button, Space, Input, Statistic, Card, InputNumber, Table } from 'antd'
import InlineRow from '@/component/InlineRow'
import { PROPERTY_CLASS_OPTIONS } from '@/constants'

const RentStackTab = (props) => {
  const { userInfo, dealInfo } = props

  if (!dealInfo || !dealInfo.financials || !dealInfo.financials.summary || !dealInfo.financials.summary.rent_stacks) return null
  const { rent_stacks } = dealInfo.financials.summary

  const tableProps = {
    size: 'large',
    columns: [
      { dataIndex: 'unit_type', title: 'Unit Type', key: 'unit_type', fixed: 'left' },
      {
        dataIndex: 'total_number_of_units',
        title: 'Total Units',
        key: 'total_number_of_units',
        fixed: 'left',
        render: (text) => <Statistic value={text} precision={2} />
      },
      {
        dataIndex: 'total_number_of_units_rate',
        title: '%',
        key: 'total_number_of_units_rate',
        fixed: 'left',
        render: (text) => <Statistic value={text} suffix="%" precision={2} />
      },
      {
        dataIndex: 'unit_sf',
        title: 'Unit SF',
        key: 'unit_sf',
        fixed: 'left',
        render: (text) => <Statistic value={text} />
      },
      {
        dataIndex: 'total_sf',
        title: 'Total SF',
        key: 'total_sf',
        fixed: 'left',
        render: (text) => <Statistic value={text} precision={0} />
      },
      {
        title: 'Sellers',
        children: [
          {
            dataIndex: 'sellers_monthly_rent',
            title: 'Monthly Rent (Unit)',
            key: 'sellers_monthly_rent',
            render: (text) => <Statistic value={text} prefix="$" precision={0} />
          },
          {
            dataIndex: 'sellers_total_rent',
            title: 'Total Monthly Rent',
            key: 'sellers_total_rent',
            render: (text) => <Statistic value={text} prefix="$" precision={0} />
          },
          {
            dataIndex: 'sellers_annual_rent',
            title: 'Total Annual Rent',
            key: 'sellers_annual_rent',
            render: (text) => <Statistic value={text} prefix="$" precision={0} />
          }
        ]
      },
      {
        title: 'Market',
        children: [
          {
            dataIndex: 'market_monthly_rent',
            title: 'Monthly Rent (Unit)',
            key: 'market_monthly_rent',
            render: (text) => <Statistic value={text} prefix="$" precision={0} />
          },
          {
            dataIndex: 'market_total_rent',
            title: 'Total Monthly Rent',
            key: 'market_total_rent',
            render: (text) => <Statistic value={text} prefix="$" precision={0} />
          },
          {
            dataIndex: 'market_annual_rent',
            title: 'Total Annual Rent',
            key: 'market_annual_rent',
            render: (text) => <Statistic value={text} prefix="$" precision={0} />
          }
        ]
      },
      {
        title: 'Upside',
        children: [
          {
            dataIndex: 'rent_increase',
            title: 'Rent Increase',
            key: 'rent_increase',
            render: (text) => <Statistic value={text} prefix="$" precision={0} />
          },
          {
            dataIndex: 'rent_rate_increase',
            title: 'Rent % Increase',
            key: 'rent_rate_increase',
            render: (text) => <Statistic value={text} suffix="%" precision={2} />
          },
          {
            dataIndex: 'rent_amount',
            title: 'Rent',
            key: 'rent_amount',
            render: (text) => <Statistic value={text} prefix="$" precision={0} />
          }
        ]
      }
    ],
    dataSource: dealInfo?.financials.rent_stacks,
    scroll: { x: 1720 }
  }

  return (
    <div className="tab-content rent-stack-tab">
      <Row gutter={[16, 16]}>
        <Col span={24} md={12}>
          <Card title="Property Information">
            <InlineRow>
              <Statistic title="Total # of Units:" value={rent_stacks.number_of_units} />
              <Statistic title="Average Unit SF:" value={rent_stacks.average_unit_sf} precision={0} />
              <Statistic title="Total SF:" value={rent_stacks.total_sf} precision={0} />
            </InlineRow>
          </Card>
        </Col>
        <Col span={24} md={12}>
          <Card title="Market">
            <InlineRow>
              <Statistic
                title="Average Market Rent:"
                value={rent_stacks.market_average_rent}
                prefix="$"
                precision={0}
              />
              <Statistic title="Monthly Market:" value={rent_stacks.market_monthly_rent} prefix="$" precision={0} />
              <Statistic title="Annual Market Rent:" value={rent_stacks.market_annual_rent} prefix="$" precision={0} />
            </InlineRow>
          </Card>
        </Col>
        <Col span={24} md={12}>
          <Card title="Sellers">
            <InlineRow>
              <Statistic
                title="Sellers Average Rent:"
                value={rent_stacks.sellers_average_rent}
                prefix="$"
                precision={0}
              />
              <Statistic
                title="Sellers Monthly Rent:"
                value={rent_stacks.sellers_monthly_rent}
                prefix="$"
                precision={0}
              />
              <Statistic
                title="Sellers Annual Rent:"
                value={rent_stacks.sellers_annual_rent}
                prefix="$"
                precision={0}
              />
            </InlineRow>
          </Card>
        </Col>
        <Col span={24} md={12}>
          <Card title="Potential Upside">
            <InlineRow>
              <Statistic
                title="Total Rent Increase:"
                value={rent_stacks.upside_total_rent_increase}
                prefix="$"
                precision={0}
              />
              <Statistic title="Average Rental:" value={rent_stacks.upside_average_rental} suffix="%" precision={2} />
              <Statistic
                title="Average Monthly Rental:"
                value={rent_stacks.upside_average_monthly_rent}
                prefix="$"
                precision={0}
              />
            </InlineRow>
          </Card>
        </Col>
        <Col span={24}>
          <Card>
            <Table {...tableProps} />
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default RentStackTab
