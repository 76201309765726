import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Divider, Form, Button, Space, Input, Select, Radio } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import Upload from 'rc-upload'
import { COMMON_RULE, PAGE_NUMBER_OPTIONS, FILE_TYPE_OPTIONS } from '@/constants'

const Step1_File = (props) => {
  const [form] = Form.useForm()
  const { dealInfo, handleUpload, handleCreate, resource, fileType, setFileType } = props

  useEffect(() => {}, [dealInfo])

  const handleSubmit = async (values) => {
    await handleCreate(values)
  }

  let page_numbers = []
  if (resource?.pages) {
    page_numbers = [...Array(resource.pages)].map((val, idx) => ({
      value: idx + 1,
      label: `${idx + 1} Page`
    }))
  }

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={handleSubmit}
      requiredMark={false}
      preserve={false}
      colon={false}
      initialValues={dealInfo}
    >
      <Row gutter={16}>
        <Col span={24} md={12}>
          <Form.Item label="Upload file">
            <Radio.Group
              options={FILE_TYPE_OPTIONS}
              onChange={(e) => setFileType(e.target.value)}
              value={fileType}
              className="mb-2"
              disabled={dealInfo}
            ></Radio.Group>
            <Upload
              customRequest={async ({ onError, onSuccess, file }) => handleUpload(file)}
              showUploadList={false}
              multiple={false}
              className="upload-file"
              disabled={dealInfo}
            >
              <Input prefix={<UploadOutlined />} placeholder="Upload" readOnly value={resource?.name} />
            </Upload>
          </Form.Item>
        </Col>
        {fileType === 'pdf' && (
          <>
            <Col span={24} md={12}>
              <Form.Item label="Property Detail Page" name="property_detail_page">
                <Select
                  className="text-cap"
                  options={page_numbers}
                  placeholder="Select the page numbers"
                  allowClear
                  disabled={dealInfo}
                />
              </Form.Item>
              <Form.Item label="Incomes and Expenses Page" name="incomes_and_expenses_page">
                <Select
                  className="text-cap"
                  options={page_numbers}
                  placeholder="Select the page numbers"
                  allowClear
                  disabled={dealInfo}
                />
              </Form.Item>
              <Form.Item label="Rent Stacks Page" name="rent_stacks_page">
                <Select
                  className="text-cap"
                  options={page_numbers}
                  placeholder="Select the page numbers"
                  allowClear
                  disabled={dealInfo}
                />
              </Form.Item>
            </Col>
          </>
        )}
      </Row>
      <Button type="primary" className="next-btn btn" htmlType="submit" disabled={!resource}>
        Next
      </Button>
    </Form>
  )
}

export default Step1_File
