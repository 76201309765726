import React, { useEffect, useState } from 'react'
import { Row, Col, Divider, Form, Button, Space, Input, Select } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import InlineRow from '@/component/InlineRow'
import NumberInput from '@/component/NumberInput'
import TextInput from '@/component/TextInput'
import { INCREASE_INCOME_OPTIONS } from '@/constants'

export default function Step6InvestmentStrategy(props) {
  const [form] = Form.useForm()
  const { dealInfo, dealInput, handleUpdate } = props
  const [initialValues, setInitialValues] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchInitialValues = async () => {
      const storedValues = dealInput.investment_strategy
      if (storedValues) {
        setInitialValues(storedValues)
        setLoading(false)
      }
    }
    fetchInitialValues()
  }, [])

  const handleSubmit = async (values) => {
    await handleUpdate(values)
  }

  let previousValue = '';
  if (dealInfo?.details?.property?.plan_on_increasing_income !== '') {
    previousValue = dealInfo?.details?.property?.plan_on_increasing_income;
  } else {
    previousValue = initialValues?.plan_on_increasing_income;
  }

  return (
    <Form layout="vertical" form={form} onFinish={handleSubmit} requiredMark={false} preserve={false} colon={false}>
      <h1 className="mb-4">Investment Strategy</h1>

      <InlineRow>
        <Form.Item
          label="Are you planning on increasing income through value add or decreasing expenses, or both? "
          name="plan_on_increasing_income"
          rules={[{ required: true, message: 'Required' }]}
          initialValue={previousValue}
        >
          <Select placeholder="Select Investment strategy" options={INCREASE_INCOME_OPTIONS} />
        </Form.Item>
      </InlineRow>
      <Button type="primary" className="next-btn btn" htmlType="submit">
        Next
      </Button>
    </Form>
  );
}
