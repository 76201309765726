import React from 'react'
import { Popover, Typography, Divider } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import './style.less'

const { Title, Text } = Typography

const TooltipWithInfo = ({ info, children }) => {
  const toolTipTextContent = Array.isArray(info) ? (
    <div className="tooltip-info">
      {info.map((section, index) => (
        <div key={index} className="tooltip-info-section">
          {Object.entries(section).map(([question, answer], idx) => (
            <div key={idx} className="tooltip-info-grid">
              <Title level={5} className="tooltip-question">
                {question}
              </Title>
              <Text className="tooltip-answer">{answer}</Text>
              <Divider />
            </div>
          ))}
        </div>
      ))}
    </div>
  ) : (
    <Text>{info}</Text>
  )

  return (
    <Popover content={toolTipTextContent} trigger="click" overlayClassName="tooltip-popover">
      <InfoCircleOutlined className="info-icon" />
    </Popover>
  )
}

export default TooltipWithInfo
