import React from 'react'
import { Navigate } from 'react-router-dom'
import {
  DashboardOutlined,
  UserOutlined,
  AppstoreOutlined,
  FileTextOutlined,
  SearchOutlined,
  HomeOutlined,
  CreditCardOutlined,
  SettingOutlined,
  BulbOutlined,
  
} from '@ant-design/icons'

// Layouts
import { CustomerLayout, AuthLayout } from '@/layouts'

// Guards
import GuestGuard from '@/component/GuestGuard'
import AuthGuard from '@/component/AuthGuard'

// Views
import Signin from '@/pages/auth/signin'
import Signup from '@/pages/auth/signup'
import ForgotPassword from '@/pages/auth/forgot_password'
import ResetPassword from '@/pages/auth/reset_password'

import Dashboard from '@/pages/dashboard'
import CreateDeal from '@/pages/create_deal'
import CreateNewDeal from '@/pages/create_new_deal'
import Underwriting from '@/pages/underwriting'
import MarketResearch from '@/pages/market_research'
import UpgradePlan from '@/pages/upgrade_plan'
import Properties from '@/pages/properties'
import Settings from '@/pages/settings'
import Profile from '@/pages/profile'
import Img from '@/component/Img'
import FAQS from '@/pages/faqs'

export default () => {
  return [
    {
      path: '/login',
      guard: GuestGuard,

      layout: AuthLayout,
      element: Signin
    },
    {
      path: '/signup',
      guard: GuestGuard,

      layout: AuthLayout,
      element: Signup
    },
    {
      path: '/forgot-password',
      guard: GuestGuard,

      layout: AuthLayout,
      element: ForgotPassword
    },
    {
      path: '/reset-password',
      guard: GuestGuard,

      layout: AuthLayout,
      element: ResetPassword
    },
    {
      path: '/dashboard',
      guard: AuthGuard,
      layout: CustomerLayout,
      element: Dashboard
    },
    {
      path: '/dashboard/',
      layout: CustomerLayout,
      guard: AuthGuard,

      element: Dashboard,
      navigation: {
        title: 'Dashboard',
        Icon: <AppstoreOutlined />
      }
    },
    {
      path: '/dashboard/create-deal',
      layout: CustomerLayout,
      guard: AuthGuard,

      element: CreateNewDeal
    },
    {
      path: '/dashboard/edit-deal/:dealId',
      layout: CustomerLayout,
      element: CreateNewDeal,
      guard: AuthGuard,

    },
    
    {
      path: '/dashboard/underwriting',
      layout: CustomerLayout,
      guard: AuthGuard,

      element: Underwriting,
      navigation: {
        title: 'Underwriting',
        Icon: <FileTextOutlined />
      }
    },
    {
      path: '/dashboard/underwriting/:dealId',
      layout: CustomerLayout,
      guard: AuthGuard,

      element: Underwriting
    },
    {
      path: '/dashboard/market-research',
      layout: CustomerLayout,
      guard: AuthGuard,

      element: MarketResearch,
      navigation: {
        title: 'Market Research',
        Icon: <SearchOutlined />
      }
    },
    {
      path: '/dashboard/upgrade-plan',
      layout: CustomerLayout,
      guard: AuthGuard,

      element: UpgradePlan,
      navigation: {
        title: 'Pricing',
        Icon: <CreditCardOutlined />
      }
    },
    {
      path: '/dashboard/properties',
      layout: CustomerLayout,
      guard: AuthGuard,

      element: Properties,
      navigation: {
        title: 'My properties',
        Icon: <HomeOutlined />
      }
    },
    {
      path: '/dashboard/properties/:dealId',
      layout: CustomerLayout,
      guard: AuthGuard,

      element: CreateDeal
    },
    {
      path: '/dashboard/faqs',
      guard: AuthGuard,
      layout: CustomerLayout,
      element: FAQS,
      navigation: {
        title: 'FAQ',
        Icon: <BulbOutlined />
      }
    },
    {
      path: '/dashboard/settings',
      layout: CustomerLayout,
      guard: AuthGuard,

      element: Settings,
      navigation: {
        title: 'Settings',
        Icon: <SettingOutlined />
      }
    },
    {
      path: '/dashboard/profile',
      layout: CustomerLayout,
      guard: AuthGuard,

      element: Profile
    },

    {
      path: '/',

      layout: AuthLayout,
      element: Signin
    }
  ]
}
