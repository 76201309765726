import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { Button, Card, Col, Input, Row, Form, Space } from 'antd'
import useMediaQuery from 'use-media-antd-query'
import AuthActions from '@/redux/auth'
import { COMMON_RULE } from '@/constants'

import '../style.less'

const Signup = (props) => {
  const navigate = useNavigate()
  const colSize = useMediaQuery()
  const auth = useSelector((state) => state.auth)
  const isMobile = ['sm', 'xs'].indexOf(colSize) > -1
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const handleSubmit = async (values) => {
    setLoading(true)
    try {
      const res = await dispatch(AuthActions.signup(values))
      if (res.key) {
        await dispatch(AuthActions.getCurrentUser())
        navigate('/dashboard/upgrade-plan')
      }
    } catch (err) {
      setLoading(false)
    }
  }

  if (auth.is_authed) {
    return <Navigate to="/dashboard" />
  }

  return (
    <div className="page-container">
      <div className="auth-page">
        <img src="/images/logo-main.png" alt="logo" />
        <h1 className="mb-40">Create your account</h1>
        <Form layout="vertical" form={form} onFinish={handleSubmit} requiredMark={false} preserve={false} colon={false}>
          <Form.Item label="Email" name="email" rules={COMMON_RULE}>
            <Input type="email" />
          </Form.Item>
          <Form.Item label="Password" name="password1" rules={COMMON_RULE}>
            <Input.Password />
          </Form.Item>
          <Form.Item label="Confirm password" name="password2" rules={COMMON_RULE} className="mb-30">
            <Input.Password />
          </Form.Item>
          <Button className="submit-btn" htmlType="submit" loading={loading}>
            Sign up
          </Button>
          <p className="text-center">
            Have an account? <Link to="/login">Log in to account</Link>
          </p>
        </Form>
      </div>
    </div>
  )
}

export default Signup
