import React, { useMemo, Fragment, useRef, useEffect } from 'react';
import { NavLink, Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import AuthActions from '@/redux/auth';
import { Layout, Menu } from 'antd';

const { SubMenu } = Menu;
const { Sider } = Layout;

const SideBar = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const sidebarRef = useRef(null);

  const routers = useMemo(() => {
    return props.router().filter((router) => {
      const path = router.path;
      const secondSlashIndex = path.indexOf('dashboard/', path.indexOf('/dashboard/') + 1);
      return secondSlashIndex !== -1 && secondSlashIndex < path.length - 1;
    });
  }, []);

  const logout = () => {
    dispatch(AuthActions.logOut());
    navigate('/');
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (props.self.isMobile && props.self.sidebarOpen && sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        props.self.setSidebarOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [props.self]);

  const renderNavigation = ({ navigation = null, children = null, path = '/' }, key) => {
    if (!navigation) return null;

    const Icon = navigation.Icon || Fragment;
    const title = navigation.title || '';

    return (
      <Menu.Item key={path} icon={Icon} className="menu__item">
        <NavLink to={path} onClick={() => props.self.isMobile && props.self.setSidebarOpen(false)}>
          {title}
        </NavLink>
      </Menu.Item>
    );
  };

  const getSelectedMenu = () => {
    const route = routers.filter((ro) => ro.path === location.pathname)[0];
    const activeMenu = route ? route.parent || route.path : '';
    return [activeMenu];
  };

  return (
    <Sider width={300} theme="dark" className="sidebar" ref={sidebarRef}>
      <Link to="/dashboard" className="header__logo">
        <img src="/images/logo-main.png" className="logo" alt="logo" />
      </Link>
      <Menu
        theme="light"
        onClick={({ key }) => {
          navigate(key);
        }}
        selectedKeys={getSelectedMenu()}
        className="sidebar__menu"
        mode="inline"
      >
        {routers.map(renderNavigation)}
      </Menu>
    </Sider>
  );
};

export default SideBar;
