import React, { useEffect, useRef, useState } from 'react'
import { Layout } from 'antd'
import SideBar from './sidebar'
import Header from './header'
import useMediaQuery from 'use-media-antd-query'

// Routers
import router from '@/routes'
import { useLocation, useNavigate } from 'react-router-dom'

const CustomerLayout = (props) => {
  const [collapsed, setCollapsed] = useState(window.localStorage.getItem('sidebarCollapsed') === 'false' ? false : true)
  const [pageTitle, setPageTitle] = useState('Overview')
  const navigate = useNavigate()
  const location = useLocation()
  const pathname = location ? location.pathname : ''
  const routerNames = router().filter((router) => {
    const path = router.path
    const secondSlashIndex = path.indexOf('dashboard/', path.indexOf('/dashboard/') + 1)
    return secondSlashIndex !== -1 && secondSlashIndex < path.length - 1
  })
  const colSize = useMediaQuery()
  const isMobile = ['sm', 'xs'].indexOf(colSize) > -1
  const [sidebarOpen, setSidebarOpen] = useState(isMobile ? false : true)

  useEffect(() => {
    setPageTitle(
      routerNames?.filter((router) => router.path.indexOf(pathname.split('/')[2]) > -1)[0]?.navigation?.title ||
        'Overview'
    )
  }, [pathname])

  useEffect(() => {
    if (window.innerWidth > 767) {
      setSidebarOpen(true)
    }
  }, [window.innerWidth])

  const onCollapse = (collapsed) => {
    setCollapsed(collapsed)
    window.localStorage.setItem('sidebarCollapsed', collapsed)
  }
  const preventSidebarClose = (event) => {
    event.stopPropagation()
  }
  return (
    <Layout className="app-layout">
      {sidebarOpen && (
        <SideBar
          self={{ collapsed, isMobile, onCollapse, defaultCollapsed: true, setSidebarOpen, sidebarOpen }}
          router={router}
        />
      )}
      <Layout.Content style={isMobile ? {} : { marginLeft: 300 }}>
        <Header
          pageTitle={pageTitle}
          isMobile={isMobile}
          router={router}
          preventSidebarClose={preventSidebarClose}
          openSidebar={() => setSidebarOpen(true)}
        />
        <Layout className="admin-layout">
          <div className="container dashboard">{props.children}</div>
        </Layout>
      </Layout.Content>
    </Layout>
  )
}

const AuthLayout = (props) => {
  const colSize = useMediaQuery()
  const isMobile = ['sm', 'xs'].indexOf(colSize) > -1

  return (
    <Layout
      className="auth-layout"
      style={{
        backgroundImage: 'url("/images/background.png")'
      }}
    >
      <Layout.Content>{props.children}</Layout.Content>
    </Layout>
  )
}

export { CustomerLayout, AuthLayout }
