import React, { useEffect, useState } from 'react'
import { Row, Col, Divider, Button, Space, Input, Statistic, Card, Table } from 'antd'

const IrrTab = (props) => {
  const { userInfo, dealInfo } = props

  const tableProps = {
    size: 'large',
    columns: [
      { dataIndex: 'year', title: 'Year', key: 'year', width: '100px' },
      { dataIndex: 'intervals', title: 'intervals', key: 'intervals', width: '160px' },
      {
        dataIndex: 'acquistion',
        title: 'Acquistion',
        key: 'acquistion',
        width: '160px',
        render: (text) => text && <Statistic value={text} prefix="$" precision={0} />
      },
      {
        dataIndex: 'cash_flow',
        title: 'Cash Flow',
        key: 'cash_flow',
        width: '160px',
        render: (text) => text && <Statistic value={text} prefix="$" precision={0} />
      },
      {
        dataIndex: 'balance',
        title: 'Balance',
        key: 'balance',
        render: (text) => text && <Statistic value={text} prefix="$" precision={0} />
      },
      {
        dataIndex: 'profits_from_sale',
        title: 'Profits from Sale',
        key: 'profits_from_sale',
        render: (text, record) =>
          text && (
            <>
              {record.unit ? (
                <p className="mb-0 field-label">{text}</p>
              ) : (
                <Statistic prefix="$" precision={0} value={text} />
              )}
            </>
          )
      },
      {
        dataIndex: 'total',
        title: 'Total',
        key: 'total',
        render: (text, record) =>
          text && (
            <Statistic
              value={text}
              prefix={!record.unit && '$'}
              suffix={record.unit && '%'}
              precision={record.unit ? 2 : 0}
            />
          )
      }
    ],
    pagination: false
  }

  return (
    <div className="tab-content">
      <Row>
        <Col span={24}>
          <Card title="IRR">
            <Table
              {...tableProps}
              dataSource={dealInfo?.financials?.irrs}
              rowKey={(record, index) => index} 
              expandable={{
                expandedRowRender: (record) => (
                  <Table
                    {...tableProps}
                    showHeader={false}
                    dataSource={record.items}
                    expandable={false} 
                  />
                ),
                rowExpandable: () => true 
              }}
            />
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default IrrTab
