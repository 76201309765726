import React from 'react';
import { Table, Tag } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

const InvoiceTable = ({ invoices, columns }) => {


  const dataSource = invoices.map((invoice, index) => ({
    key: index,
    invoice_no: invoice?.invoice_number,
    title: invoice ? invoice?.title: '',
    status: <Tag icon={invoice?.status == "PAID" ?
      <CheckCircleOutlined /> :
      invoice.status == "DRAFT" ?
        <ExclamationCircleOutlined /> :
        <CloseCircleOutlined />}
      color={invoice.status == "PAID" ?
        "success" :
        invoice.status == "DRAFT" ?
          "warning" :
          "error"}>{invoice.status}</Tag>,
    paymentMethod: Object.keys(invoice.accepted_payment_methods)
      .filter(method => invoice.accepted_payment_methods[method])
      .join(', '),
    due_date: invoice.payment_requests[0].due_date,
    amount: '$' + invoice.payment_requests[0]?.total_completed_amount_money.amount / 100,
    public_url: invoice.public_url,
  }));

  return (
    <div>
      <Table columns={columns} dataSource={dataSource} bordered />
    </div>

  );
};

export default InvoiceTable;
