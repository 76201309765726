import React, { useEffect, useState } from 'react'
import { Modal, Button } from 'antd'
import { useNavigate } from 'react-router-dom';

export default function Step0Intro({modalVisible,handleStep0}) {

  const navigate= useNavigate();
  const handleClose=()=>{
    navigate('/dashboard/properties')
  }
  useEffect(()=>{
    localStorage.removeItem('rent_unit_mix')
    localStorage.removeItem('expenses')
    localStorage.removeItem('loan_financing')
    localStorage.removeItem('value_add')
  },[])

  return (
    <Modal
      centered
      open={modalVisible}
      footer={null}
      width={600}
      closable
      onCancel={handleClose}
      bodyStyle={{ padding: '30px' }}
    >
      <div className="intro-content">
        <div className="intro-header">Here's What we Expect:</div>
        <div className="intro-steps">
          <div className="step">
            <div className="dot">1</div>
            <div className="step-text">
              <span>Tell us a few details about the property you are underwriting.</span>
            </div>
          </div>
          <div className="line"></div>
          <div className="step">
            <div className="dot">2</div>
            <div className="step-text">
              <span>Insert some of the seller's historical financials</span>
            </div>
          </div>
          <div className="line"></div>
          <div className="step">
            <div className="dot">3</div>
            <div className="step-text">
              <span>Choose your strategy for the property and we'll guide you the rest of the way!</span>
            </div>
          </div>
        </div>
        <div className="intro-footer">
          <Button type="primary"   size="large" onClick={handleStep0}>
            Let's Go
          </Button>
        </div>
      </div>
    </Modal>
  )
}
