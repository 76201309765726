import React, { useState, useEffect } from 'react';
import { Modal, Button, Alert, Spin } from 'antd';
import { CreditCard, PaymentForm } from 'react-square-web-payments-sdk';
import { useDispatch } from 'react-redux';
import AuthActions from '@/redux/auth';
import './style.less';

const SquareElement = ({ userInfo, membershipPlan, membershipPeriod, setActiveMembershipPlan, free_subscription }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const button = document.getElementById('rswp-card-button');
    if (button) {
      if (loading) {
        button.classList.add('loading');
      } else {
        button.classList.remove('loading');
      }
    }
  }, [loading]);

  const handlePaymentMethod = async (token) => {
    setLoading(true);
    setError(null);

    try {
      const res = await dispatch(
        AuthActions.subscribe({
          payment_information: token,
          membership_plan: membershipPlan,
          membership_period: membershipPeriod
        })
      );

      if (res) {
        await dispatch(AuthActions.getCurrentUser());
        setActiveMembershipPlan(null);
      }
    } catch (err) {
      console.error('Error during subscription creation:', err);
      setError('An error occurred. Please try again.');
    }

    setLoading(false);
  };

  const cardTokenizeResponseReceived = (token, verifiedBuyer) => {
    handlePaymentMethod(token);
  };

  const createSubscription = () => {
    document.querySelector('button[type="submit"]').click();
  };

  return (
    <Modal
      title="Card Information"
      visible={!!membershipPlan}
      onCancel={() => setActiveMembershipPlan(null)}
      footer={null}
      width={600}
      maskClosable={false}
      className="subscribe-modal"
    >
      <PaymentForm
        applicationId={process.env.SQUARE_APPLICATION_ID}
        locationId={process.env.SQUARE_LOCATION_ID}
        cardTokenizeResponseReceived={cardTokenizeResponseReceived}
        createVerificationDetails={() => ({
          amount: '0.00', // For free trial
          currencyCode: 'USD',
          intent: 'CHARGE',
          billingContact: {
            givenName: userInfo?.first_name || 'Unknown',
            familyName: userInfo?.last_name || 'Unknown',
            email: userInfo?.email || 'Unknown'
          }
        })}
      >
        <CreditCard>
          <button type="submit">
            {loading ? <Spin /> : 'Subscribe'}
          </button>
        </CreditCard>
        {error && <p className="error-msg">{error}</p>}
      </PaymentForm>
      {free_subscription ? (
        <Alert
          message="Free Trial Information"
          description="Start your 14-day free trial by providing your credit card information. You won’t be charged until the trial ends. Afterward, your selected plan will be billed automatically."
          type="info"
          className="m-2 text-justify"
          showIcon
          closable
        />
      ) : (
        ''
      )}
    </Modal>
  );
};

export default SquareElement;
