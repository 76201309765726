import React from 'react'
import { Result } from 'antd'
import { Link } from 'react-router-dom'
import './style.less'

const NotFound = ({ Navigate }) => (
  <Result
    status="404"
    title="404"
    subTitle="Sorry, the page you visited does not exist."
    extra={
      <Link to={Navigate} className="btn">
        Back Home
      </Link>
    }
    className="not-found-page"
  />
)

export default NotFound
