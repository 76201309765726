import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useLocation, useSearchParams } from 'react-router-dom'
import { Row, Col, Divider, Card, Statistic, Table, Input, Button, Space, Modal } from 'antd'
import { SearchOutlined, EditOutlined, FileTextOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import useMediaQuery from 'use-media-antd-query'
import DealActions from '@/redux/deal'
import './style.less'

const Properties = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [loading, setLoading] = useState(false)
  const { userInfo, is_authed } = useSelector((state) => state.auth)
  const { dealInfo, dealList } = useSelector((state) => state.deal)
  const colSize = useMediaQuery()
  const isMobile = ['sm', 'xs'].indexOf(colSize) > -1

  const searchKey = useMemo(() => {
    let query = searchParams.get('query') || ''
    return query
  }, [searchParams])

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      dispatch(DealActions.getDealList(userInfo?.id, searchKey))
      setLoading(false)
    }

    fetchData()
  }, [searchParams])

  const handleAddProperty = () => {
    dispatch(DealActions.resetDealState())
    navigate('/dashboard/create-deal')
  }

  const handleDelete = (dealId) => {
    Modal.confirm({
      title: 'Do you want to delete?',
      content: 'Are you sure that you want to delete this property?',
      icon: null,
      width: 546,
      closable: true,
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk: async () => {
        setLoading(true)
        await dispatch(DealActions.deleteDeal(userInfo.id, dealId))
        await dispatch(DealActions.getDealList(userInfo.id, searchKey))
        setLoading(false)
      }
    })
  }

  const handleSearch = (value) => {
    navigate(`/dashboard/properties?query=${value}`)
  }

  const tableProps = {
    size: 'large',
    columns: [
      { dataIndex: ['property', 'name'], title: 'Name', key: 'name' },
      {
        dataIndex: ['property', 'number_of_units'],
        title: 'Number of Units',
        key: 'number_of_units',
        render: (text) => <Statistic value={text} precision={0} />
      },
      {
        dataIndex: ['property', 'purchase_price'],
        title: 'Purchase Price',
        key: 'purchase_price',
        render: (text) => <Statistic value={text} precision={0} prefix="$" />
      },
      {
        dataIndex: ['property', 'rentable_square_foot'],
        title: 'Rentable Square foot',
        key: 'rentable_square_foot',
        render: (text) => <Statistic value={text} precision={0} />
      },
      { dataIndex: ['property', 'address'], title: 'Address', key: 'address' },
      { dataIndex: ['property', 'year_built'], title: 'Year Built', key: 'year_built' },
      { dataIndex: ['property', 'property_class'], title: 'Property Class', key: 'property_class' },
      {
        title: '',
        key: 'action',
        render: (text, record) => (
          <Space>
            <Button
              type="primary"
              ghost
              size="small"
              onClick={() => navigate(`/dashboard/underwriting/${record.id}`)}
              icon={<FileTextOutlined />}
            ></Button>
            {/* <Button
              size="small"
              onClick={() => navigate(`/dashboard/edit-deal/${record.id}`)}
              icon={<EditOutlined />}
            ></Button> */}
            <Button danger size="small" onClick={() => handleDelete(record.id)} icon={<DeleteOutlined />}></Button>
          </Space>
        )
      }
    ],
    dataSource: dealList?.results
  }

  return (
    <div className="dashboard-page properties-page">
      <div className="property-actions">
        <Input.Search
          className="search-input"
          placeholder="Search property"
          defaultValue={searchKey}
          onSearch={handleSearch}
        />
        <Button onClick={handleAddProperty} className="btn flex items-center" icon={<PlusOutlined />}>
          Add Property
        </Button>
      </div>
      <Row gutter={[16]}>
        <Col span={24}>
          <Card>
            <Table loading={loading} rowKey={(record) => record.id} {...tableProps} />
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Properties
