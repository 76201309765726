import React, { useState, useEffect } from 'react'
import { Table, InputNumber, Button, Space, Typography } from 'antd'
import NumberInput from '../NumberInput'
import TextInput from '../TextInput'

const { Text } = Typography

const CapitalImprovementTable = ({capitalImprovementData, onDataUpdate }) => {
  const [data, setData] = useState([])
  const [total, setTotal] = useState(0)
  useEffect(() => {
    data.capital_improvements = total
    onDataUpdate(data)
  }, [data, onDataUpdate])

  useEffect(()=>{
   if (capitalImprovementData && capitalImprovementData.length > 0) {
      setData(capitalImprovementData);
    } else {
      setData([{ key: 0, description: '', quantity: 0, unit_cost: 0, total_cost: 0 }]);
    }
  },[])

  // Function to add a new empty row to the table
  const addRow = () => {
    const newData = [...data]
    const key = newData.length === 0 ? '0' : (parseInt(newData[newData.length - 1].key) + 1).toString()
    newData.push({ key, description: '', quantity: 0, unit_cost: 0,total_cost: 0 })
    setData(newData)
  }

  // Function to remove a row from the table
  const removeRow = (key) => {
    const newData = data.filter((item) => item.key !== key)
    setData(newData)
  }

  // Function to calculate the total cost based on the data
  const calculateTotal = () => {
    let total = 0
    data.forEach((item) => {
      total += item?.quantity * item?.unit_cost
    })
    setTotal(total)
    return total
  }

  const transformToCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(value)
  }

  // Columns configuration for the table
  const columns = [
    {
      title: 'Description',
      dataIndex: 'description',
      width: '30%',
      render: (text, record) => (
        <TextInput
          style={{ width: '100%' }}
          min={0}
          defaultValue={text}
          onChange={(e) => handleFieldChange(record.key, 'description', e.target.value)}
        />
      )
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      width: '25%',
      render: (text, record) => (
        <NumberInput
          min={0}
          defaultValue={text}
          onChange={(value) => handleFieldChange(record.key, 'quantity', value)}
        />
      )
    },
    {
      title: 'Unit Cost',
      dataIndex: 'unit_cost',
      width: '20%',
      render: (text, record) => (
        <NumberInput
          min={0}
          defaultValue={text}
          onChange={(value) => {
            handleFieldChange(record.key, 'unit_cost', value)
            handleFieldChange(record.key, 'total_cost', record.quantity * record.unit_cost)
          }}
          pricing={true}
        />
      )
    },
    {
      title: 'Total Cost',
      dataIndex: 'total_cost',
      width: '20%',
      render: (text, record) => <Text>{transformToCurrency(record.quantity * record.unit_cost)}</Text>
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: '25%',
      render: (text, record) =>
        data.length > 1 ? (
          <Button style={{ fontSize: '15px' }} type="primary" danger onClick={() => removeRow(record.key)}>
            Remove
          </Button>
        ) : null
    }
  ]

  // Function to handle field changes in the table
  const handleFieldChange = (key, dataIndex, value) => {
    const newData = [...data]
    const index = newData.findIndex((item) => key === item?.key)
    if (index > -1) {
      newData[index][dataIndex] = value
      setData(newData)
    }
  }

  return (
    <div style={{ marginTop: '20px' }}>
      <Table
        columns={columns}
        dataSource={data}
        footer={() => (
          <div style={{ textAlign: 'right' }}>
            <Text strong>Total(s):</Text> <Text strong>{transformToCurrency(calculateTotal())}</Text>
          </div>
        )}
        pagination={false}
        bordered
        scroll={{ x: '100%' }} // This enables horizontal scrolling
      />
      <div style={{ marginTop: '20px', textAlign: 'center' }}>
        <Space>
          <Button onClick={addRow} type="primary">
            Add New
          </Button>
        </Space>
      </div>
    </div>
  )
}

export default CapitalImprovementTable
