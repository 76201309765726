import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Space, Spin, Card } from 'antd'
// import ReactHighcharts from 'react-highcharts'

const Charts = (props) => {
  const dispatch = useDispatch()
  const { treasuryInfo } = useSelector((state) => state.common)
  const { loading } = props

  const getConfig = (data) => {
    return {
      chart: {
        zoomType: 'x'
      },
      title: {
        text: ''
      },
      subtitle: {
        text: ''
      },
      xAxis: {
        type: 'datetime'
      },
      yAxis: {
        title: {
          text: ''
        }
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        area: {
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1
            },
            stops: [
              [0, '#558bf6'],
              [1, '#ffffff']
            ]
          },
          marker: {
            radius: 2
          },
          lineWidth: 2,
          states: {
            hover: {
              lineWidth: 2
            }
          },
          threshold: null
        }
      },

      series: [
        {
          type: 'area',
          name: '',
          data: data
        }
      ]
    }
  }

  return (
    <Row gutter={[16, 16]} className="statistic-items">
      <Col span={24} md={12}>
        <Card title="Treasury Daily Interest Rate Chart">
          {/* {!loading && <ReactHighcharts config={getConfig(treasuryInfo?.interest_rate)} />} */}
        </Card>
      </Col>
      <Col span={24} md={12}>
        <Card title="Mortgage Chart">
          {/* {!loading && <ReactHighcharts config={getConfig(treasuryInfo?.mortgage)} />} */}
        </Card>
      </Col>
    </Row>
  )
}

export default Charts
