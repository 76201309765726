import React, { useEffect, useState } from 'react'
import { useParams, Link, useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Divider, Form, Button, Space, Tabs, Spin } from 'antd'
import { EditOutlined, FileTextOutlined } from '@ant-design/icons'
import SummaryTab from './tabs/summary_tab'
import FinaicialsTab from './tabs/financials_tab'
import RentStackTab from './tabs/rent_stack_tab'
import FinancingTab from './tabs/financing_tab'
import PropertyDetailsTab from './tabs/property_details_tab'
import AmortizationTab from './tabs/amortization_tab'
import IrrTab from './tabs/irr_tab'
import useMediaQuery from 'use-media-antd-query'
import DealActions from '@/redux/deal'
import { DEAL } from '@/constants'
import SubscriptionGuard from '@/component/SubscriptionGuard'
import './style.less'

const Underwriting = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const { userInfo, is_authed } = useSelector((state) => state.auth)
  const { dealInfo, dealInput } = useSelector((state) => state.deal)
  const colSize = useMediaQuery()
  const isMobile = ['sm', 'xs'].indexOf(colSize) > -1
  const [activeTab, setActiveTab] = useState('summary')
  const { dealId } = useParams()
  const [searchParams] = useSearchParams()

  const getParams = () => {
    let feature = searchParams.get('feature') || 'summary'
    return {
      feature
    }
  }

  const updateDealInput = (key, value, growth = null) => {
    if (growth) {
      key = 'growth_rate.' + key
      dispatch({ type: DEAL.DEAL_UPDATE_VALUE, payload: { key, value: value || 0 } })
    } else {
      dispatch({ type: DEAL.DEAL_UPDATE_VALUE, payload: { key, value: value || 0 } })
    }
  }

  const getDealInput = (key, growth = null) => {
    if (growth) {
      return dealInput[growth][key]
    } else {
      let keys = key.split('.')
      return dealInput[keys[0]][keys[1]]
    }
  }

  const loadData = async () => {
    if (dealId) {
      setLoading(true)
      await dispatch(DealActions.getDeal(userInfo.id, dealId))
      setLoading(false)
    } else {
      if (dealInfo === null) {
        navigate('/dashboard/properties')
      }
    }
  }

  useEffect(() => {
    loadData()
  }, [dealId])

  useEffect(() => {
    const { feature } = getParams()
    setActiveTab(feature)
  }, [searchParams])

  const handleSave = async () => {
    setLoading(true)
    await dispatch(
      DealActions.updateDeal(userInfo.id, dealInfo.id, false, {
        inputs: dealInput
      })
    )
    setLoading(false)
  }
  const params = {
    userInfo,
    dealInfo,
    dealInput,
    isMobile,
    updateDealInput,
    getDealInput,
    handleSave
  }

  const tabItems = [
    {
      key: 'summary',
      label: 'Summary',
      children: <SummaryTab {...params} />
    },
    {
      key: 'financials',
      label: 'Financials',
      children: <FinaicialsTab {...params} />
    },
    {
      key: 'rent_stack',
      label: 'Rent Stack',
      children: <RentStackTab {...params} />
    },
    {
      key: 'financing',
      label: 'Financing',
      children: <FinancingTab {...params} />
    },
    {
      key: 'property_details',
      label: 'Property Details',
      children: <PropertyDetailsTab {...params} />
    },
    {
      key: 'amortization',
      label: 'Amortization',
      children: <AmortizationTab {...params} />
    },
    {
      key: 'irr',
      label: 'IRR',
      children: <IrrTab {...params} />
    }
  ]

  const onChangeTab = (key) => {
    navigate(`/dashboard/underwriting/${dealId ? dealId : ''}?feature=${key}`)
  }

  if (!dealInfo) return null

  return (
    <SubscriptionGuard>
      <div className="dashboard-page underwriting-page">
        <Spin spinning={loading}>
          <Tabs
            activeKey={activeTab}
            items={tabItems}
            onChange={onChangeTab}
            className="main-tab"
            // tabBarExtraContent={
            //   <>
            //     <Space>
            //       {activeTab === 'property_details' && (
            //         <Button
            //           type="primary"
            //           ghost
            //           onClick={() => navigate(`/dashboard/properties/${dealInfo.id}`)}
            //           icon={<EditOutlined />}
            //         >
            //           Edit
            //         </Button>
            //       )}
            //       {/*<Button type="primary" className="btn" onClick={() => handleSave()} icon={<FileTextOutlined />}>Save</Button>*/}
            //     </Space>
            //   </>
            // }
          />
        </Spin>
      </div>
    </SubscriptionGuard>
  )
}

export default Underwriting
