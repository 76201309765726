import axios from 'axios'

// General API
const Api = axios.create({
  baseURL: process.env.API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
})

Api.interceptors.response.use(
  (response) => response.data,
  (error) => Promise.reject(error.response.data)
)

// API with token header
const TokenApi = axios.create({
  baseURL: process.env.API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
})

TokenApi.interceptors.request.use(
  (config) => {
    if (!config.withoutAuth) {
      config.headers.Authorization = `Token ${window.localStorage.getItem('accessToken')}`
    }

    return config
  },
  (error) => {
    return Promise.reject(error.response.data)
  }
)

TokenApi.interceptors.response.use(
  (response) => {
    return response.data
  },
  (error) => {
    return Promise.reject(error.response.data)
  }
)

export { Api, TokenApi }
