import React, { useEffect, useState } from 'react'
import { Row, Col, Divider, Button, Space, Input, Statistic, Card, Select, Table, InputNumber, Checkbox } from 'antd'
import { SwapOutlined } from '@ant-design/icons'
import InlineRow from '@/component/InlineRow'
import { FINANCIAL_TABLE_OPTIONS, FINANCIAL_TABLE_TITLES } from '@/constants'

const FinancialsTab = (props) => {
  const [editMode, setEditMode] = useState(false)
  const { userInfo, dealInfo, isMobile, dealInput, updateDealInput, getDealInput, handleSave } = props
  const [financialTable, setFinancialTable] = useState('incomes')
  const [extendedView, setExtendedView] = useState(false)

  if (!dealInfo || !dealInfo.financials || !dealInfo.financials.summary) return null

  const { returns_splits, financials } = dealInfo.financials.summary

  const onChangeFinancialTable = (value) => {
    setFinancialTable(value)
  }
  const toggleEditMode = () => {
    setEditMode(!editMode) // Toggle edit mode when the checkbox is clicked
  }

  const tableExtendedColumns = extendedView
    ? [...Array(10)].map((idx, i) => ({
        dataIndex: `year${i + 1}`,
        title: `Year ${i + 1}`,
        key: `year${i + 1}`,
        render: (text, record) => {
          if (record.field == 'market_rent_growth') {
            return editMode ? (
              <InputNumber
                value={getDealInput(`${record.field}.${i + 1}`) || record[`year${i + 1}`] }
                onChange={(val) => updateDealInput(`${record.field}.${i + 1}`, val)}
                formatter={(value) => `${value}%`}
              />
            ) : (
              <Statistic value={record[`year${i + 1}`] + '%'} precision={0} />
            )
          }
          if (record.unit_of_input) return <Statistic value={text} formatter={(value) => `${value.toFixed(2)}%`} />
          else return <Statistic value={text} precision={0} prefix="$" />
        }
      }))
    : [
        {
          dataIndex: `year1`,
          title: `Year 1`,
          key: `year1`,
          render: (text, record) => {
            if (record.field == 'market_rent_growth') {
              return editMode ? (
                <InputNumber
                  value={getDealInput(`${record.field}.1`) || record.year1 }
                  onChange={(val) => updateDealInput(`${record.field}.1`, val)}
                  formatter={(value) => `${value}%`}
                  onBlur={() => handleSave()}
                />
              ) : (
                <Statistic value={record.year1  + '%'} precision={0} />
              )
            }
            if (record.unit_of_input) return <Statistic value={text} formatter={(value) => `${value.toFixed(2)}%`} />
            else return <Statistic value={text} precision={0} prefix="$" />
          }
        }
      ]

  let tableColumns = [
    {
      dataIndex: 'name',
      title: FINANCIAL_TABLE_TITLES[financialTable],
      key: 'name',
      width: extendedView ? '18%' : '28%',
      fixed: 'left',
      render: (text) => <p className="field-label">{text}</p>
    },
    {
      dataIndex: 'field',
      title: '',
      key: 'field',
      width: extendedView ? '8%' : '12%',
      fixed: 'left',
      render: (text, record) => {
        if (record.input) {
          if (record.input === 'input') {
            return (
              <InputNumber
                value={ getDealInput(text,'growth_rate') ||  record?.input_val }
                onChange={(val) => updateDealInput(text, val,'growth')}
                prefix={record.unit_of_input == '$' ? record.unit_of_input : ''}
                formatter={(value) => `${value}${record.unit_of_input == '$' ? '' : ' %'}`}
                onBlur={() => handleSave()}
                disabled={!editMode}
              />
            )
          } else if (text === 'sellers' || text.startsWith('year')) {
            // Make sellers and year fields editable by default
            return (
              <InputNumber
                value={getDealInput(text)}
                onChange={(val) => updateDealInput(text, val)}
                onBlur={() => handleSave()}
                disabled={!editMode} // Make input fields disabled when editMode is false
              />
            )
          } else return <Statistic value={record.input} suffix={record.unit_of_input || '%'} />
        } else return
      }
    },
    {
      dataIndex: `sellers`,
      title: `Sellers`,
      key: `sellers`,
      fixed: 'left',
      render: (text, record) =>
        record.unit_of_input ? (
          <Statistic value={text} precision={2} formatter={(value) => `${value.toFixed(2)}%`} />
        ) : (
          <Statistic value={text} precision={0} prefix="$" />
        )
    }
  ]

  const tableProps = {
    size: 'large',
    columns: tableColumns.concat(tableExtendedColumns),
    dataSource: dealInfo?.financials[financialTable],
    pagination: false,
    scroll: extendedView ? { x: 1720 } : {}
  }

  return (
    <div className="tab-content financial-tab">
      <Row className="mb-2">
        <Col span={24}>
          <Card>
            <InlineRow className="mb-1 financial-responsive-grid">
              <Statistic title="Number of Units:" value={dealInfo?.details.property.number_of_units} />
              <Statistic title="Exit Value:" value={financials?.exit_value} prefix="$" precision={0} />
              <Statistic
                title="Annual Rate of Return:"
                value={returns_splits?.average_annual_return}
                suffix="%"
                precision={2}
              />
              <Statistic title="IRR:" value={returns_splits?.irr} suffix="%" precision={2} />
            </InlineRow>
            <InlineRow>
              <Statistic title="Purchase DSCR:" value={returns_splits?.purchase_dscr} precision={2} />
              <Statistic title="Year 1 Cap Rate:" value={returns_splits?.year1_cap_rate} suffix="%" precision={2} />
              <Statistic
                title="Annual Cash on Cash:"
                value={returns_splits?.cash_on_cash_return_average}
                suffix="%"
                precision={2}
              />
              <Statistic title="Total Return:" value={returns_splits?.total_returns} suffix="%" precision={2} />
            </InlineRow>
          </Card>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col span={24}>
          <Space className="financial-responsive-space">
            <Checkbox checked={editMode} onChange={toggleEditMode}>
              Edit Mode
            </Checkbox>
            <Select
              value={financialTable}
              options={FINANCIAL_TABLE_OPTIONS}
              placeholder="Select the table"
              onChange={onChangeFinancialTable}
              className="financials-table-select"
            />
            <Button
              type="primary"
              className="btn"
              onClick={() => setExtendedView(!extendedView)}
              icon={<SwapOutlined />}
            >
              Extended View
            </Button>
          </Space>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Card>
            <Table {...tableProps} rowKey={(record) => record.id} />
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default FinancialsTab
