import React, { useEffect, useState } from 'react'
import { Row, Col, Divider, Button, Space, Input, Statistic, Card, InputNumber, Select, Table } from 'antd'
import InlineRow from '@/component/InlineRow'
import ValueAdds from '@/component/ValueAdds'
import { PROPERTY_CLASS_OPTIONS, HOLD_TIME_OPTIONS, INCOMES, EXPENSES_PROPERTIES } from '@/constants'

const NumberInput = ({ className, label, value, field, handleChange, handleSave, prefix = null, suffix = null }) => {
  return (
    <div className={`info-field ${className}`}>
      {label && <p>{label}</p>}
      <InputNumber
        value={value}
        onChange={(val) => {
          handleChange(field, val)
          handleSave()
        }}
        prefix={prefix}
        formatter={(value) => `${value}${suffix ? '%' : ''}`}
        onBlur={() => handleSave()}
      />
    </div>
  )
}

const PropertyDetailsTab = (props) => {
  const { userInfo, dealInfo, dealInput, updateDealInput, handleSave } = props

  if (!dealInfo || !dealInfo.details) return null

  const { details, financials } = dealInfo

  const getAdditionalSellersValue = (field) => {
    const found = financials?.incomes.find((item) => item.field === field)
    return found ? found.sellers || 0 : 0
  }

  const tableProps = {
    size: 'small',
    columns: [
      { dataIndex: 'description', title: 'Description', key: 'description' },
      { dataIndex: 'quantity', title: 'Quantity', key: 'quantity' },
      {
        dataIndex: 'unit_cost',
        title: 'Unit Cost',
        key: 'unit_cost',
        render: (text) => <Statistic value={text} prefix="$" />
      }
    ],
    dataSource: dealInput?.value_add?.capital_improvements_set,
    pagination: false
  }

  const params = {
    handleChange: updateDealInput,
    handleSave: handleSave
  }

  return (
    <div className="tab-content property-details-tab">
      <Row gutter={[16, 16]}>
        <Col span={24} md={6}>
          <Card title="Property Details">
            <Statistic className="mb-1" title="Number of Units:" value={details?.property.number_of_units} />
            <Statistic
              className="mb-1"
              title="Purchase Price:"
              value={details?.property.purchase_price}
              prefix="$"
              precision={0}
            />
            <Statistic
              className="mb-1"
              title="Rentable Square Foot:"
              value={details?.property.rentable_square_foot}
              precision={0}
            />
            <Statistic className="mb-1" title="Year Built:" value={`${details?.property?.year_built} `} />
            <Statistic
              className="mb-1"
              title="Asking Price Per Unit:"
              value={details?.property?.asking_price_per_unit}
              prefix="$"
              precision={0}
            />
            <Statistic
              className="mb-1"
              title="Price Per Square Foot:"
              value={details?.property?.price_per_square_foot}
              prefix="$"
              precision={0}
            />
            <div className="info-field mb-1">
              <p>Property Class</p>
              <Select
                value={dealInput?.property?.property_class}
                options={PROPERTY_CLASS_OPTIONS}
                showSearch
                onChange={(value) => {
                  updateDealInput('property.property_class', value)
                  handleSave()
                }}
              />
            </div>
            <NumberInput
              {...params}
              className="mb-1"
              label="Market Cap Rate:"
              value={dealInput?.property?.market_cap_rate}
              field="property.market_cap_rate"
              suffix="%"
            />
            <div className="info-field mb-1">
              <p>Hold Time (Years):</p>
              <Select
                value={dealInput?.property?.hold_time}
                options={HOLD_TIME_OPTIONS}
                showSearch
                onChange={(value) => {
                  updateDealInput('property.hold_time', value)
                  handleSave()
                }}
              />
            </div>
          </Card>
        </Col>
        <Col span={24} md={12}>
          <Card title="Incomes">
            <InlineRow className="mb-1">
              <p className="mb-0"></p>
              <p className="mb-0 field-label">Sellers</p>
              <p className="mb-0 field-label">Year1</p>
            </InlineRow>
            {INCOMES.map((prop) => (
              <InlineRow className="mb-1" key={prop.value}>
                <p className="mb-0 field-label">{prop.label}:</p>
                <Statistic
                  value={
                    prop.value === 'total_gross_income' || prop.value === 'market_rent'
                      ? getAdditionalSellersValue(prop.value)
                      : details?.incomes?.[0]?.[prop.value] || 0
                  }
                  precision={0}
                  prefix="$"
                />
                <Statistic
                  value={financials?.incomes?.find((income) => income.field === prop.value)?.year1 || 0}
                  precision={0}
                  prefix="$"
                />
              </InlineRow>
            ))}
          </Card>
        </Col>
        <Col span={24} md={6}>
          <Card title="Value Add">


            {/* <Statistic
              className="mb-1"
              title="Additional Renovation per Year"
              value={details?.value_add?.additional_renovation_per_year}
              precision={0}
              prefix="$"
            /> */}

            <NumberInput
              {...params}
              className="mb-1"
              label="Rental Increase per Renovated Unit:"
              value={dealInput?.value_add?.rent_premium_per_unit}
              field="value_add.rent_premium_per_unit"
              prefix="$"
            />
            <NumberInput
              {...params}
              className="mb-1"
              label="Units that Need Upgrades:"
              value={dealInput?.value_add?.count}
              field="value_add.count"
              unit=""
            />
            {/* <NumberInput
              {...params}
              className="mb-1"
              label="Length Of Renovations:"
              value={dealInput?.value_add?.length_of_renovations}
              field="value_add.length_of_renovations"
            /> */}
            <Divider />
            {/* <NumberInput
              {...params}
              className="mb-1"
              label="Other Income:"
              value={dealInput?.value_add?.other_income}
              field="value_add.other_income"
              prefix="$"
            /> */}
            <Statistic
              className="mb-1"
              title="Capital Improvements:"
              value={details?.value_add?.capital_improvements}
              precision={0}
              prefix="$"
            />
            <Divider />
            <Table {...tableProps} />
            <ValueAdds
              {...params}
              data={dealInput?.value_add?.capital_improvements_set}
              field="value_add.capital_improvements_set"
            />
          </Card>
        </Col>
        <Col span={24} md={6}>
          <Card title="Rent Roll">
            <Statistic className="mb-1" title="Total Units:" value={details?.property?.number_of_units} />
            <Statistic
              className="mb-1"
              title="Current Occupied Monthly Income:"
              value={details?.rent_roll?.current_occupied_income}
              prefix="$"
              precision={0}
            />
            <Statistic
              className="mb-1"
              title="Current Vacant Monthly Income:"
              value={details?.rent_roll?.current_vacant_income}
              prefix="$"
              precision={0}
            />
            <Statistic
              className="mb-1"
              title="# of Vacant Units"
              value={details?.rent_roll?.number_of_vacant_units}
              precision={0}
            />
            <Statistic
              className="mb-1"
              title="Occupancy %"
              value={details?.rent_roll?.occupancy_rate}
              suffix="%"
              precision={0}
            />
            <Statistic
              className="mb-1"
              title="Gross Scheduled Income:"
              value={
                details?.rent_roll?.gross_scheduled_income && details?.rent_roll?.gross_scheduled_income > 0
                  ? details?.rent_roll?.gross_scheduled_income
                  : dealInfo?.financials?.summary?.financing?.gross_scheduled_income
              }
              prefix="$"
              precision={0}
            />
            <Statistic
              className="mb-1"
              title="Physical Vacancy:"
              value={
                details?.rent_roll?.physical_vacancy && details?.rent_roll?.physical_vacancy > 0
                  ? details?.rent_roll?.physical_vacancy
                  : dealInfo?.financials?.summary?.financing?.physical_vacancy
              }
              prefix="$"
              precision={0}
            />
            <NumberInput
              {...params}
              className="mb-1"
              label="Below Market Rent per Unit:"
              value={dealInput?.rent_roll?.below_market_rent_per_unit}
              field="rent_roll.below_market_rent_per_unit"
              prefix="$"
            />
            <NumberInput
              {...params}
              label="Units Below Market Rent:"
              value={dealInput?.rent_roll?.units_below_market_rent}
              field="rent_roll.units_below_market_rent"
              unit=""
            />
          </Card>
        </Col>
        <Col span={24} md={12}>
          <Card title="Expenses">
            <InlineRow className="mb-1">
              <p className="mb-0"></p>
              <p className="mb-0 field-label">Sellers:</p>
              <p className="mb-0 field-label">Year1:</p>
            </InlineRow>
            {EXPENSES_PROPERTIES.map((prop) => (
              <InlineRow className="mb-1" key={prop.value}>
                <p className="mb-0 field-label">{prop.label}:</p>
                <Statistic value={details?.expenses?.[0]?.[prop.value] || 0} precision={0} prefix="$" />
                <Statistic
                  value={financials?.expenses?.find((expense) => expense.field === prop.value)?.year1 || 0}
                  precision={0}
                  prefix="$"
                />
              </InlineRow>
            ))}
          </Card>
        </Col>
        <Col span={24} md={6}>
          <Card title="Other">
            <Statistic
              className="mb-1"
              title="Total Capital Improvements"
              value={details?.value_add?.capital_improvements}
              precision={0}
              prefix="$"
            />
            <InlineRow className="mb-1">
              {/* <Statistic
                title="Per Unit:"
                value={details?.other?.total_capital_improvements_per_unit}
                precision={0}
                prefix="$"
              /> */}
              <Statistic
                title="Capital Reserves Per Unit"
                prefix="$"
                value={details?.other?.capital_reserves_per_unit}
                precision={2}
              />
              <Statistic title="Cap Rate @ Resale:" value={details?.other?.cap_rate_resale} precision={2} suffix="%" />
            </InlineRow>
            <InlineRow className="mb-1">
              <Statistic
                title="Investor Split:"
                value={
                  details?.other?.investor_split == 0
                    ? 100 - details?.other?.manager_split
                    : details?.other?.investor_split
                }
                precision={2}
                suffix="%"
              />
              <Statistic
                title="1st Years Insurance:"
                value={dealInfo?.financials?.summary?.uses_of_funds?.first_years_insurance}
                precision={0}
                prefix="$"
              />
            </InlineRow>
            {/* <InlineRow className="mb-1">
              
              <Statistic title="Contingency:" value={details?.other?.contingency} precision={0} prefix="$" />
            </InlineRow> */}
            <InlineRow className="mb-1">
              <NumberInput
                {...params}
                className="mb-1"
                label="Manager Split:"
                value={dealInput?.other?.manager_split}
                field="other.manager_split"
                suffix="%"
              />
              <NumberInput
                {...params}
                label="Preferred Rate:"
                value={dealInput?.other?.preferred_rate}
                field="other.preferred_rate"
                suffix="%"
              />
            </InlineRow>
            <Statistic
              title="Property Tax per Month:"
              value={details?.other?.property_tax_per_month}
              precision={0}
              prefix="$"
            />
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default PropertyDetailsTab
