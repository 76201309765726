import React, { useEffect, useState } from 'react'
import { Form, Button, Select, Card } from 'antd'
import NumberInput from '@/component/NumberInput'
import { YES_NO_OPTIONS, VALUE_ADD_INFO } from '@/constants'
import ValueAddTable from '@/component/ValueAddTable'
import CapitalImprovementTable from '@/component/CapitalImprovementTable'
import RUBSTable from '@/component/RUBSTable'
import TooltipWithInfo from '@/component/TooltipWithInfo'
const Step7ValueAdd = ({ dealInfo, handleUpdate }) => {
  const [form] = Form.useForm()
  const [showFirstSubQuestions, setShowFirstSubQuestions] = useState('')
  const [showValueAddTable, setShowValueAddTable] = useState(false)
  const [capitalImprovementData, setCapitalImprovementData] = useState([])
  const [RUBSData, setRUBSData] = useState([])

  const [valueAddData, setValueAddData] = useState([{ key: 1, year: 1, rows: [{ rowKey: 1 }] }])

  const [showCapitalImprovementTable, setShowCapitalImprovementTable] = useState(false)
  const [showRUBSTable, setShowRUBSTable] = useState(false)

  const handleSubmit = async (values) => {
    const below_market_rent_per_unit = dealInfo?.details?.rent_roll?.below_market_rent_per_unit ?? 0
    const units_below_market_rent = dealInfo?.details?.rent_roll?.units_below_market_rent ?? 0
    // Extract data from form values
    const {
      renovation_value_add,
      count,
      cost_per_unit,
      rent_premium_per_unit,
      extra_value_add,
      capital_improvements,
      rubs
    } = values

    const renovated_value_add_table = [
      {
        year_count: 1,
        market_rent_rate: 15,
        market_rent_amount: 0.15 * below_market_rent_per_unit * units_below_market_rent * 12,
        renovated_rate: 15,
        renovated_amount: 0.15 * count * rent_premium_per_unit * 12
      },
      {
        year_count: 2,
        market_rent_rate: 35,
        market_rent_amount: 0.35 * below_market_rent_per_unit * units_below_market_rent * 12,
        renovated_rate: 20,
        renovated_amount: 0.2 * count * rent_premium_per_unit * 12
      },
      {
        year_count: 3,
        market_rent_rate: 30,
        market_rent_amount: 0.3 * below_market_rent_per_unit * units_below_market_rent * 12,
        renovated_rate: 20,
        renovated_amount: 0.2 * count * rent_premium_per_unit * 12
      },
      {
        year_count: 4,
        market_rent_rate: 15,
        market_rent_amount: 0.15 * below_market_rent_per_unit * units_below_market_rent * 12,
        renovated_rate: 0,
        renovated_amount: 0 * count * rent_premium_per_unit * 12
      },
    ]

    // Prepare data based on form inputs
    const formattedData = {
      renovation_units: renovation_value_add
        ? {
            count,
            cost_per_unit,
            rent_premium_per_unit
          }
        : {
            count: 0,
            cost_per_unit: 0,
            rent_premium_per_unit: 0
          },
      extra_value_add: extra_value_add ? valueAddData : null,
      capital_improvements: capital_improvements ? capitalImprovementData : null,
      rubs_value_add: rubs ? RUBSData : null,
      renovation_value_add: renovation_value_add ? renovated_value_add_table : null
    }
    // Call handleUpdate with the formatted data
    await handleUpdate(formattedData)
  }

  useEffect(() => {
    const storedValueAdd = JSON.parse(localStorage.getItem('value_add'))
    const {
      renovation_units = null,
      extra_value_add = null,
      capital_improvements = null,
      rubs_value_add = null
    } = storedValueAdd || {}
    if (renovation_units?.count > 0) {
      form.setFieldsValue({
        renovation_value_add: true,
        count: renovation_units.count,
        cost_per_unit: renovation_units.cost_per_unit,
        rent_premium_per_unit: renovation_units.rent_premium_per_unit
      })
      setShowFirstSubQuestions(true)
    } else {
      form.setFieldsValue({
        renovation_value_add: false
      })
      setShowFirstSubQuestions(false)
    }
    if (extra_value_add) {
      form.setFieldsValue({
        extra_value_add: true
      })
      setShowValueAddTable(true)
      setValueAddData(extra_value_add)
    } else {
      form.setFieldsValue({
        extra_value_add: false
      })
      setShowValueAddTable(false)
    }
    if (capital_improvements) {
      form.setFieldsValue({
        capital_improvements: true
      })
      setShowCapitalImprovementTable(true)

      setCapitalImprovementData(capital_improvements)
    } else {
      form.setFieldsValue({
        capital_improvements: false
      })
      setShowCapitalImprovementTable(false)
    }

    if (rubs_value_add) {
      form.setFieldsValue({
        rubs: true
      })
      setShowRUBSTable(true)

      setRUBSData(rubs_value_add)
    } else {
      form.setFieldsValue({
        rubs: false
      })
      setShowRUBSTable(false)
    }
  }, [])

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={handleSubmit}
      requiredMark={false}
      preserve={false}
      colon={false}
      // initialValues={flatValue}
      className="value-add-additional-form"
    >
      <h1>Value Add</h1>

      <Form.Item
        label="1. Do you plan on renovating any units? (suggestion 65-70% of non-renovated units of the property)"
        name="renovation_value_add"
        rules={[{ required: true, message: 'Required' }]}
      >
        <Select options={YES_NO_OPTIONS} onChange={(value) => setShowFirstSubQuestions(value)} />
      </Form.Item>
      {showFirstSubQuestions && (
        <Card>
          <NumberInput label="How many?" name="count" />
          <NumberInput label="How much is it going to cost per unit?" name="cost_per_unit" pricing={true} />
          <NumberInput
            label="What is the rent premium per unit? (how much more will you be charging because of the renovation?)"
            name="rent_premium_per_unit"
            pricing={true}
          />
        </Card>
      )}
      {
        <Form.Item
          rules={[{ required: true, message: 'Required' }]}
          label={
            <>
              {'2. Are there any other value adds (Other Income)?'}
              {<TooltipWithInfo info={VALUE_ADD_INFO[0]['Other Income']} />}
            </>
          }
          name="extra_value_add"
        >
          <Select options={YES_NO_OPTIONS} onChange={(value) => setShowValueAddTable(value)} />
        </Form.Item>
      }
      {showValueAddTable && <ValueAddTable valueAddData={valueAddData} setValueAddData={setValueAddData} />}

      <Form.Item
        rules={[{ required: true, message: 'Required' }]}
        label={
          <>
            {'3. Are there any capital Improvements?'}
            {<TooltipWithInfo info={VALUE_ADD_INFO[0]['Capital Improvements']} />}
          </>
        }
        name="capital_improvements"
      >
        <Select options={YES_NO_OPTIONS} onChange={(value) => setShowCapitalImprovementTable(value)} />
      </Form.Item>
      {showCapitalImprovementTable && (
        <CapitalImprovementTable
          capitalImprovementData={capitalImprovementData}
          onDataUpdate={(data) => setCapitalImprovementData(data)}
        />
      )}
      <Form.Item
        rules={[{ required: true, message: 'Required' }]}
        label={
          <>
            {'4. Are you planning on implementing any RUBS?'}
            {<TooltipWithInfo info={VALUE_ADD_INFO[0]['RUBS']} />}
          </>
        }
        name="rubs"
      >
        <Select options={YES_NO_OPTIONS} onChange={(value) => setShowRUBSTable(value)} />
      </Form.Item>

      {showRUBSTable && (
        <RUBSTable
          implementedRUBSAmount={dealInfo?.details?.incomes[0]?.utility_reimbursement}
          onDataUpdate={(data) => setRUBSData(data)}
          RUBSData={RUBSData}
        />
      )}
      <Button type="primary" className="next-btn btn" htmlType="submit">
        Next
      </Button>
    </Form>
  )
}

export default Step7ValueAdd
