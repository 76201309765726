import { COMMON, DEAL } from '@/constants'
import { getErrorMessage } from '@/utils'
import { Api, TokenApi } from '@/utils/api'


export const resetDealState = () => ({
  type: DEAL.RESET_DEAL,
});

export const getDeal = (id, dealId, load = false) => {
  return async (dispatch) => {
    try {
      const res = await TokenApi.get(`api/v1/users/${id}/deals/${dealId}/`)
      dispatch({
        type: load ? DEAL.DEAL_LOAD : DEAL.DEAL_DETAIL,
        payload: res
      })
      return res
    } catch (err) {
      dispatch({
        type: COMMON.TOP_ALERT,
        payload: { type: 'error', message: getErrorMessage(err) }
      })
    }
  }
}

export const createDeal = (id, values, moveNext) => {
  return async (dispatch, getState) => {
    try {
      const currentState = getState().deal;
      let nextStep = currentState.stepIndex;
      const res = await TokenApi.post(`api/v1/users/${id}/deals/`, values)
      if (moveNext) {
        nextStep++;
      }
      dispatch({
        type: DEAL.DEAL_LOAD, // Dispatch DEAL_DETAIL instead of DEAL_STEP
        payload: {
          ...res, // Pass values to be updated in the reducer
          moveNext, // Include moveNext in the payload
        },
      });


      return res
    } catch (err) {
      console.log(err)
      dispatch({
        type: COMMON.TOP_ALERT,
        payload: { type: 'error', message: getErrorMessage(err) }
      })
    }
  }
}


export const updateDeal = (id, dealId, moveNext, values, navigate) => {
  return async (dispatch, getState) => {
    const isCompleted = values?.details?.sources_other?.status == 'completed' || false
    try {
      const res = await TokenApi.patch(`api/v1/users/${id}/deals/${dealId}/`, values)
      res.moveNext = moveNext
      dispatch({
        type: DEAL.DEAL_LOAD,
        payload: res
      })

      if (isCompleted) {
        navigate(`/dashboard/underwriting/${dealId}`)
      }



    } catch (err) {
      console.log(err);
      dispatch({
        type: COMMON.TOP_ALERT, // Dispatch a common alert action
        payload: { type: 'error', message: getErrorMessage(err) }
      });
    }
  };
};



export const deleteDeal = (id, dealId, load = false) => {
  return async (dispatch) => {
    try {
      const res = await TokenApi.delete(`api/v1/users/${id}/deals/${dealId}/`)
      return res
    } catch (err) {
      dispatch({
        type: COMMON.TOP_ALERT,
        payload: { type: 'error', message: getErrorMessage(err) }
      })
    }
  }
}

export const updateValue = (value) => {
  return async (dispatch) => {
    try {
      const res = await TokenApi.patch(`api/v1/users/${id}/deals/${dealId}/`, values)
      dispatch({
        type: DEAL.DEAL_DETAIL,
        payload: res
      })
    } catch (err) {
      dispatch({
        type: COMMON.TOP_ALERT,
        payload: { type: 'error', message: getErrorMessage(err) }
      })
    }
  }
}

export const getDealList = (id, query = null) => {
  return async (dispatch) => {
    try {
      const res = await TokenApi.get(`api/v1/users/${id}/deals/?query=${query}`)
      dispatch({
        type: DEAL.DEAL_LIST,
        payload: res
      })
      return res
    } catch (err) {
      dispatch({
        type: COMMON.TOP_ALERT,
        payload: { type: 'error', message: getErrorMessage(err) }
      })
    }
  }
}
