import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Form, Button, Space, Row, Col, Input, Divider, InputNumber } from 'antd'
import { MinusOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons'
import { COMMON_RULE } from '@/constants'
import './style.less'

const ValueAdds = ({ data, field, handleChange, handleSave }) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const [visible, setVisible] = useState(false)

  const handleSubmit = async (values) => {
    handleChange(field, values.additionals)
    handleSave()
    setVisible(false)
  }

  const resetValues = () => {
    setVisible(false)
  }

  const items = []

  return (
    <>
      <Button className="mt-1 float-right" type="dashed" onClick={() => setVisible(true)} icon={<EditOutlined />}>
        Edit value adds
      </Button>
      <Modal
        title="Edit Value adds"
        open={visible}
        onCancel={resetValues}
        onOk={form.submit}
        destroyOnClose={false}
        width={520}
        maskClosable={false}
        footer={[
          <Button onClick={() => resetValues()} className="close-btn">
            Cancel
          </Button>,
          <Button type="primary" onClick={() => form.submit()}>
            Save changes
          </Button>
        ]}
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={handleSubmit}
          requiredMark={false}
          preserve={false}
          colon={false}
          className="value-add-additional-form"
          initialValues={{
            additionals: data
          }}
        >
          <Row gutter={[8, 8]} className="row-header">
            <Col span={24} md={11}>
              <p>Description</p>
            </Col>
            <Col span={24} md={5}>
              <p>Quantity</p>
            </Col>
            <Col span={24} md={7}>
              <p>Unit Cost</p>
            </Col>
            <Col span={24} md={1}></Col>
          </Row>
          <Form.List name="additionals">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Row gutter={[8, 8]} key={key}>
                    <Col span={24} md={11}>
                      <Form.Item {...restField} name={[name, 'description']} rules={COMMON_RULE}>
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={24} md={5}>
                      <Form.Item {...restField} name={[name, 'quantity']} rules={COMMON_RULE}>
                        <InputNumber formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} />
                      </Form.Item>
                    </Col>
                    <Col span={24} md={7}>
                      <Form.Item {...restField} name={[name, 'unit_cost']} rules={COMMON_RULE}>
                        <InputNumber
                          prefix="$"
                          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24} md={1}>
                      <Form.Item>
                        <MinusOutlined onClick={() => remove(name)} />
                      </Form.Item>
                    </Col>
                  </Row>
                ))}
                <Form.Item className="mt-1">
                  <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                    Add new
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </Modal>
    </>
  )
}

export default ValueAdds
