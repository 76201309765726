import React, { useEffect, useState } from 'react'
import { useParams, Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Divider, Form, Button, Space, Spin, Tooltip, Progress } from 'antd'
import Step1Property from './steps/Step1Property'
import Step2RentRoll from './steps/Step2RentRoll'
import Step3RentUnitMix from './steps/Step3RentUnitMix'
import useMediaQuery from 'use-media-antd-query'
import DealActions from '@/redux/deal'
import { TokenApi } from '@/utils/api'
import { DEAL } from '@/constants'
import './style.less'
import Step0Intro from './steps/Step0Intro'
import Step4IncomeAndExpenses from './steps/Step4IncomeAndExpenses'
import Step5TransitionModal from './steps/Step5TransitionModal'
import Step6InvestmentStrategy from './steps/Step6InvestmentStrategy'
import Step7ValueAdd from './steps/Step7ValueAdd'
import Step8Expenses from './steps/Step8Expenses'
import Step9LoanFinancing from './steps/Step9LoanFinancing'
import Step10SourcesAndOther from './steps/Step10SourcesAndOther'
import deal from '@/redux/deal'

const CreateNewDeal = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [fileType, setFileType] = useState('pdf')
  const [resource, setResource] = useState()
  const { userInfo, is_authed } = useSelector((state) => state.auth)
  const { dealInfo, stepIndex, dealInput } = useSelector((state) => state.deal)
  const colSize = useMediaQuery()
  const isMobile = ['sm', 'xs'].indexOf(colSize) > -1
  const { dealId } = useParams()
  const [modalVisible, setModalVisible] = useState(true)
  const [transitionModalVisible, setTransitionVisible] = useState(true)
  const navigate = useNavigate()

  useEffect(() => {
    loadData()
    return () => {
      dispatch({ type: DEAL.DEAL_STEP, payload: 0 })
    }
  }, [dealId])

  useEffect(() => {}, [dealInfo])

  const loadData = async () => {
    if (dealId) {
      setLoading(true)
      try {
        const response = await dispatch(DealActions.getDeal(userInfo?.id, dealId, true))
      } catch (error) {
        console.error('Error loading deal:', error)
      } finally {
        setLoading(false)
      }
    } else {
      dispatch({ type: DEAL.DEAL_INIT, payload: 1 })
    }
  }

  const handleNavigateStep = (index) => {
    index = index == 5 ? index - 1 : index
    dispatch({ type: DEAL.DEAL_STEP, payload: index })
    // localStorage.setItem('stepIndex', index)
  }

  const handleStep0 = () => {
    setModalVisible(false)
    handleNavigateStep(1)
  }
  const handleStep5 = () => {
    setTransitionVisible(true)
    handleNavigateStep(6)
  }

  const handleCreate = async (values, moveNext) => {
    values.user = userInfo?.id

    if (dealInfo?.id) {
      dispatch(
        DealActions.updateDeal(userInfo?.id, dealInfo?.id, moveNext, {
          details: { property: values }
        })
      )
    } else {
      dispatch(DealActions.createDeal(userInfo?.id, values, moveNext))
    }
  }

  const handleUpdate = async (values, moveNext = true) => {
    setLoading(true)
    let updatedDealInput = { ...dealInput }

    // Update dealInfo based on the current step
    switch (stepIndex) {
      case 1:

        updatedDealInput.property = values?.property
        // localStorage.setItem('property_details', JSON.stringify(values))
        break
      case 2:
        updatedDealInput.rent_roll = values
        // localStorage.setItem('rent_roll', JSON.stringify(values))

        break
      case 3:
        updatedDealInput.rent_unit_mix = values
        localStorage.setItem('rent_unit_mix', JSON.stringify(values))

        break
      case 4:
        updatedDealInput.income_expenses = values
        // localStorage.setItem('income_expenses', JSON.stringify(values))

        break
      case 6:
        updatedDealInput.investment_strategy = values
        // localStorage.setItem('investment_strategy', JSON.stringify(values))

        break
      case 7:
        if (dealInfo?.details?.property?.plan_on_increasing_income === 'expenses') {
          updatedDealInput.expenses = values
          localStorage.setItem('expenses', JSON.stringify(values))
        } else {
          updatedDealInput.value_add = values
          localStorage.setItem('value_add', JSON.stringify(values))
        }
        break
      case 8:
        if (
          dealInfo?.details?.property?.plan_on_increasing_income === 'value_add' ||
          dealInfo?.details?.property?.plan_on_increasing_income === 'expenses'
        ) {
          updatedDealInput.loan_financing = values
          localStorage.setItem('loan_financing', JSON.stringify(values))
        } else {
          updatedDealInput.expenses = values
          localStorage.setItem('expenses', JSON.stringify(values))
        }
        break

      case 9:
        if (
          dealInfo?.details?.property?.plan_on_increasing_income === 'value_add' ||
          dealInfo?.details?.property?.plan_on_increasing_income === 'expenses'
        ) {
          updatedDealInput.sources_other = values
          localStorage.removeItem('rent_unit_mix')
          localStorage.removeItem('expenses')
          localStorage.removeItem('loan_financing')
          localStorage.removeItem('value_add')
        } else {
          localStorage.setItem('loan_financing', JSON.stringify(values))
          updatedDealInput.loan_financing = values
        }
        break
      case 10:
        updatedDealInput.sources_other = values
        localStorage.removeItem('rent_unit_mix')
        localStorage.removeItem('expenses')
        localStorage.removeItem('loan_financing')
        localStorage.removeItem('value_add')
        break
      default:
        break
    }
    dispatch(
      DealActions.updateDeal(
        userInfo?.id,
        dealInfo?.id,
        moveNext,
        {
          details: updatedDealInput
        },
        navigate
      )
    )

    setLoading(false)
  }

  const params = {
    dealInfo,
    dealInput,
    handleUpdate
  }

  const stepPercent = (stepIndex) => {
    let totalSteps = 10 // Total number of steps
    if (
      dealInfo?.details?.property?.plan_on_increasing_income === 'value_add' ||
      dealInfo?.details?.property?.plan_on_increasing_income === 'expenses'
    ) {
      totalSteps = 9 // Skip Value Add or Expenses step
    }

    return (stepIndex / totalSteps) * 100
  }

  let stepRender = ''

  switch (stepIndex) {
    case 0:
      if (!dealId) {
        stepRender = <Step0Intro modalVisible={modalVisible} handleStep0={handleStep0} />
      } else {
        stepRender = <Step1Property {...params} handleCreate={handleCreate} edit={true} />
      }

      break
    case 1:
      stepRender = <Step1Property {...params} handleCreate={handleCreate} />
      break
    case 2:
      stepRender = <Step2RentRoll {...params} />
      break
    case 3:
      stepRender = <Step3RentUnitMix {...params} />
      break
    case 4:
      stepRender = <Step4IncomeAndExpenses {...params} />
      break
    case 5:
      stepRender = <Step5TransitionModal transitionModalVisible={transitionModalVisible} handleStep5={handleStep5} />
      break
    case 6:
      stepRender = <Step6InvestmentStrategy {...params} />
      break
    case 7:
      stepRender =
        dealInfo?.details?.property?.plan_on_increasing_income === 'expenses' ? (
          <Step8Expenses {...params} />
        ) : (
          <Step7ValueAdd {...params} />
        )
      break
    case 8:
      stepRender =
        dealInfo?.details?.property?.plan_on_increasing_income === 'expenses' ||
        dealInfo?.details?.property?.plan_on_increasing_income === 'value_add' ? (
          <Step9LoanFinancing {...params} />
        ) : (
          <Step8Expenses {...params} />
        )
      break
    case 9:
      if (
        dealInfo?.details?.property?.plan_on_increasing_income === 'expenses' ||
        dealInfo?.details?.property?.plan_on_increasing_income === 'value_add'
      ) {
        stepRender = <Step10SourcesAndOther {...params} />
      } else {
        stepRender = <Step9LoanFinancing {...params} />
      }
      break
    case 10:
      stepRender = <Step10SourcesAndOther {...params} />
      break
    default:
      stepRender = <Step0Intro modalVisible={modalVisible} handleStep0={handleStep0} />
  }

  let edgeCnt = 1
  if (dealInfo) edgeCnt = 2

  return (
    <div className="dashboard-page create-deal-page">
      <Spin spinning={loading}>
        <div className="form-container">
          {stepIndex !== 0 && stepIndex !== 5 && <Progress percent={stepPercent(stepIndex)} showInfo={false} />}
          {stepRender}
        </div>
        <div className="step-navigator"></div>
        {stepIndex !== 0 && stepIndex !== 1 && stepIndex !== 5 && (
          <Button type="primary" className="back-btn btn" onClick={() => handleNavigateStep(stepIndex - 1)}>
            Previous
          </Button>
        )}
      </Spin>
    </div>
  )
}

export default CreateNewDeal
