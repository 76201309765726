import React from 'react';
import { Form, InputNumber } from 'antd';
import TooltipWithInfo from '@/component/TooltipWithInfo'; // Make sure the import path is correct

const NumberInput = ({ name, label, required = true, pricing, rating, tooltipInfo, ...params }) => {
  return (
    <Form.Item 
      label={
        <>
          {label}
          {tooltipInfo && tooltipInfo.info && (
            <TooltipWithInfo info={tooltipInfo.info} />
          )}
        </>
      } 
      name={name} 
      rules={[{ required: required, message: 'Required' }]}
    >
      <InputNumber
        {...params}
        className="number-input"
        addonBefore={pricing ? '$' : ''}
        min={0}
        step={rating ? 0.01 : 1}
        addonAfter={rating ? '%' : ''}
        formatter={(value) =>
          `${rating ? parseFloat(value || 0).toFixed(2) : parseFloat(value || 0).toFixed(0)}`.replace(
            /\B(?=(\d{3})+(?!\d))/g,
            ','
          )
        }
      />
    </Form.Item>
  );
}

export default NumberInput;
