import React, { useEffect, useState } from 'react'
import { Form, Button } from 'antd'
import InlineRow from '@/component/InlineRow'
import NumberInput from '@/component/NumberInput'

const Step4_RentRoll = (props) => {
  const [form] = Form.useForm()
  const { dealInfo, handleUpdate } = props

  const [formData, setFormData] = useState({
    total_units: dealInfo?.details?.rent_roll?.total_units || 0,
    current_occupied_income: dealInfo?.details?.rent_roll?.current_occupied_income || 0,
    current_vacant_income: dealInfo?.details?.rent_roll?.current_vacant_income || 0,
    market_rent: dealInfo?.details?.rent_roll?.market_rent || 0,
    physical_vacancy: dealInfo?.details?.rent_roll?.physical_vacancy || 0,
    below_market_rent_per_unit: dealInfo?.details?.rent_roll?.below_market_rent_per_unit || 0,
    units_below_market_rent: dealInfo?.details?.rent_roll?.units_below_market_rent || 0
  })

  useEffect(() => {
    if (dealInfo?.details?.rent_roll?.market_rent !== formData?.market_rent) {
      setFormData((prevData) => ({
        ...prevData,
        market_rent: dealInfo?.details?.rent_roll?.market_rent
      }))
      form.setFieldsValue({
        market_rent: dealInfo?.details?.rent_roll?.market_rent
      })
    }
  }, [dealInfo, formData.market_rent, form])

  const handleChange = (fieldName, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value
    }))
  }

  const handleSubmit = async () => {
    await handleUpdate({ rent_roll: formData })
  }

  const handleOnBlur = () => {
    handleUpdate({ rent_roll: formData }, false)
  }

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={handleSubmit}
      requiredMark={false}
      preserve={false}
      colon={false}
      initialValues={dealInfo?.details?.rent_roll}
    >
      <InlineRow>
        <NumberInput
          label="Total Unit"
          name="total_units"
          value={formData.total_units}
          onChange={(value) => handleChange('total_units', value)}
        />
        <NumberInput
          label="Current Occupied Income"
          name="current_occupied_income"
          pricing={true}
          value={formData.current_occupied_income}
          onBlur={handleOnBlur}
          onChange={(value) => handleChange('current_occupied_income', value)}
        />
      </InlineRow>
      <InlineRow>
        <NumberInput
          label="Current Vacant Income"
          name="current_vacant_income"
          pricing={true}
          value={formData.current_vacant_income}
          onBlur={handleOnBlur}
          onChange={(value) => handleChange('current_vacant_income', value)}
        />
        <NumberInput
          label="Market Rent"
          name="market_rent"
          pricing={true}
          value={formData.market_rent}
          onChange={(value) => handleChange('market_rent', value)}
        />
      </InlineRow>
      <InlineRow>
        <NumberInput
          label="Physical Vacancy"
          name="physical_vacancy"
          pricing={true}
          value={formData.physical_vacancy}
          onChange={(value) => handleChange('physical_vacancy', value)}
        />
        <NumberInput
          label="Below Market Rent per Unit"
          name="below_market_rent_per_unit"
          pricing={true}
          value={formData.below_market_rent_per_unit}
          onChange={(value) => handleChange('below_market_rent_per_unit', value)}
        />
      </InlineRow>
      <InlineRow>
        <NumberInput
          label="Units Below Market Rent"
          name="units_below_market_rent"
          value={formData.units_below_market_rent}
          onChange={(value) => handleChange('units_below_market_rent', value)}
        />
      </InlineRow>
      <Button type="primary" className="next-btn btn" htmlType="submit">
        Next
      </Button>
    </Form>
  )
}

export default Step4_RentRoll
