import React, { Suspense, Fragment, useMemo } from 'react'
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { ConfigProvider } from 'antd'
import en_US from 'antd/es/locale/en_US'

// Router
import router from '@/routes'

// Basic Components
import Auth from '@/component/AuthWrap'
import TopAlert from '@/component/TopAlert'
import ScrollReset from '@/component/ScrollReset'
import LoadingScreen from '@/component/LoadingScreen'
import NotFound from '@/component/NotFound'
import { AuthLayout, CustomerLayout } from './layouts'
import SubscriptionGuard from './component/SubscriptionGuard'

const history = createBrowserHistory()

const App = () => {
  // const navigate = useNavigate()

  const routers = useMemo(() => {
    return router()
  }, [])

  const renderRoutes = (routes) =>
    routes ? (
      <Suspense fallback={<LoadingScreen />}>
        <Routes>
          {routes.map((route, i) => {
            const Guard = route.guard || Fragment
            const Layout = route.layout || Fragment
            const Element = route.element
            const ElementProps = route.props || {}
            const shouldWrapWithSubscriptionGuard = !['/dashboard/upgrade-plan', '/dashboard/profile','/login','/logout','/signup','/dashboard/faqs','/forgot-password','/reset-password','/dashboard/settings'].includes(route.path)

            return (
              <Route
                key={i}
                path={route.path}
                element={
                  <Guard>
                    {shouldWrapWithSubscriptionGuard ? (
                      <SubscriptionGuard>
                        <Layout>
                          <Element {...ElementProps} />
                        </Layout>
                      </SubscriptionGuard>
                    ) : (
                      <Layout>
                        <Element {...ElementProps} />
                      </Layout>
                    )}
                  </Guard>
                }
              />
            )
          })}
          <Route path="*" element={<NotFound Navigate="/" />} />
        </Routes>
      </Suspense>
    ) : null

  return (
    <ConfigProvider locale={en_US}>
      <TopAlert />
      <Router history={history}>
        <Auth>
          <ScrollReset />
          {renderRoutes(routers)}
        </Auth>
      </Router>
    </ConfigProvider>
  )
}

export default App
