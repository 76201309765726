import React, { useState, useEffect } from 'react'
import { Form, Button, Select, Input, Table, Space, Row, Card, message, notification } from 'antd'
import { UNIT_OPTIONS, RENT_UNIT_MIX_INFO } from '@/constants'
import InlineRow from '@/component/InlineRow'
import NumberInput from '@/component/NumberInput'
import AnswersTable from '@/component/AnswerTable'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import TooltipWithInfo from '@/component/TooltipWithInfo'
import TextInput from '@/component/TextInput'

export default function Step3RentUnitMix(props) {
  const [form] = Form.useForm()
  const [unitTypeCount, setUnitTypeCount] = useState(0)
  const [unitData, setUnitData] = useState([])
  const [answerData, setAnswerData] = useState({
    0: [],
    1: [],
    2: [],
    3: []
  })
  const [currentQuestion, setCurrentQuestion] = useState(0)
  const [initialValues, setInitialValues] = useState({})
  const [loading, setLoading] = useState(true)
  const [showNext, setShowNext] = useState(false)

  const { dealInput, handleUpdate } = props

  useEffect(() => {
    const fetchInitialValues = async () => {
      const storedValues = dealInput.rent_unit_mix
      if (storedValues) {
        setInitialValues(storedValues)
        form.setFieldsValue(storedValues)
        setLoading(false)
        updateAnswerData(storedValues)
      }
      if (localStorage.getItem('rent_unit_mix')) {
        const localValues = JSON.parse(localStorage.getItem('rent_unit_mix'))
        setInitialValues(localValues)
        form.setFieldsValue(localValues)
        setLoading(false)
        updateAnswerData(localValues)
      }
    }
    fetchInitialValues()
  }, [dealInput, form])

  const updateAnswerData = (values) => {
    const newAnswerData = {
      0: values.number_of_units_on_each_unit_type || [],
      1: values.total_number_of_units || [],
      2: values.square_footage_for_each_unit_type || [],
      3: values.average_current_rent_for_each_floor_plan || [],
      4: values.market_rate_you_plan_on_charging || []
    }
    setAnswerData(newAnswerData)
  }

  useEffect(() => {
    if (answerData[2].length > 0) {
      setShowNext(true)
    }
  }, [answerData, currentQuestion])

  useEffect(() => {
    const data = []
    for (let i = 0; i < unitTypeCount; i++) {
      data.push({
        key: i,
        unitType: `${i + 1}`,
        units: ''
      })
    }
    setUnitData(data)
  }, [unitTypeCount])

  const handleSubmit = async (values) => {
    const formattedAnswers = {}
    slugs.forEach((slug, index) => {
      formattedAnswers[slug] = answerData[index]
    })
    if (formattedAnswers[slugs[4]].length < 1) {
      notification.error({
        message: 'Answer all the questions before proceeding to the next step',
        icon: <ExclamationCircleOutlined style={{ color: '#ff4d4f' }} />,
        duration: 3
      })
      return
    }
    await handleUpdate(formattedAnswers)
  }

  const handleUnitTypeChange = (value) => {
    if (value > 50 || value < 0) {
      setUnitTypeCount(1)
      return
    }
    setUnitTypeCount(parseInt(value))
  }

  const handleNextQuestion = () => {
    const allUnitsInputted = unitData.every((item) => item.units !== '')
    if (!allUnitsInputted) {
      notification.error({
        message: 'Please input all units before proceeding to the next question.',
        icon: <ExclamationCircleOutlined style={{ color: '#ff4d4f' }} />,
        duration: 2
      })
      return
    }
    const newData = unitData.map((item) => ({ ...item, units: '' }))
    setUnitData(newData)
    setCurrentQuestion(currentQuestion + 1)
  }

  const handlePreviousQuestion = () => {
    setCurrentQuestion(currentQuestion - 1)
  }

  const handleChange = (newValue, recordKey) => {
    const newData = unitData.map((item) => {
      if (item.key === recordKey) {
        return { ...item, units: newValue }
      }
      return item
    })
    setUnitData(newData)
    const newAnswerData = { ...answerData }
    newAnswerData[currentQuestion] = [...newData]
    setAnswerData(newAnswerData)
  }

  const questions = [
    '1. How many Units on each of these unit types?',
    '2. How many total number of Units',
    '3. What is the square footage for each of these unit types?',
    '4. What is the average current rent for each of those floor plans?',
    '5. What is the market rate you plan on charging?'
  ]
  const slugs = [
    'number_of_units_on_each_unit_type',
    'total_number_of_units',
    'square_footage_for_each_unit_type',
    'average_current_rent_for_each_floor_plan',
    'market_rate_you_plan_on_charging'
  ]

  if (loading) {
    return null
  }
  return (
    <div className="">
      <Form
        layout="vertical"
        form={form}
        onFinish={handleSubmit}
        requiredMark={false}
        preserve={false}
        colon={false}
        initialValues={initialValues}
      >
        <h1>Rent Unit Mix</h1>
        
        {/* {answerData[0].length && ( */}
          <InlineRow>
            <Form.Item
              label={
                <>
                  {'How many unit types do you have?'}
                  <TooltipWithInfo info={RENT_UNIT_MIX_INFO[0]['Unit Types']} />
                </>
              }
              name="unit_type_count"
              rules={[{ required: true, message: 'Required' }]}
            >
              <Select
                options={UNIT_OPTIONS}
                values={unitTypeCount}
                placeholder="Select no. of units"
                onChange={handleUnitTypeChange}
              />
            </Form.Item>
          </InlineRow>
        {/* )} */}

        {unitTypeCount > 0 && (
          <div className="question-section">
            <Space size={5} direction="vertical" style={{ width: '100%' }}>
              <span className="question">{questions[currentQuestion]}</span>
              <Row gutter={2}>
                {currentQuestion > 0 && (
                  <Button type="primary" className="question-btn" onClick={handlePreviousQuestion}>
                    Previous Question
                  </Button>
                )}
                {currentQuestion < 4 && (
                  <Button type="primary" className="question-btn" onClick={handleNextQuestion}>
                    Next Question
                  </Button>
                )}
              </Row>
            </Space>

            <Table
              columns={[
                {
                  title: '#',
                  dataIndex: 'unitType',
                  key: 'unitType'
                },
                {
                  title: questions[currentQuestion].includes('How many Units on each of these unit types?') ? (
                    <div>
                      Unit Type
                      <TooltipWithInfo info={RENT_UNIT_MIX_INFO[0]['Units']} />
                    </div>
                  ) : questions[currentQuestion].includes('How many total number of Units') ? (
                    <div>
                      Total # of Units
                      <TooltipWithInfo info={RENT_UNIT_MIX_INFO[0]['total units']} />
                    </div>
                  )
                   : questions[currentQuestion].includes('What is the square footage for each of these unit types') ? (
                    <div>
                      Unit SF
                      <TooltipWithInfo info={RENT_UNIT_MIX_INFO[0]['Unit SF']} />
                    </div>
                  ) : questions[currentQuestion].includes(
                      'What is the average current rent for each of those floor plans?'
                    ) ? (
                    <div>
                      Seller's Monthly Rent
                      <TooltipWithInfo info={RENT_UNIT_MIX_INFO[0]["Seller's Monthly Rent"]} />
                    </div>
                  ) : (
                    <div>
                      Market Monthly Rent
                      <TooltipWithInfo info={RENT_UNIT_MIX_INFO[0]['Market Monthly Rent']} />
                    </div>
                  ),
                  dataIndex: questions[currentQuestion].includes('How many Units on each of these unit types?')
                    ? 'units'
                    : questions[currentQuestion].includes('How many total number of Units') ? 'total_number_of_units'
                    : questions[currentQuestion].includes('What is the square footage for each of these unit types') ? 'squareFootage'
                    : questions[currentQuestion].includes('What is the average current rent for each of those floor plans?') ? 'currentRent'
                    : 'marketRate',
                  key: questions[currentQuestion].includes('How many Units on each of these unit types?')
                    ? 'units'
                    : questions[currentQuestion].includes('How many total number of Units') ? 'total_number_of_units'
                    : questions[currentQuestion].includes('What is the square footage for each of these unit types')
                      ? 'squareFootage'
                      : questions[currentQuestion].includes(
                            'What is the average current rent for each of those floor plans?'
                          )
                        ? 'currentRent'
                        : 'marketRate',
                  render: (text, record) => {
                    return (
                      <Form.Item name={`units_${record.key}`} initialValue={''}>
                        {questions[currentQuestion].includes('How many Units on each of these unit types?') ? (
                          <Input
                            required
                            value={text || ''}
                            placeholder='1BR/1BA, C , 1BR/1BA FR'
                            onChange={(e) => handleChange(e.target.value, record.key)}
                          />
                        ) : (
                          <NumberInput
                            required
                            pricing={(currentQuestion == 3 || currentQuestion == 4 )? true:false}
                            value={text}
                            onChange={(newValue) => handleChange(newValue, record.key)}
                          />
                        )}
                      </Form.Item>
                    )
                  }
                }
              ]}
              dataSource={unitData}
              pagination={false}
              bordered
              size="small"
              rowClassName="editable-row"
            />
          </div>
        )}

        <Button type="primary" className="next-btn btn" htmlType="submit">
          Next
        </Button>
      </Form>
      {Object.keys(answerData[0]).length > 0 && (
        <Card title="OverView Rent Unit Mix">
          <AnswersTable questions={questions} answerData={answerData} unitCount={unitTypeCount} />
        </Card>
      )}
    </div>
  )
}
