import { useState } from 'react'
import { Spin } from 'antd';


const override = {
  margin: '20px 0',
  marginBottom: '20px',
  padding: '30px 50px',
  textAlign: 'center',
  borderRadius: '4px',
  display:'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height:'100vh',



}

function LoadingScreen() {
  let [loading, setLoading] = useState(true)
  let [color, setColor] = useState('#ffffff')

  return (
    <div style={override}>
    <Spin size="large" />
  </div>
  )
}

export default LoadingScreen
