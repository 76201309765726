import { AUTH, SUBSCRIPTIONS } from '@/constants'

const initState = {
  is_authed: false,
  userInfo: null,
  userRole: 'customer',
  openAuthPopup: false,
  invoiceLists:null
}

const AuthReducer = (state = initState, action) => {
  const { type, payload } = action

  switch (type) {
    case AUTH.SIGNED_IN:
      return {
        ...state,
        is_authed: true
      }

    case AUTH.SIGNED_OUT:
      return {
        ...state,
        is_authed: false,
        userInfo: null
      }

    case AUTH.USER_INFO:
      return {
        ...state,
        userInfo: Object.assign({}, payload)
      }

    case AUTH.OTP_STATUS:
      return {
        ...state,
        userInfo: { ...state.userInfo, two_factor_enable: payload }
      }
    
    case SUBSCRIPTIONS.LIST:
      return{
        ...state,
        invoiceLists: Object.assign({}, payload)
      }

    default:
      return state
  }
}

export default AuthReducer
