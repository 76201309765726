import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Navigate } from 'react-router-dom'
import { Button, Card, Col, Input, Row, Form } from 'antd'
import useMediaQuery from 'use-media-antd-query'
import AuthActions from '@/redux/auth'
import { COMMON_RULE } from '@/constants'

import '../style.less'

const Signin = (props) => {
  const dispatch = useDispatch()

  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const colSize = useMediaQuery()
  const isMobile = ['sm', 'xs'].indexOf(colSize) > -1
  const auth = useSelector((state) => state.auth)

  const handleSubmit = async (values) => {
    setLoading(true)
    try {
      const res = await dispatch(AuthActions.login(values))
      if (res.key) {
        await dispatch(AuthActions.getCurrentUser())
        props.history('/dashboard')
      }
    } catch (err) {
      setLoading(false)
    }
  }

  if (auth.is_authed) {
    return <Navigate to="/dashboard" />
  }

  return (
    <div className="page-container">
      <div className="auth-page">
        <img src="/images/logo-main.png" alt="logo" />
        <h1 className="mb-50">Sign in to your account</h1>
        <Form layout="vertical" form={form} onFinish={handleSubmit} requiredMark={false} preserve={false} colon={false}>
          <Form.Item label="Email" name="email" rules={COMMON_RULE}>
            <Input type="email" />
          </Form.Item>
          <Form.Item label="Password" name="password" rules={COMMON_RULE} className="mb-20">
            <Input.Password />
          </Form.Item>
          <Button className="submit-btn" htmlType="submit" loading={loading}>
            Sign in
          </Button>
          <p className="text-center">
            Not a member? <Link to="/signup">Create account</Link> or{' '}
            <Link to="/forgot-password">Forgot Password?</Link>
          </p>
        </Form>
      </div>
    </div>
  )
}

export default Signin
