import React, { useEffect, useState } from 'react'
import { Form, Button, Select, Statistic, InputNumber } from 'antd'
import InlineRow from '@/component/InlineRow'
import NumberInput from '@/component/NumberInput'
import { RENT_ROLL_OPTIONS, RENT_ROLL_INFO } from '@/constants'
import TooltipWithInfo from '@/component/TooltipWithInfo'
const Step2RentRoll = (props) => {
  const [form] = Form.useForm()
  const { handleUpdate, dealInfo } = props
  const [selectedOption, setSelectedOption] = useState(null)
  const [formData, setFormData] = useState({
    current_occupied_income: dealInfo?.details?.rent_roll?.current_occupied_income || 0,
    current_vacant_income: dealInfo?.details?.rent_roll?.current_vacant_income || 0,
    market_rent: dealInfo?.details?.rent_roll?.market_rent || 0,
    number_of_vacant_units: dealInfo?.details?.rent_roll?.number_of_vacant_units || 0,
    below_market_rent_per_unit: dealInfo?.details?.rent_roll?.below_market_rent_per_unit || 0,
    units_below_market_rent: dealInfo?.details?.rent_roll?.units_below_market_rent || 0
  })

  const handleChange = (fieldName, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value
    }))
  }

  const handleSelectChange = (value) => {
    setSelectedOption(value)
  }

  const handleSubmit = async (values) => {
    const {
      current_vacant_income,
      current_occupied_income,
      occupancy_rate,
      number_of_vacant_units,
      below_market_rent_per_unit,
      units_below_market_rent
    } = values
    let calculatedGrossScheduledIncome = 0

    // gross scheduled income and physical occupancy calculation
    if (selectedOption === 'current_vacant_income') {
      calculatedGrossScheduledIncome = (current_vacant_income + current_occupied_income) * 12
    } else if (selectedOption === 'occupancy_rate') {
      if (occupancy_rate > 0) {
        calculatedGrossScheduledIncome = (current_occupied_income / (occupancy_rate / 100)) * 12
      } else {
        calculatedGrossScheduledIncome = 0
      }
    } else {
      const numberOfUnits = dealInfo?.details?.property?.number_of_units || 0
      if (numberOfUnits > 0) {
        calculatedGrossScheduledIncome = (current_occupied_income / (1 - number_of_vacant_units / numberOfUnits)) * 12
      } else {
        calculatedGrossScheduledIncome = 0
      }
    }
    const calculatedPhysicalVacancy = calculatedGrossScheduledIncome - current_occupied_income * 12

    const updatedFormData = {
      current_vacant_income,
      current_occupied_income,
      occupancy_rate,
      number_of_vacant_units,
      gross_scheduled_income: calculatedGrossScheduledIncome,
      physical_vacancy: calculatedPhysicalVacancy,
      below_market_rent_per_unit,
      units_below_market_rent
    }

    await handleUpdate(updatedFormData)
  }

  useEffect(() => {
    if (dealInfo?.details?.rent_roll?.current_vacant_income) {
      handleSelectChange('current_vacant_income')
      form.setFieldsValue({
        selected_option: 'current_vacant_income',
        number_of_vacant_units: null,
        occupancy_rate: null
      })
    } else if (dealInfo?.details?.rent_roll?.number_of_vacant_units) {
      handleSelectChange('number_of_vacant_units')
      form.setFieldsValue({
        selected_option: 'number_of_vacant_units',
        current_vacant_income: null,
        occupancy_rate: null
      })
    } else if (dealInfo?.details?.rent_roll?.occupancy_rate) {
      handleSelectChange('occupancy_rate')
      form.setFieldsValue({
        selected_option: 'occupancy_rate',
        number_of_vacant_units: null,
        current_vacant_income: null
      })
    }
  }, [])

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={handleSubmit}
      requiredMark={false}
      preserve={false}
      colon={false}
      initialValues={dealInfo?.details?.rent_roll}
    >
      <h1>Rent Roll</h1>
      <InlineRow>
        <NumberInput
          tooltipInfo={{ info: RENT_ROLL_INFO[0]['Current Occupied Income'] }}
          label="What is the current occupied monthly income?"
          name="current_occupied_income"
          value={formData.current_occupied_income}
          pricing={true}
          onChange={(value) => handleChange('current_occupied_income', value)}
        />
        <Form.Item
          rules={[{ required: true, message: 'Required' }]}
          label="Which one of the following do you have?"
          name="selected_option"
        >
          <Select options={RENT_ROLL_OPTIONS} onChange={(value) => handleSelectChange(value)} value={selectedOption} />
        </Form.Item>
      </InlineRow>

      {selectedOption === 'current_vacant_income' && (
        <NumberInput
          label="Current Vacant Monthly Income"
          tooltipInfo={{ info: RENT_ROLL_INFO[0]['Current Vacant Income'] }}
          name="current_vacant_income"
          value={formData.current_vacant_income}
          pricing={true}
          onChange={(value) => handleChange('current_vacant_income', value)}
        />
      )}
      {selectedOption === 'number_of_vacant_units' && (
        <NumberInput
          label="# of Vacant Units"
          name="number_of_vacant_units"
          tooltipInfo={{ info: RENT_ROLL_INFO[0]['Number of Vacant Units'] }}
          value={formData.number_of_vacant_units}
          onChange={(value) => handleChange('number_of_vacant_units', value)}
        />
      )}
      {selectedOption === 'occupancy_rate' && (
        <Form.Item
          name="occupancy_rate"
          label={
            <>
              {'Occupancy %'}
              {<TooltipWithInfo info={RENT_ROLL_INFO[0]['Occupancy %']} />}
            </>
          }
        >
          <InputNumber
            value={formData.occupancy_rate}
            onChange={(value) => handleChange('occupancy_rate', value)}
            addonAfter="%"
          />
        </Form.Item>
      )}

      <InlineRow>
        <NumberInput
          label="Average Below Market Rent:"
          name="below_market_rent_per_unit"
          value={formData.below_market_rent_per_unit}
          pricing={true}
          onChange={(value) => handleChange('below_market_rent_per_unit', value)}
        />
        <NumberInput
          label="# of Units Below Market Rent"
          name="units_below_market_rent"
          value={formData.units_below_market_rent}
          onChange={(value) => handleChange('units_below_market_rent', value)}
        />
        {/* <Statistic title="Average Below Market Rent:" value="$300" precision={0} />
        <Statistic title="# of Units Below Market Rent" value="25" precision={0} /> */}
      </InlineRow>
      <Button type="primary" className="next-btn btn" htmlType="submit">
        Next
      </Button>
    </Form>
  )
}

export default Step2RentRoll
