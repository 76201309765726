import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { Typography, Space, Card, Button } from 'antd'
import './style.less'

function SubscriptionGuard({ children }) {
  const { userInfo, is_authed } = useSelector((state) => state.auth)
  const navigate = useNavigate()
  const location = useLocation();
  const pathname = location ? location.pathname : '';
  const { membership_plan, membership_avail } = userInfo || {};
  const [searchParams] = useSearchParams();
  const feature = searchParams.get('feature')
  const isMarketResearchPage = pathname?.indexOf('market-research') > -1
  //  const isRestricted = !membership_avail || !membership_plan || (membership_plan === "free" && feature && feature !== "summary") || (["free", "standard"].indexOf(membership_plan) > -1 && isMarketResearchPage)
  // const isRestricted = false
  const isRestricted = !membership_avail


  if (isRestricted) {
    return (
      <div className="subscription-guard">
        <Card bordered={false}>
          <img src="/images/logo-main.png" className="logo" alt="logo" />
          {
          userInfo?.square_subscription_id || userInfo?.subscription_ended?
          <h4>You need to upgrade your plan for this feature.</h4>
        :
        <h4>Enter your credit card details to access a 14-day free trial.</h4>

        }
          <Space>
            <Button className="btn" type="primary" onClick={() => navigate('/dashboard/upgrade-plan')}>
            {userInfo?.square_subscription_id  || userInfo?.subscription_ended ?'Upgrade Plan':'Start Free Trial'}
            </Button>
          </Space>
        </Card>
      </div>
    )
  }

  return children
}

export default SubscriptionGuard
