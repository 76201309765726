import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Navigate, useNavigate, useSearchParams } from 'react-router-dom'
import { Button, Card, Col, Input, Row, Form } from 'antd'
import useMediaQuery from 'use-media-antd-query'
import AuthActions from '@/redux/auth'
import { COMMON, COMMON_RULE } from '@/constants'

import '../style.less'

const ResetPassword = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams();

  const userId = searchParams.get('uid') || null

  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const colSize = useMediaQuery()
  const isMobile = ['sm', 'xs'].indexOf(colSize) > -1
  const auth = useSelector((state) => state.auth)

  const handleSubmit = async (values) => {
    if (values.password !== values.confirmPassword) {
      dispatch({
        type: COMMON.TOP_ALERT,
        payload: { type: 'error', message: 'The two password fields didn’t match.' }
      })
      return
    }
    setLoading(true)
    try {
      const res = await dispatch(
        AuthActions.resetPwdComplete({
          uid: userId,
          password: values.password
        })
      )
      navigate('/login')
    } catch (err) {}
    setLoading(false)
  }

  if (auth.is_authed) {
    return <Navigate to="/dashboard" />
  }

  return (
    <div className="page-container">
      <div className="auth-page">
        <img src="/images/logo-main.png" alt="logo" />
        <h1 className="mb-50">Reset password</h1>
        <Form layout="vertical" form={form} onFinish={handleSubmit} requiredMark={false} preserve={false} colon={false}>
          <Form.Item label="New password" name="password" rules={COMMON_RULE}>
            <Input.Password className="poll-input" />
          </Form.Item>
          <Form.Item label="Confirm password" name="confirmPassword" rules={COMMON_RULE}>
            <Input.Password className="poll-input" />
          </Form.Item>
          <Button className="submit-btn" htmlType="submit" loading={loading}>
            Reset password
          </Button>
          <p className="text-center">
            Have an account? <Link to="/login">Log in to account</Link>
          </p>
        </Form>
      </div>
    </div>
  )
}

export default ResetPassword
