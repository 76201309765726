import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { Row, Col, Divider, Form, Button, Space, Input, Select, Radio, DatePicker, Image } from 'antd'
import Upload from 'rc-upload'
import InlineRow from '@/component/InlineRow'
import NumberInput from '@/component/NumberInput'
import TextInput from '@/component/TextInput'
import AuthActions from '@/redux/auth'
import { TokenApi } from '@/utils/api'
import { GENDER_OPTIONS } from '@/constants'
import moment from 'moment'
import Img from '@/component/Img'
import './style.less'

const Profile = () => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const { userInfo, is_authed } = useSelector((state) => state.auth)
  const [photo, setPhoto] = useState(userInfo?.profile_photo)

  const handleSubmit = async (values) => {
    try {
      setLoading(true)
      values.photo = photo?.id || null
      if (values.birthday) values.birthday = values.birthday.format('YYYY-MM-DD')

      await dispatch(AuthActions.updateUser(userInfo.id, values))
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  const handleUpload = async ({ onError, onSuccess, file }) => {
  if (!file.type.startsWith('image/')) {
    onError('Only image files are allowed.');
    return;
  }

  if (file.size < 100) {
    onError('File size is too small.');
    return;
  }
  
    const formData = new FormData()
    formData.append('file', file)
    formData.append('name', file.name)
    formData.append('category', 'image')
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data' 
      }
    }
    const res = await TokenApi.post(`api/v1/resources/`, formData,config)
    res.new = true
    setPhoto(res)
    window.location.reload();
  }

  return (
    <div className="dashboard-page profile-page">
      <Row gutter={[16]}>
        <Col span={24} md={24}>
          <Form
            layout="vertical"
            form={form}
            onFinish={handleSubmit}
            requiredMark={false}
            preserve={false}
            colon={false}
            initialValues={{ ...userInfo, birthday: userInfo?.birthday && moment(userInfo.birthday) }}
          >
            <div className="profile-photo">
              <Upload customRequest={handleUpload} multiple={false}>
                <Img src={photo ? photo.file : '/images/user.svg'} />
              </Upload>
            </div>
            <InlineRow className="responsive-layout" >
              <TextInput label="First name" name="first_name" className="responsive-input" required={false} />
              <TextInput label="Last name" name="last_name" className="responsive-input" required={false} />
            </InlineRow>
            <InlineRow className="responsive-layout">
              <TextInput label="Location" name="location" className="responsive-input" required={false} />
              <TextInput label="Phone number" name="phone" className="responsive-input" required={false} />
            </InlineRow>
            <InlineRow className="responsive-layout">
              <Form.Item className="responsive-input" label="Date of Birth" name="birthday">
                <DatePicker format="YYYY-MM-DD" />
              </Form.Item>
              <TextInput className="responsive-input" label="Email" name="email" readOnly />
            </InlineRow >
            <Form.Item label="Gender" name="gender">
              <Radio.Group options={GENDER_OPTIONS}></Radio.Group>
            </Form.Item>
            <Form.Item label="Bio" name="bio">
              <Input.TextArea rows={4} />
            </Form.Item>
            <Button type="primary" className="btn" htmlType="submit" loading={loading}>
              Save
            </Button>
          </Form>
        </Col>
      </Row>
    </div>
  )
}

export default Profile
