import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useLocation, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  Row,
  Col,
  Divider,
  Card,
  Statistic,
  Spin,
  Tabs,
  Input,
  Form,
  Select,
  InputNumber,
  Button,
  DatePicker
} from 'antd'
import { CreditCardOutlined, SearchOutlined } from '@ant-design/icons'
import SubscriptionGuard from '@/component/SubscriptionGuard'
import CommonActions from '@/redux/common'
import RentCast from './rentcast'
import MarektTrend from './market_trend'
import Walkscore from './walkscore'
import { COMMON_RULE, PROPERTY_TYPE_OPTIONS, BEDROOM_OPTIONS, BATHROOM_OPTIONS, DISTANCE_OPTIONS } from '@/constants'
import './style.less'

const MarketResearch = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams();

  const [loading, setLoading] = useState(false)
  const [activeTab, setActiveTab] = useState('rentcast')
  const [form] = Form.useForm()
  const { rentcastInfo, marketTrendsInfo, walkscoreInfo } = useSelector((state) => state.common)

  const getParams = () => {
    let feature = searchParams.get('feature') || 'rentcast'
    return {
      feature
    }
  }

  useEffect(() => {
    const { feature } = getParams()
    setActiveTab(feature)
  }, [searchParams])

  const onChangeTab = (key) => {
    navigate(`/dashboard/market-research?feature=${key}`)
  }

  const handleSubmit = async (values) => {
    setLoading(true)
    if (values.datetime_ini) {
      values.datetime_ini = values.datetime_ini.format('YYYY-MM-DD')
      values.datetime_end = values.datetime_end.format('YYYY-MM-DD')
    }
    try {
      switch (activeTab) {
        case 'market-trend':
          await dispatch(CommonActions.getMarketTrendsData(values))
          break
        case 'walkscore':
          await dispatch(CommonActions.getWalkscoreData(values))
          break
        default:
          await dispatch(CommonActions.getRentcastData(values))
      }
    } catch (err) {
      console.log(err)
    }
    setLoading(false)
  }

  const params = {
    rentcastInfo,
    marketTrendsInfo,
    walkscoreInfo,
    handleSubmit
  }

  const tabItems = [
    {
      key: 'rentcast',
      label: 'Rent Estimate & Comparable',
      children: <RentCast {...params} />
    },
    {
      key: 'market-trend',
      label: 'Market Trends and Insight',
      children: <MarektTrend {...params} />
    },
    {
      key: 'walkscore',
      label: 'Walk Score and Crime Report',
      children: <Walkscore {...params} />
    }
  ]

  return (
    <SubscriptionGuard>
      <div className="dashboard-page market-research-page">
        <Spin spinning={loading}>
          <Form
            layout="vertical"
            form={form}
            onFinish={handleSubmit}
            requiredMark={false}
            preserve={false}
            colon={false}
          >
            <Row gutter={[16, 16]}>
              <Col span={24} md={7}>
                <Form.Item name="address" rules={COMMON_RULE}>
                  <Input placeholder="Search by property address" />
                </Form.Item>
              </Col>
              {activeTab === 'rentcast' && (
                <>
                  <Col span={24} md={4} >
                    <Form.Item name="property_type">
                      <Select options={PROPERTY_TYPE_OPTIONS} placeholder="Property type" showSearch allowClear />
                    </Form.Item>
                  </Col>
                  <Col span={24} md={5} xl={3}>
                    <Form.Item name="bedrooms">
                      <Select options={BEDROOM_OPTIONS} placeholder="Bedrooms" showSearch allowClear />
                    </Form.Item>
                  </Col>
                  <Col span={24} md={5} xl={3}>
                    <Form.Item name="bathrooms">
                      <Select options={BATHROOM_OPTIONS} placeholder="Bathrooms" showSearch allowClear />
                    </Form.Item>
                  </Col>
                  <Col span={24} md={5} xl={3}>
                    <Form.Item name="square_foot">
                      <InputNumber style={{ width: '100%' }} placeholder="Square foot" />
                    </Form.Item>
                  </Col>
                </>
              )}
              {/* activeTab === "walkscore" && <>
                <Col span={24} md={5}>
                  <Form.Item name="datetime_ini" rules={COMMON_RULE}>
                    <DatePicker placeholder="From Date" format="YYYY-MM-DD" />
                  </Form.Item>
                </Col>
                <Col span={24} md={5}>
                  <Form.Item name="datetime_end" rules={COMMON_RULE}>
                    <DatePicker placeholder="To Date" format="YYYY-MM-DD" />
                  </Form.Item>
                </Col>
                <Col span={24} md={3}>
                  <Form.Item name="distance" rules={COMMON_RULE}>
                    <Select options={DISTANCE_OPTIONS} placeholder="Distance"  showSearch allowClear />
                  </Form.Item>
                </Col>
              </> */}
              <Col span={24} md={['rentcast'].indexOf(activeTab) > -1 ? 12 : 17} xl={['rentcast'].indexOf(activeTab) > -1 ? 4 : 17}>
                <Button type="primary" className="btn submit-btn" htmlType="submit" icon={<SearchOutlined />}>
                  Search
                </Button>
              </Col>
            </Row>
          </Form>
          <Divider />
          <Tabs activeKey={activeTab} items={tabItems} onChange={onChangeTab} className="main-tab" />
        </Spin>
      </div>
    </SubscriptionGuard>
  )
}

export default MarketResearch
