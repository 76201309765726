import React from 'react'
import { Row, Col, Divider, Statistic, Card } from 'antd'
import InlineRow from '@/component/InlineRow'

const SummaryTab = (props) => {
  const { userInfo, dealInfo } = props

  if (!dealInfo) return null

  const property = dealInfo?.details?.property
  const financing = dealInfo?.financials?.summary?.financing
  const returns_splits = dealInfo?.financials?.summary?.returns_splits
  const uses_of_funds = dealInfo?.financials?.summary?.uses_of_funds
  const sources_of_funds = dealInfo?.financials?.summary?.sources_of_funds
  const financials = dealInfo?.financials?.summary?.financials

  return (
    <div className="tab-content summary-tab">
      <Row gutter={[16, 16]}>
        <Col span={24} md={8}>
          <Card title="Property Information">
            <img src="/images/property.png" alt="Property" />
            <h3>{property?.name}</h3>
            <Statistic className="mb-1" title="Address:" value={property?.address} />
            <InlineRow className="mb-1 responsive-grid">
              <Statistic title="Number of Units:" value={property?.number_of_units} />
              <Statistic title="Purchase Price:" value={property?.purchase_price} prefix="$" />
            </InlineRow>
            <InlineRow className="responsive-grid">
              <Statistic title="Asking Price:" value={property?.asking_price_per_unit} prefix="$" precision={0} />
              <Statistic title="Year Built:" value={`${property?.year_built} `} />
            </InlineRow>
          </Card>
        </Col>
        <Col span={24} md={8}>
          <Card title="Investor Returns & Splits">
            <h3>Investor Returns</h3>
            <Statistic
              className="mb-1"
              title="Cash on Cash Return(Year 1):"
              value={returns_splits?.cash_on_cash_return_year1}
              suffix="%"
              precision={2}
            />
            <Statistic
              className="mb-1"
              title="Cash on Cash Return(Average):"
              value={returns_splits?.cash_on_cash_return_average}
              suffix="%"
              precision={2}
            />
            <Statistic
              className="mb-1"
              title="Average Annual Return:"
              value={returns_splits?.average_annual_return}
              suffix="%"
              precision={2}
            />
            <Statistic
              className="mb-1"
              title="Total Revenue:"
              value={returns_splits?.total_returns}
              suffix="%"
              precision={2}
            />
            <Statistic title="IRR:" value={returns_splits?.irr} suffix="%" precision={2} />
            <Divider />
            <h3>Splits</h3>
            <Statistic title="Equity Partners Split:" value={returns_splits?.equity_partners_split} suffix="%" />
            <Statistic title="Manager Equity:" value={returns_splits?.manager_equity} suffix="%" />
            <Statistic title="Preferred Rate:" value={returns_splits?.preferred_rate} suffix="%" />
          </Card>
        </Col>
        <Col span={24} md={8}>
          <Card title="Uses of Funds">
            <h3>Closing Cost</h3>
            <InlineRow className="mb-1 responsive-grid">
              <Statistic title="Closing Costs:" value={uses_of_funds?.closing_costs} prefix="$" precision={0} />
              <Statistic title="Loan Point Costs:" value={uses_of_funds?.loan_cost} prefix="$" precision={0} />
            </InlineRow>
            <InlineRow className="mb-1 responsive-grid">
              <Statistic title="Acquisition Fee:" value={uses_of_funds?.acquisition_amount} prefix="$" precision={0} />
              <Statistic title="Guarantor Fee:" value={uses_of_funds?.guarantor_amount} prefix="$" precision={0} />
            </InlineRow>
            <Statistic
              title="Total Closing Cost:"
              value={uses_of_funds?.total_closing_costs}
              prefix="$"
              precision={0}
            />
            <Divider />
            <h3>Acquisition Cost</h3>
            <Statistic
              className="mb-1"
              title="Capital Improvements:"
              value={uses_of_funds?.capital_improvements}
              prefix="$"
              precision={0}
            />
            <Statistic
              className="mb-1"
              title="Operating Capital Reserves:"
              value={uses_of_funds?.capital_reserves}
              prefix="$"
              precision={0}
            />
            <Statistic
              className="mb-1"
              title="1st Years Insurance:"
              value={uses_of_funds?.first_years_insurance}
              prefix="$"
              precision={0}
            />
            <Statistic
              className="mb-1"
              title="Property Tax Residual (Monthly):"
              value={uses_of_funds?.property_tax_per_month}
              prefix="$"
              precision={0}
            />
            <Statistic
              className="mb-1"
              title="Utility Deposit:"
              value={uses_of_funds?.contingency}
              prefix="$"
              precision={0}
            />
            <Statistic className="mb-1" title="Other:" value={uses_of_funds?.other} prefix="$" precision={0} />
            <Statistic
              className="mb-1"
              title="Total Acquisition Cost:"
              value={uses_of_funds?.total_acquisition_costs}
              prefix="$"
              precision={0}
            />
            <Statistic
              title="Total Closing & Acquisition Costs:"
              value={uses_of_funds?.total_closing_acquisition_costs}
              prefix="$"
              precision={0}
            />
          </Card>
        </Col>
        <Col span={24} md={8}>
          <Card title="Financing">
            <Statistic className="mb-1" title="Hold Time:" value={financing?.hold_time} />
            <Statistic
              className="mb-1"
              title="Down Payment:"
              value={financing?.down_payment}
              prefix="$"
              precision={0}
            />
            <Statistic
              className="mb-1"
              title="Interest Rate:"
              value={financing?.interest_rate}
              suffix="%"
              precision={2}
            />
            <Statistic title="Interest Only(in months):" value={financing?.interest_only_in_months} />
          </Card>
        </Col>
        <Col span={24} md={8}>
          <Card title="Financials">
            <InlineRow className="mb-1 responsive-grid">
              <Statistic title="Takeover NOI:" value={financials?.take_over_noi} prefix="$" precision={0} />
              <Statistic title="Projected Cap Rate:" value={financials?.projected_cap_rate} suffix="%" precision={2} />
            </InlineRow>
            <InlineRow className="mb-1 responsive-grid">
              <Statistic title="DSCR:" value={financials?.dscr} precision={2} />
              <Statistic title="Avg Current Rent:" value={dealInfo?.financials?.summary?.rent_stacks?.sellers_average_rent} prefix="$" precision={0} />
            </InlineRow>
            {/* <Statistic
              className="mb-1"
              title="Rent Premium (Monthly) Brought to Market:"
              value={financials?.brought_to_market}
              prefix="$"
              precision={0}
            /> */}
             <Statistic
                title="Projected (Exit) NOI:"
                value={financials?.projected_exit_noi}
                prefix="$"
                precision={0}
              />
            <InlineRow className="responsive-grid">
              <Statistic
                title="Exit Cap Rate:"
                value={dealInfo?.details?.other?.cap_rate_resale}
                suffix="%"
                precision={2}
              />
              <Statistic title="Exit Value:" value={financials?.exit_value} prefix="$" precision={0} />
            </InlineRow>
          </Card>
        </Col>
        <Col span={24} md={8}>
          <Card title="Sources of Funds">
            <h3>Debt Financing</h3>
            <InlineRow className="mb-1 responsive-grid">
              <Statistic title="Mortgage Loan:" value={sources_of_funds?.mortgage_amount} prefix="$" precision={0} />
              <Statistic
                title="Improvement Financing:"
                value={sources_of_funds?.improvement_financing}
                prefix="$"
                precision={0}
              />
            </InlineRow>
            <Statistic
              title="Total Debt Financing:"
              value={sources_of_funds?.total_debt_financing}
              prefix="$"
              precision={0}
            />
            <Divider />
            <h3>Equity</h3>
            <InlineRow className="mb-1 responsive-grid">
              <Statistic
                title="Class A Member - Sponsor:"
                value={sources_of_funds?.class_a_member_sponsor}
                prefix="$"
                precision={0}
              />
              <Statistic
                title="Preferred Equity:"
                value={sources_of_funds?.preferred_equity}
                prefix="$"
                precision={0}
              />
            </InlineRow>
            <InlineRow className="mb-1 responsive-grid">
              <Statistic title="Class A Member:" value={sources_of_funds?.class_a_member} prefix="$" precision={0} />
              <Statistic title="Total Equity:" value={sources_of_funds?.total_equity} prefix="$" precision={0} />
            </InlineRow>
            <Statistic
              title="Total Equity and Debt Financing:"
              value={sources_of_funds?.total_equity_debt_financing}
              prefix="$"
              precision={0}
            />
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default SummaryTab
