import { COMMON } from '@/constants'

const initState = {
  alert: null,
  rentcastInfo: null,
  marketTrendsInfo: null,
  walkscoreInfo: null,
  treasuryInfo: null
}

const CommonReducer = (state = initState, action) => {
  const { type, payload } = action

  switch (type) {
    case COMMON.TOP_ALERT:
      return {
        ...state,
        alert: Object.assign({}, payload)
      }

    case COMMON.CLEAR_ALERT:
      return {
        ...state,
        alert: null
      }

    case COMMON.TREASURY_DETAIL:
      return {
        ...state,
        treasuryInfo: payload
      }

    case COMMON.RENT_CAST_DETAIL:
      return {
        ...state,
        rentcastInfo: payload
      }

    case COMMON.MARKET_TRENDS_DETAIL:
      return {
        ...state,
        marketTrendsInfo: payload
      }

    case COMMON.WALK_SCORE_DETAIL:
      return {
        ...state,
        walkscoreInfo: payload
      }

    default:
      return state
  }
}

export default CommonReducer
