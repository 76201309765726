import { DASHBOARD } from '@/constants'

const initState = {
  items: []
}

const DashboardReducer = (state = initState, action) => {
  const { type, payload } = action

  switch (type) {
    case DASHBOARD.ITEM_LIST:
      return {
        ...state,
        next: payload.next,
        count: payload.count,
        previous: payload.previous,
        items: payload.results
      }

    default:
      return state
  }
}

export default DashboardReducer
