import React, { useEffect, useState, useMemo } from 'react'
import { Table, Statistic, Row, Col, InputNumber } from 'antd'
import NumberInput from '../NumberInput'
import InlineRow from '../InlineRow'

const FilledValuesTable = ({ initialData, fillableData, onInputValuesChange, totalNumberOfUnit=0,gross_income=0 }) => {
  const initialInputValues = {
    payroll_per_unit: 0,
    repairs_and_maintenance_per_unit: 0,
    insurance_per_unit: 0,
    management_rate: 0,
    contract_services_per_unit: 0,
    general_admin_per_unit: 0,
    marketing_per_unit: 0,
    misc_per_unit: 0
  }

  const [inputValues, setInputValues] = useState(initialInputValues)
  
  const totalExpense = useMemo(() => {
    const baseExpense = Object.entries(inputValues).reduce((acc, [key, value]) => {
      let keyExpense;
      if (key === 'management_rate') {
        keyExpense = (parseFloat(value) / 100) * gross_income;
        return acc + keyExpense;
      } else {
        keyExpense = (parseFloat(value) || 0) * totalNumberOfUnit;
        return acc + keyExpense;
      }
    }, 0);
  
    const taxes = parseFloat(fillableData?.taxes) || 0;
    const utility = (parseFloat(fillableData?.utility) || 0) * 1.02;
  

  
    return baseExpense + taxes + utility;
  }, [inputValues, totalNumberOfUnit, fillableData, gross_income]);

  // Data for the table
  const data = [
    {
      label: 'Payroll Per Unit',
      dataIndex: 'payroll_per_unit',
      input: inputValues['payroll_per_unit'] || initialData['payroll_per_unit'] || 0,
      value: fillableData?.payroll || 0
    },
    {
      label: 'Repairs and Maintenance Per Unit',
      dataIndex: 'repairs_and_maintenance_per_unit',
      input: inputValues['repairs_and_maintenance_per_unit'] || initialData['repairs_and_maintenance_per_unit'] || 0,
      value: fillableData?.repairs_and_maintenance || 0
    },
    {
      label: 'Insurance Per Unit',
      dataIndex: 'insurance_per_unit',
      input: inputValues['insurance_per_unit'] || initialData['insurance_per_unit'] || 0,
      value: fillableData?.insurance || 0
    },
    {
      label: 'Management %',
      dataIndex: 'management_rate',
      input: inputValues['management_rate'] || initialData['management_rate'] || 0,
      value: fillableData?.management_fee || 0,
      rating: true
    },
    {
      label: 'Contract Services Per Unit',
      dataIndex: 'contract_services_per_unit',
      input: inputValues['contract_services_per_unit'] || initialData['contract_services_per_unit'] || 0,
      value: fillableData?.contract_services || 0
    },
    {
      label: 'General/ Admin Per Unit',
      dataIndex: 'general_admin_per_unit',
      input: inputValues['general_admin_per_unit'] || initialData['general_admin_per_unit'] || 0,
      value: fillableData?.general_admin || 0
    },
    {
      label: 'Marketing Per Unit',
      dataIndex: 'marketing_per_unit',
      input: inputValues['marketing_per_unit'] || initialData['marketing_per_unit'] || 0,
      value: fillableData?.marketing || 0
    },
    {
      label: 'Misc. Per Unit',
      dataIndex: 'misc_per_unit',
      input: inputValues['misc_per_unit'] || initialData['misc_per_unit'] || 0,
      value: fillableData?.misc || 0
    }
  ]

  const extraData = [
    {
      label: 'Taxes',
      value: fillableData?.taxes || 0
    },
    {
      label: 'Utility',
      value: fillableData?.utility * 1.02 || 0
    }
  ]

  // Columns configuration for the table
  const columns = [
    {
      title: 'Labels',
      dataIndex: 'label',
      width: '40%'
    },
    {
      title: 'Input(Per Unit)',
      dataIndex: 'input',
      render: (text, record) => (
        <InputNumber
          defaultValue={record.input}
          value={record.input}
          onChange={(value) => handleInputChange(value, record.dataIndex)}
          addBefore={!record.rating ? '$' : ''}
          addonAfter={record.rating ? '%' : ''}
        />
      ),
      width: '30%'
    },
    {
      title: 'Sellers (Per Unit) For Reference',
      dataIndex: 'value',
      render: (text, record) => <NumberInput defaultValue={record.value / totalNumberOfUnit} disabled pricing />,
      width: '30%'
    }
  ]
  const transformToCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(value)
  }
  // Handler for input change
  const handleInputChange = (value, label) => {
    // Update input values in state
    setInputValues((prevState) => ({
      ...prevState,
      [label]: value
    }))
  }

  useEffect(() => {
    // Pass the input values along with the total expense to the callback
    onInputValuesChange({
      ...inputValues,
      total_expense: totalExpense
    })
  }, [inputValues])

  return (
    <div>
      <Row align="">
        {extraData.map((item, index) => (
          <Col key={index} span={6}>
            <Statistic title={item?.label} value={transformToCurrency(item.value || 0)} precision={0} />
          </Col>
        ))}
      </Row>

      <Table
        dataSource={data.map((item, index) => ({ ...item, key: index }))}
        columns={columns}
        pagination={false}
        bordered
        size="middle"
      />
    </div>
  )
}

export default FilledValuesTable
