import { PROFILE, COMMON } from '@/constants'

const initState = {
  alert: null
}

const ProfileReducer = (state = initState, action) => {
  const { type, payload } = action

  switch (type) {
    case PROFILE.USER_DETAIL:
      return {
        ...state,
        userDetail: payload
      }
    case PROFILE.USER_NOT_EXIST:
      return {
        ...state,
        userDetail: payload
      }

    default:
      return state
  }
}

export default ProfileReducer
