import React, { useState, useEffect } from 'react'
import { Form, Button, Space, Divider, notification } from 'antd'
import InlineRow from '@/component/InlineRow'
import NumberInput from '@/component/NumberInput'
import TextLabel from '@/component/TextLabel'
import { INCOMES_PROPERTIES, EXPENSES_PROPERTIES, INCOME_EXPENSE_INFO } from '@/constants'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import TooltipWithInfo from '@/component/TooltipWithInfo'

export default function Step4IncomeAndExpenses(props) {
  const [form] = Form.useForm()
  const { dealInfo,handleUpdate, dealInput } = props
  const [initialValues, setInitialValues] = useState({})
  const [loading, setLoading] = useState(true)
  const [noiEditable, setNoiEditable] = useState(false)

  useEffect(() => {

    const fetchInitialValues = async () => {
      const storedValues = dealInput.income_expenses
      if (storedValues) {
        setInitialValues(storedValues)
        setLoading(false)
      } else {
        setInitialValues('')
        setLoading(false) // Set loading to false when initialValues are set
      }
    }
    fetchInitialValues()
  }, [])

  const handleSubmit = async () => {
    try {
      await form.validateFields()

      const values = form.getFieldsValue()

      let totalIncome = 0
      let totalExpenses = 0

      INCOMES_PROPERTIES.forEach((prop) => {
        totalIncome += values?.incomes[0]?.[prop?.value] || 0
      })

      EXPENSES_PROPERTIES.forEach((prop) => {
        totalExpenses += values?.expenses[0]?.[prop?.value] || 0
      })

      const calculatedNOI = 
          values?.incomes[0]?.['gross_potential_rent'] -
          values?.incomes[0]?.['loss_to_lease'] -
          values?.incomes[0]?.['physical_vacancy'] -
          values?.incomes[0]?.['concessions'] -
          values?.incomes[0]?.['bad_debt'] -
          values?.incomes[0]?.['non_revenue_units'] +
          values?.incomes[0]?.['utility_reimbursement'] +
          values?.incomes[0]?.['other_income'] -
          totalExpenses;
      
      // console.log('New Calculated NOI:', newCalculatedNoi);   

      // const calculatedNOI = totalIncome - totalExpenses

      const userInputNOI = values?.expenses[0]?.noi || 0
      if (userInputNOI !== calculatedNOI) {
        notification.error({
          message: 'Net Operating Income(NOI) Mismatch',
          description: `The calculated NOI: ${transformToCurrency(calculatedNOI)} does not match the total NOI reported: ${transformToCurrency(userInputNOI)}. Please review your income and expenses.`,
          icon: <ExclamationCircleOutlined style={{ color: '#ff4d4f' }} />,
          duration: 4
        })
        return
      }

      await handleUpdate(values)
    } catch (err) {
      console.error('Form validation failed:', err)
    }
  }
  const transformToCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(value)
  }

  const toggleNoiEditable = () => {
    setNoiEditable(!noiEditable)
  }

  // Move the conditional rendering to the top
  if (loading) {
    return null
  }
  let calculatedInitialValues;
  

if (dealInfo?.details?.incomes && dealInfo?.details?.incomes.length > 0) {
  
  const { incomes, expenses } = dealInfo?.details;

  calculatedInitialValues = {
      incomes: [incomes[0]], 
      expenses: [expenses[0]],
  };
} else {
  calculatedInitialValues = initialValues;

}

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={handleSubmit}
      requiredMark={false}
      preserve={false}
      colon={false}
      initialValues={calculatedInitialValues}
      className="income-and-expenses-container"
    >
      <h1>T-12 - Income & Expenses</h1>

      <InlineRow>
        <NumberInput
          label="What is the total NOI reported on their Annual Statement?"
          tooltipInfo={{ info: INCOME_EXPENSE_INFO[0]['Net Operating Income (NOI)'] }}
          name={['expenses', 0, 'noi']}
          pricing={true}
        />
      </InlineRow>
      <Space className="header-text">Please fill out the total income and expense for the year from the seller:</Space>
      <Divider>Income</Divider>
      <InlineRow>
        <TextLabel></TextLabel>
        <TextLabel className="text-label">Sellers</TextLabel>
      </InlineRow>
      {INCOMES_PROPERTIES.map((prop) => (
        <InlineRow key={prop.value}>
          <TextLabel>
            {prop.label}:
            <TooltipWithInfo info={INCOME_EXPENSE_INFO[0][prop.label]} />
          </TextLabel>
          <NumberInput name={['incomes', 0, prop.value]} pricing={true} />
        </InlineRow>
      ))}
      <Divider>Expenses</Divider>
      <InlineRow>
        <TextLabel></TextLabel>
        <TextLabel>Sellers</TextLabel>
      </InlineRow>
      {EXPENSES_PROPERTIES.map((prop) => (
        <InlineRow key={prop.value}>
          <TextLabel>
            {prop.label}:
            <TooltipWithInfo info={INCOME_EXPENSE_INFO[0][prop.label]} />
          </TextLabel>
          <NumberInput
            name={['expenses', 0, prop.value]}
            pricing={true}
            readOnly={prop.value === 'noi' ? !noiEditable : prop.readOnly}
          />
        </InlineRow>
      ))}

      <Button type="primary" className="next-btn btn" htmlType="submit">
        Next
      </Button>
    </Form>
  );
}
